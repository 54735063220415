import { http } from "@/http-commons";

class categorias {
  
  all() {
    return http.get('/categoria');
  }
  post(data) {
    return http.post('/categoria',data);
  }
  put(id,data) {
    return http.put(`/categoria/${id}`,data);
  }
  delete(id) {
    return http.delete(`/categoria/${id}`);
  }
  categoriapormodulo(poliza,cxp) {
    return http.get(`/categoria/GetCategoriaxModulo/${poliza}/${cxp}`);
  }
}
export default new categorias();