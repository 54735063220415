import { http } from "@/http-commons";

class tipoinmobiliario {
  all() {
    return http.get('/TipoInmobiliario');
  }
  get(id) {
    return http.get(`/TipoInmobiliario/${id}`);
  }
  post(TipoInmobiliario) {
    return http.post('/TipoInmobiliario', TipoInmobiliario);
  }
  put(id, TipoInmobiliario) {
    return http.put(`/TipoInmobiliario/${id}`, TipoInmobiliario);
  }
  delete(id) {
    return http.delete(`/TipoInmobiliario/${id}`);
  }
}

export default new tipoinmobiliario();