<template>
  <div>
    <md-card>
      <md-ripple>
        <md-card-header>
          <div class="md-title">
            {{ "Correo: " + emailObj.fromAddress }}
          </div>
          <div class="md-subhead">
            {{ "Asunto: " + emailObj.subject + " Fecha: " + emailObj.date }}
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-subhead" v-if="emailObj.isHtml == false">{{ emailObj.body }}</div>
        </md-card-content>
        <md-card-actions>
          <md-chips
            v-model="attachments"
            md-static
            slot-scope="{ item }"
          ></md-chips>
        </md-card-actions>
      </md-ripple>
    </md-card>
  </div>
</template>

<script>
import DOMPurify from "dompurify";
export default {
  props: {
    emailObj: {
      type: Object,
      required: true,
      default: {},
    },
  },
  components: {},
  computed: {
    sanitizedHTML() {
      return DOMPurify.sanitize(this.emailObj.body);
    }
  },
  data() {
    return {
      attachments: [],
    };
  },
  methods: {
    download(chip) {
      console.log("Descargando", chip);
    },
    downloadAll() {
      if (this.emailObj.attachments.length === 0) return;
    },
  },
  render(createElement) {
    if (this.emailObj.isHtml) {
      return createElement(
        'div',
        {
          domProps: {
            innerText: this.sanitizedHTML,
          }
        }
      );
    } else {
      return createElement(
        'div',
        {
          domProps: {
            innerText: this.emailObj.body,
          }
        }
      );
    }
  }
};
</script>