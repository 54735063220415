import { http } from "@/http-commons";

class solucion {
  get(id) {
    return http.get(`/solucion/Representacion/${id}`);
  }
  post(solucion) {
    return http.post('/solucion', solucion);
  }
  put(id, data) {
    return http.put(`/solucion/${id}`, data);
  }
  delete(id) {
    return http.delete(`/solucion/${id}`);
  }
  getId(id) {
    return http.get(`/solucion/${id}`);
  }
  Filtros(UsuarioId, fechaInicio, fechafin, Estatus,) {
    return http.get(`/Solucion/Filtros/${UsuarioId}/${fechaInicio}/${fechafin}/${Estatus}/`);
  }

  getProcesoSoluciones() {
    return http.get(`/ProcesoSoluciones`);
  }

  getSolucionesList(data) {
    return http.post(`/Solucion/SolucionesList`, data);
  }
  getPolizaId(id){
    return http.get(`/Solucion/SolucionPoliza/${id}`);
  }
  isExists(id){
    return http.get(`/Solucion/isExists/${id}`);
  }
}

export default new solucion();