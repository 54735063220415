import { FixedScaleAxis } from "chartist";

class Mensajes {
    // type: ['', 'info', 'success', 'warning', 'danger'],

    login(protocolo, status) {
        // console.log(protocolo);
        // console.log(status);
        let Mensajes = {
            tipo: "",
            mensaje: "",
            accion: false
        };
        switch (protocolo) {
            case 'post':
                switch (status) {
                    case 200:
                        Mensajes.tipo = "success";
                        Mensajes.mensaje = "Sigamos adelante";
                        Mensajes.accion = true;
                        break;
                    case 400:
                        Mensajes.tipo = "danger";
                        Mensajes.mensaje = "Ups. algo salio mal, favor de intentarlo nuevamente";
                        Mensajes.accion = false;
                        break;

                };
                break;

        };
        return Mensajes;
    }
    generico(protocolo, status) {
        let Mensajes = {
            tipo: "",
            mensaje: ""
        };
        switch (protocolo) {
            case 'post':
                switch (status) {
                    case 200:
                        Mensajes.tipo = "";
                        Mensajes.mensaje = "Sigamos adelante";
                        break;
                    case 201:
                        Mensajes.tipo = "success";
                        Mensajes.mensaje = "El registro se guardo de forma correcta";
                        Mensajes.accion = true;
                        break;
                };
                break;
            case 'get':
                switch (status) {
                    case 200:
                        Mensajes.tipo = "";
                        Mensajes.mensaje = "Sigamos adelante";
                        Mensajes.accion = true;
                        break;
                };
                break;
            case 'put':
                switch (status) {
                    case 204:
                        Mensajes.tipo = "success";
                        Mensajes.mensaje = "El registro se actualizo de forma correcta";
                        Mensajes.accion = true;
                        break;
                };
                break;
            case 'delete':
                switch (status) {
                    case 204:
                        Mensajes.tipo = "success";
                        Mensajes.mensaje = "Se elimino de forma correcta";
                        Mensajes.accion = true;
                        break;
                };
                break;

        };
        return Mensajes;
    }
    crud(protocolo, status,mensajes) {        
        let colMensajes =[];
        switch (protocolo) {
            case 'post':
                switch (status) {
                    case 200:
                        colMensajes = ProcesamosMensajes(mensajes);
                        
                        break;
                    case 201:
                        colMensajes = ProcesamosMensajes(mensajes);
                        break;
                };
                break;
            case 'get':
                switch (status) {
                    case 200:
                        colMensajes = ProcesamosMensajes(mensajes);
                        break;
                };
                break;
            case 'put':
                switch (status) {
                    case 200:
                        colMensajes = this.ProcesamosMensajes(mensajes);
                        break;
                    case 204:
                        colMensajes = ProcesamosMensajes(mensajes);
                        break;
                };
                break;
            case 'delete':
                switch (status) {
                    case 204:
                        colMensajes = ProcesamosMensajes(mensajes);
                        break;
                };
                break;

        };
        return colMensajes;
    }
    ProcesamosMensajes(mensajes){
        let colMensajes =[];
        let mensaje = {
            tipo: "",
            mensaje: ""
        };
        mensajes.forEach(m => {
            mensaje.tipo = e.tipo;
            mensaje.mensaje = e.mensaje;
            colMensajes.push(mensaje);
        });
        return colMensajes;
    }

    Error(componente, e) {
        console.log('Error en:');
        console.log(componente);
        console.log(e);
    }

}

export default new Mensajes();