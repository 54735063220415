import { http } from "@/http-commons";

class Estsoluciones {
  all() {
    return http.get('/dashboardsoluciones/ListadoPorProceso');
  }
  get(id) {
    return http.get(`/dashboardsoluciones/ListadoPorProceso'${id}`);  
  }
  
}

export default new Estsoluciones();