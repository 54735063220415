<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-primary">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">Referencias Personales</h4>
        </md-card-header>
        <md-card-content>
          <md-table
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover"
          >
            <md-table-toolbar>
              <md-field>
                <label for="pages">Por página</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>

              <md-field>
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style="width: 200px"
                  placeholder="Buscar registro"
                  v-model="searchQuery"
                >
                </md-input>
              </md-field>
              <md-field>
                <md-button class="md-primary"   @click="ModalPersonalizado(1)">Nuevo</md-button>
              </md-field>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Id" md-sort-by="referenciaPersonalId">{{
                item.referenciaPersonalId
              }}</md-table-cell>
              <md-table-cell
                md-label="Nombre(s)"
                md-sort-by="rpnombres"
                >{{ item.rpnombres }}</md-table-cell
              >
              <md-table-cell md-label="Apellido Paterno" md-sort-by="rpapePaterno">{{
                item.rpapePaterno
              }}</md-table-cell>
              <md-table-cell md-label="Apellido Materno" md-sort-by="rpApeMaterno">{{
                item.rpApeMaterno
              }}</md-table-cell>
              <md-table-cell md-label="Teléfono" md-sort-by="rptelefono">{{
                item.rptelefono
              }}</md-table-cell>
              <md-table-cell md-label="Tipo de Referencia" md-sort-by="tipoRefPersonalId">{{
                item.tipoRefPersonal.tipoRefPersonalDesc
              }}</md-table-cell>
             <md-table-cell md-label="Acciones" v-if="modo != 5">           
              <md-button
                  class="md-just-icon md-warning md-simple"
                  @click.native="ModalPersonalizado(2, item)"
                >
                  <md-icon>edit</md-icon>
                </md-button>
                <md-button
                  class="md-just-icon md-danger md-simple"
                  @click.native="ModalPersonalizado(3, item)"
                >
                  <md-icon>close</md-icon>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }}
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          >
          </pagination>
        </md-card-actions>
      </md-card>
      <modal v-if="classicModal" @close="classicModalHide">
        <template slot="header">
          <h4 class="modal-title">{{ datosModal.titulo }}</h4>
          <md-button
            class="md-simple md-just-icon md-round modal-default-button"
            @click="classicModalHide"
          >
            <md-icon>clear</md-icon>
          </md-button>
        </template>

        <template slot="body">
          <md-field>
            <label>Id</label>
            <md-input v-model="referenciaPersonalId" disabled></md-input>
            <span class="md-helper-text">referenciaPersonalId</span> </md-field
          ><md-field>
            <label>Nombre</label>
            <md-input v-model="rpnombres"></md-input>            
          </md-field>
          <md-field>
            <label>Apellido Paterno</label>
            <md-input v-model="rpapePaterno"></md-input>
          </md-field>
          <md-field>
            <label>Apellido Materno</label>
            <md-input v-model="rpApeMaterno"></md-input>
          </md-field>
          <md-field>
            <label>Teléfono</label>
            <md-input v-model="rptelefono"></md-input>
          </md-field>
          <md-field>
            <!-- Combo-->
            <label>Tipo de parentesco</label>
            <md-select v-model="tipoRefPersonalId">
              <md-option
                v-for="a in tipoRefPersCol"
                :key="a.tipoRefPersonalId"
                :value="a.tipoRefPersonalId"
                >
                  {{ a.tipoRefPersonalDesc }}
      
              </md-option>
            </md-select>
          </md-field>
        </template>
        <template slot="footer">
          <md-button class="md-danger md-simple" @click="classicModalHide"
            >Cerrar</md-button
          >
          <md-button
            class="md-primary"
            @click.native="postNow(datosModal.evento)"
            >{{ datosModal.boton }}</md-button
          >
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
import { Pagination, Modal } from "@/components";
import Fuse from "fuse.js";
import Swal from "sweetalert2";
import mensaje from "../../Servicios/ManjeadorErrores/Mensajes";
import servRefPers from "../../Servicios/Operacion/referenciasPersonales.js";
import store from "../../Store/index";
import servTipoRefPers from "@/Servicios/Catalogos/tiporefpersonal.js"

export default {
  components: {
    Pagination,
    Modal,
  },
  computed: {
    usuario() {
      return store.state.usuario;
    },
    general() {
      return store.state.General;
    },
    modo() {
      return store.state.Modo;
    },
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      currentSort: "rpnombres",
      currentSortOrder: "asc",
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      footerTable: ["ID","Nombre(s)", "Apellido Paterno", "Apelido Materno", "Teléfono","Tipo Referencia"],
      searchQuery: "",
      propsToSearch: ["referenciaPersonalId","rpnombres", "rpapePaterno", "rpApeMaterno","rptelefono","tipoRefPersonalId"],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      classicModal: false,
      referenciaPersonalId:0,
      rpnombres: null,
      rpapePaterno: null,
      rpApeMaterno: null,
      rptelefono:null,
      tipoRefPersonalId:0,
      fisicaMoralId:0,
      datosModal: {
        titulo: "Crear una Referencia Personal",
        boton: "Confirmar",
        evento: 0,
      },
      tipoRefPersCol:[]
    };
  },
  methods: {
    getTipoRefPers(){
      servTipoRefPers.all().then(r => {
        this.tipoRefPersCol=r.data;}
      ).catch(e => console.log(" Error en cargar combo Tipo Parentesco "+e))
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    postNow(exp) {
      let data = {
        referenciaPersonalId: this.referenciaPersonalId,
        rpnombres: this.rpnombres,
        rpapePaterno: this.rpapePaterno,
        rpApeMaterno: this.rpApeMaterno,
        rptelefono: this.rptelefono,
        tipoRefPersonalId: this.tipoRefPersonalId,
        fisicaMoralId: this.general.FisicaMoralId,

      };
      switch (exp) {
        case 1:
          servRefPers
            .post(data)
            .then((r) => {
              this.ProcesamosResponse(r.data);
            })
            .catch(function (e) {
              mensaje.Error("referenciasPersonales-crud-post", e);
            });
          break;

        case 2:
          if (this.referenciaPersonalId > 0) {
            servRefPers
              .put(this.referenciaPersonalId, data)
              .then((r) => {              
                this.classicModal = false;
                this.ProcesamosResponse(r.data);
              })
              .catch(function (e) {
                mensaje.Error("referenciasPersonales-crud-put", e);
              });
          }
          break;
      }
    },
    ProcesamosResponse(data) {
      data.forEach((e) => {
        let m = {
          mensaje: "",
          tipo: "",
        };
        switch (e.tipo) {
          case 2: //Atributos por completar
            // se agrega a la clase el error "md-valid" ó "md-error"
            const m = {};
            const valor = this[e.mensaje];
            m.mensaje = e.mensaje;
            m.tipo = "danger";
            this.notifyVue(m);
            this.isBandera = true;
            break;
          case 1: //Error
            m.mensaje = e.mensaje;
            m.tipo = "danger";
            this.notifyVue(m);
            this.isBandera=true;
            break;
          case 3: //success
            this.getDatos();
            this.classicModalHide();
            m.mensaje = e.mensaje;
            m.tipo = "success";
            this.notifyVue(m);
            break;
          case 4: //Information
            m.mensaje = e.mensaje;
            m.tipo = "info";
            this.notifyVue(m);
            break;
        }
      });
    },
    getDatos() {
      servRefPers
        .getId(this.general.FisicaMoralId)
        .then((a) => {
          this.tableData = a.data;
          this.fuseSearch = new Fuse(this.tableData, {
            keys: ["referenciaPersonalId","rpnombres", "rpapePaterno", "rpApeMaterno","rptelefono","tipoRefPersonalId"],
            threshold: 0.3,
          });
        })
        .catch((e) => {
          mensaje.Error("referenciasPersonales-all", e);
        });
    },
    classicModalHide() {
      this.Clear();
      this.classicModal = false;
    },
    ModalPersonalizado(exp, row) {
      switch (exp) {
        case 1: //Insertar
          this.classicModal = true;
          this.datosModal.titulo = "Crear Referencia";
          this.datosModal.boton = "Confirmar";
          this.datosModal.evento = 1;
          break;
        case 2://Actualizar
          this.referenciaPersonalId=row.referenciaPersonalId;
          this.rpnombres=row.rpnombres;
          this.rpapePaterno=row.rpapePaterno;
          this.rpApeMaterno=row.rpApeMaterno;
          this.rptelefono=row.rptelefono;
          this.tipoRefPersonalId=row.tipoRefPersonalId;
          this.fisicaMoralId=row.fisicaMoralId;
          this.classicModal = true;
          this.datosModal.titulo = "Actualizar Referencia";
          this.datosModal.boton = "Actualizar";
          this.datosModal.evento = 2;
          break;

        case 3: //Eliminar
          this.referenciaPersonalId = row.referenciaPersonalId;
          this.deleteRow(this.referenciaPersonalId);
          break;
      }
    },
    deleteRow(id) {
      if (this.referenciaPersonalId > 0) {
        Swal.fire({
          title: "!Eliminar!",
          text: `¿Deseas eliminar el siguiente registro: ${this.referenciaPersonalId} ?`,
          type: "success",
          showCancelButton: true,
          confirmButtonClass: "md-button md-success",
          cancelButtonClass: "md-button md-danger",
          confirmButtonText: "Si, eliminar!",
          cancelButtonText: "Cancelar",
          buttonsStyling: false,
        })
          .then((r) => {
            if (r.value) {
              servRefPers
                .delete(id)
                .then((response) => {
                  Swal.fire({
                    title: "Eliminado!",
                    text: `Se ha eliminado el registro ${this.referenciaPersonalId}`,
                    type: "success",
                    confirmButtonClass: "md-button md-success",
                    cancelButtonText: "Cancelar",
                    buttonsStyling: false,
                  });
                  let mens = {};
                  mens = mensaje.generico(
                    response.config.method,
                    response.status
                  );
                  if (mens.accion == true) {
                    this.getDatos();
                  }
                  this.notifyVue(mens);
                })
                .catch(function (e) {
                  mensaje.Error("usuario-crud-sercategorias-delete", e);
                });
            }
            if (r.dismiss == "cancel") {
              this.Clear();
            }
          })
          .catch(function (e) {
            mensaje.Error("usuario-crud-sercategorias-delete", e);
          });
      }
    },
    notifyVue(Mensajes) {
      this.$notify({
        message: Mensajes.mensaje,
        timeout: 30000,
        icon: "add_alert",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: Mensajes.tipo,
      });
    },
    Clear() {
      this.referenciaPersonalId=0;
      this.rpnombres= null;
      this.rpapePaterno=null;
      this.rpApeMaterno=null;
      this.rptelefono=null;
      this.tipoRefPersonalId=0;
      
    },
  },
  created() {
    if(this.general.SolicitudId>0){
      this.getDatos();
      this.getTipoRefPers();
      console.log("id:",this.general.FisicaMoralId);
    }; 
  },
  mounted() {

  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result.map((i) => {
        return i.item;
      });
    },
  },
};
</script>

<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.full-control > .md-list {
  width: 320px;
  max-width: 100%;
  height: 400px;
  display: inline-block;
  overflow: auto;
  border: 1px solid rgba(#000, 0.12);
  vertical-align: top;
}
</style>
