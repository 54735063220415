import { http } from "@/http-commons";

class cuentasxpagar {
  all() {
    return http.get('/CuentasXpagar');
  }
  post(cxp) {
    return http.post('/CuentasXpagar',cxp);
  }
  put(id,cxp) {
    return http.put(`/CuentasXpagar/${id}`,cxp);
  }
  delete(id) {
    return http.delete(`/CuentasXpagar/${id}`);
  }
}

export default new cuentasxpagar();