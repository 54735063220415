<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">Detalle de Póliza</h4>
        </md-card-header>
        <md-card-content>
          <md-table
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover"
          >
            <md-table-toolbar>
              <md-field>
                <label for="pages">Por página</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>
              <md-field>
                <label>Id Póliza</label>
                <md-input
                  type="numeric"
                  class="mb-3"
                  clearable
                  style="width: 200px"
                  placeholder="Id Póliza"
                  v-model="polizaId"
                >
                </md-input>
              </md-field>
              <md-field>
                <md-button class="md-default" @click="getDatos()"
                  >Buscar</md-button
                >
              </md-field>
              <md-field>
                <md-button class="md-primary" @click="ModalPersonalizado(1)"
                  >Nuevo</md-button
                >
              </md-field>
              <md-field>
                <md-button class="md-success" @click="ModalPersonalizado(4)"
                  >Generar Movimiento</md-button>
              </md-field>
            </md-table-toolbar>

            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="DP Id" md-sort-by="detallePolizaId">{{
                item.detallePolizaId
              }}   </md-table-cell>
              <md-table-cell md-label="Categoria" md-sort-by="descripcion">{{
                item.categoriaEs.descripcion
              }}</md-table-cell>
              <md-table-cell md-label="Importe">{{
                item.importe
              }}</md-table-cell>
              <md-table-cell
                md-label="Entrada/Salida"
                v-if="item.categoriaEs.tipoEs == 1"
                >Entrada</md-table-cell
              >
              <md-table-cell md-label="Entrada/Salida" v-else
                >Salida</md-table-cell
              >
              <md-table-cell
                v-if="item.movimientos.length > 0"
                md-label="Recibo Id"
                > {{ item.movimientos[0].mmid }}</md-table-cell
              >
              <md-table-cell v-else md-label="Recibo Id">0</md-table-cell>
              <md-table-cell md-label="Acciones">
                <md-button
                  v-if="item.movimientos.length == 0"
                  class="md-just-icon md-warning md-simple"
                  @click.native="ModalPersonalizado(2, item)"
                >
                  <md-icon>edit</md-icon>
                </md-button>
                <md-button
                  v-if="item.movimientos.length == 0"
                  class="md-just-icon md-danger md-simple"
                  @click.native="ModalPersonalizado(3, item)"
                >
                  <md-icon>close</md-icon>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
          <div class="footer-table md-table">
            <table>
              <caption>Descripción de los datos en el pie de la tabla</caption>
              <tfoot>
                <tr>
                  <th
                    v-for="item in footerTable"
                    :key="item"
                    class="md-table-head"
                  >
                    <div class="md-table-head-container md-ripple md-disabled">
                      <div class="md-table-head-label">
                        {{ item }}
                      </div>
                    </div>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          >
          </pagination>
        </md-card-actions>
      </md-card>
      <modal v-if="classicModal" @close="classicModalHide">
        <template slot="header">
          <h4 class="modal-title">{{ datosModal.titulo }}</h4>
          <md-button
            class="md-simple md-just-icon md-round modal-default-button"
            @click="classicModalHide"
          >
            <md-icon>clear</md-icon>
          </md-button>
        </template>

        <template slot="body" v-if="datosModal.crud == true">
          <el-tree
            :data="dataCategoria"
            :props="defaultProps"
            @node-click="handleNodeClick"
            highlight-current
          ></el-tree>
          <md-field>
            <label>Importe</label>
            <md-input v-model="Importe"></md-input>
            <span class="md-helper-text">Importe</span>
          </md-field>
        </template>
        <template slot="body" v-else>
          <el-tree
            :data="dataCuentas"
            :props="defaultProps"
            @node-click="handleNodeClickCuenta"
            highlight-current
          ></el-tree>
        </template>
        <template slot="footer">
          <md-button class="md-danger md-simple" @click="classicModalHide"
            >Cerrar</md-button
          >
          <md-button
            class="md-primary"
            @click.native="createupdate(datosModal.evento)"
            :disabled="isActive"
            >{{ datosModal.boton }}</md-button
          >
        </template>
      </modal>
    </div>
  </div>
</template>
<script>
import { Pagination, Modal } from "@/components";
import Fuse from "fuse.js";
import mensaje from "@/Servicios/ManjeadorErrores/Mensajes";
import servdp from "@/Servicios/Polizas/detallepoliza";
import store from "@/Store/index";
import servcategoria from "@/Servicios/Administracion/categorias";
import Swal from "sweetalert2";
import servCuentasBancarias from "@/Servicios/Administracion/CuentasBancaria";
import servcuentas from "@/Servicios/Movimientos/cuentas";

export default {
  name: "Detalle",
  components: {
    Pagination,
    Modal,
  },
  computed: {
    General() {
      return store.state.General;
    },
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
    usuario() {
      return store.state.usuario;
    },
  },
  data() {
    return {
      currentSort: "areaId",
      currentSortOrder: "asc",
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      footerTable: [
        "DP Id",
        "Categoria",
        "Importe ",
        "Entrada/Salida",
        "ReciboId",
        "Acciones",
      ],
      searchQuery: "",
      propsToSearch: [
        "detallePolizaId",
        "polizaId",
        "representacion",
        "ejecutivo",
      ],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      polizaId: 0,
      classicModal: false,
      classicModalgenerar: false,
      categoriaId: 0,
      datosModal: {
        titulo: "Crear un concepto",
        boton: "Confirmar",
        evento: 0,
        crud: false,
      },
      dataCategoria: [],
      dataCuentas: [],
      Importe: null,
      
      detallepolizaId: 0,
      CuentaId: 0,
      Mensajes: {
        tipo: "",
        mensaje: "",
        accion: false,
      },
      isActive: false,
    };
  },
  methods: {
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    getDatos() {
      if (this.polizaId > 0) {
        store.state.General.Poliza = this.polizaId;
        servdp
          .dpall(this.polizaId)
          .then((a) => {
            this.tableData = a.data;
          })
          .catch((e) => {
            mensaje.Error("poliza-PolizasFechas-all", e);
          });
      }
    },
    ModalPersonalizado(exp, row) {
      switch (exp) {
        case 1: //Insertar
          this.classicModal = true;
          this.datosModal.titulo = "Crear concepto";
          this.datosModal.boton = "Confirmar";
          this.datosModal.evento = 1;
          this.datosModal.crud = true;
          break;
        case 2: //Actualizar
          this.detallepolizaId = row.detallePolizaId;
          this.categoriaId = row.categoriaEsid;
          this.Importe = row.importe;
          this.classicModal = true;
          this.datosModal.titulo = "Actualizar concepto";
          this.datosModal.boton = "Actualizar";
          this.datosModal.evento = 2;
          this.datosModal.crud = true;
          break;

        case 3: //Eliminar
          this.detallePolizaId = row.detallePolizaId;
          this.deleteRow(this.detallePolizaId);
          break;

        case 4: //Generar Movimiento
          this.datosModal.titulo = "Favor de seleccionar una cuenta";
          this.datosModal.boton = "Generar";
          this.datosModal.crud = false;
          this.datosModal.evento = 4;
          this.classicModal = true;
          this.obtenemosCuentas();
          break;
      }
    },
    classicModalHide() {
      this.Clear();
      this.classicModal = false;
    },
    handleNodeClick(data) {
      this.categoriaId = data.id;
    },
    handleNodeClickCuenta(data) {
      this.CuentaId = data.id;
    },
    defaultProps() {},
    obtenemosCategorias() {
      servcategoria
        .categoriapormodulo(true, false)
        .then((response) => {
          console.log("Categorias",response);
          response.data.forEach((v) => {
            //esta funcionalidad carga el arbol
            if (v.categoriaEspadreId == null) {
              let padre = {
                id: v.categoriaEsid,
                label: v.descripcion,
                children: [],
              };
              v.inverseCategoriaEspadre.forEach((c) => {
                let children = {
                  id: c.categoriaEsid,
                  label: c.descripcion,
                };
                padre.children.push(children);
              });
              this.dataCategoria.push(padre);
            }
          });
        })
        .catch(function (e) {
          mensaje.Error("categoriapormodulo-detallepolizas-then", e);
        });
    },
    obtenemosCuentas() {
      this.dataCuentas = [];
      servcuentas
        .Usuario(this.usuario.usuariosId,false)
        .then((response) => {
          response.data.forEach((c) => {
            //esta funcionalidad carga el arbol de cuentas
            let children = {
              id: c.cuentaId,
              label: c.nombre,
            };
            this.dataCuentas.push(children);
          });
        })
        .catch(function (e) {
          mensaje.Error("detallepoliza-cuentasbancarias-get", e);
        });
    },
    createupdate(exp) {
      this.isActive = true;
      this.Mensajes.accion = false;
      var data = {
        detallepolizaId: this.detallepolizaId,
        importe: parseFloat(this.Importe),
        polizaId: this.polizaId,
        categoriaesid: this.categoriaId,
      };
      if (this.Importe <= 0 && exp != 4) {
        this.Mensajes.tipo = "danger";
        this.Mensajes.mensaje = "El importe tiene que ser mayor a 0";
        this.Mensajes.accion = true;
        this.notifyVue(this.Mensajes);
      }
      if (this.categoriaId <= 0 && exp != 4) {
        this.Mensajes.tipo = "danger";
        this.Mensajes.mensaje = "Favor de seleccionar alguna de las categorias";
        this.Mensajes.accion = true;
        this.notifyVue(this.Mensajes);
      }
      switch (exp) {
        case 1:
          if (this.Mensajes.accion == false) {
            servdp
              .post(data)
              .then((r) => {
                //hay que cerrar el model
                this.classicModal = false;
                let mens = {};
                mens = mensaje.generico(r.config.method, r.status);
                if (mens.accion == true) {
                  this.getDatos();
                }
                this.notifyVue(mens);
                //Aqui recuperamos los datos
              })
              .catch(function (e) {
                mensaje.Error("detallepoliza-crud-post", e);
              });
          }
          break;

        case 2:
          if (this.detallepolizaId > 0 && this.Mensajes.accion == false) {
            servdp
              .put(this.detallepolizaId, data)
              .then((r) => {
                //hay que cerrar el model
                this.classicModal = false;
                let mens = {};
                mens = mensaje.generico(r.config.method, r.status);
                if (mens.accion == true) {
                  this.getDatos();
                }
                this.notifyVue(mens);
                //Aqui recuperamos los datos
              })
              .catch(function (e) {
                mensaje.Error("detallepoliza-crud-put", e);
              });
          }
          break;
        case 4:
          this.GeneramosMovimiento();
          break;
      }
      this.isActive = false;
    },
    notifyVue(Mensajes) {
      this.$notify({
        message: Mensajes.mensaje,
        timeout: 30000,
        icon: "add_alert",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: Mensajes.tipo,
      });
    },
    deleteRow(id) {
      if (this.detallePolizaId > 0) {
        Swal.fire({
          title: "!Eliminar!",
          text: `¿Deseas eliminar el siguiente registro: ${this.detallePolizaId} ?`,
          type: "success",
          showCancelButton: true,
          confirmButtonClass: "md-button md-success",
          cancelButtonClass: "md-button md-danger",
          confirmButtonText: "Si, eliminar!",
          cancelButtonText: "Cancelar",
          buttonsStyling: false,
        })
          .then((r) => {
            if (r.value) {
              servdp
                .delete(id)
                .then((response) => {
                  Swal.fire({
                    title: "Eliminado!",
                    text: `Se ha eliminado el registro ${this.detallePolizaId}`,
                    type: "success",
                    confirmButtonClass: "md-button md-success",
                    cancelButtonText: "Cancelar",
                    buttonsStyling: false,
                  });
                  let mens = {};
                  mens = mensaje.generico(
                    response.config.method,
                    response.status
                  );
                  if (mens.accion == true) {
                    this.getDatos();
                  }
                  this.notifyVue(mens);
                })
                .catch(function (e) {
                  mensaje.Error("detallepolizas-crud-delete", e);
                });
            }
            if (r.dismiss == "cancel") {
              this.Clear();
            }
          })
          .catch(function (e) {
            mensaje.Error("usuario-crud-sercategorias-delete", e);
          });
      }
    },
    Clear() {
      this.categoriaId = 0;
      this.Importe = null;
      this.detallepolizaId = 0;
    },
    GeneramosMovimiento() {
      this.Mensajes.accion = false;
      if (this.CuentaId <= 0) {
        this.Mensajes.tipo = "danger";
        this.Mensajes.mensaje = "Favor de selecionar una cuenta";
        this.Mensajes.accion = true;
        this.notifyVue(this.Mensajes);
      }
      if (this.polizaId <= 0) {
        this.Mensajes.tipo = "danger";
        this.Mensajes.mensaje =
          "No encontramos Póliza a donde asociar el movimiento";
        this.Mensajes.accion = true;
        this.notifyVue(this.Mensajes);
      }

      if (this.Mensajes.accion == false) {
        servdp
          .GenerarMovimiento(
            this.CuentaId,
            this.polizaId,
            this.usuario.usuariosId
          )
          .then((r) => {
            //console.log("responsedownload: ", r);
            var fileURL = window.URL.createObjectURL(new Blob([r.data]));

            var fileLink = document.createElement("a");

            fileLink.href = fileURL;

            fileLink.setAttribute("download", "recibo.docx");

            document.body.appendChild(fileLink);

            fileLink.click();
            this.getDatos();
          })
          .catch((e) => {
            console.log("Error-DetallePolizas-GenerarMovimiento", e);
          });
      }
    },
  },
  created() {
    this.polizaId = this.General.Poliza;
    this.getDatos();
    this.obtenemosCategorias();
  },
  mounted() {
    // Fuse search initialization.
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ["polizaId", "solicitudId", "representacion", "ejecutivo"],
      threshold: 0.3,
    });
  },
  watch: {
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result.map(r => r.item);
    },
  },
};
</script>
<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
</style>
