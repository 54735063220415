import { data } from "jquery";
import { http } from "@/http-commons";

class personasOcupanInm {
  getId(id) {
    return http.get(`/PersonasOcupanInm/${id}`);
  }
  delete(id){
    return http.delete(`/PersonasOcupanInm/${id}`);
  }
  post(data){
    return http.post('/PersonasOcupanInm', data);
  }
  put(id,data){
    return http.put(`/PersonasOcupanInm/${id}`,data);
  }

}

export default new personasOcupanInm();