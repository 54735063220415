import { http } from "@/http-commons";

class polizas {
  PolizasFechas(fechainicio, fechafin,usuarioid) {
    return http.get(`/Poliza/PolizasFecha/${fechainicio}/${fechafin}/${usuarioid}`);
  }
  BuscaPoliza(id,usuarioid){
    return http.get(`/Poliza/BuscaPoliza/${id}/${usuarioid}`);
  }
  NotificacionEmail(data){
    return http.post(`/Poliza/NotificacionPoliza`,data);
  }
  NotificacionPolizaMasiva(data,stringhtml){
    //console.log(data);
    return http.post(`/Poliza/NotificacionPolizaMasiva`,data,stringhtml);
    
  }
  CargaMasivaExcel(data,cobrada,cancelada,usuarioId,cuentaId){ 
    return http.post(`/cargaMasivaPolizas/${cobrada}/${cancelada}/${usuarioId}/${cuentaId}`,data);
  }

  PolizasLista(data)
  {
    return http.post(`/Poliza/PolizasLista`,data);
  }

}


export default new polizas();