import { http } from "@/http-commons";

class Estatussoluciones {
  all() {
    return http.get('/dashboardsoluciones');
  }
  get(id) {
    return http.get(`/dashboardsoluciones/ListadoPorProceso${id}`);  
  }
  
}

export default new Estatussoluciones();