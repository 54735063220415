import { render, staticRenderFns } from "./referenciaArrendamiento.vue?vue&type=template&id=92f0573e&scoped=true"
import script from "./referenciaArrendamiento.vue?vue&type=script&lang=js"
export * from "./referenciaArrendamiento.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "92f0573e",
  null
  
)

export default component.exports