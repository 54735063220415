import { render, staticRenderFns } from "./listEmail.vue?vue&type=template&id=038e640d&scoped=true"
import script from "./listEmail.vue?vue&type=script&lang=js"
export * from "./listEmail.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "038e640d",
  null
  
)

export default component.exports