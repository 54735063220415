import { http } from "@/http-commons";

class Listanegra {
  all() {
    return http.get('/Listanegra');
  }
  get(id) {
    return http.get(`/Listanegra/${id}`);
  }
  post(Listanegra) {
    return http.post('/Listanegra',Listanegra);
  }
  put(id, Listanegra) {
    return http.put(`/Listanegra/${id}`,Listanegra);
  }
  delete(id) {
    return http.delete(`/Listanegra/${id}`);
  }
  
}

export default new Listanegra();