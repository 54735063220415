import Vue from "vue";
import VueRouter from "vue-router";
import DashboardPlugin from "./material-dashboard";
import App from "./App.vue";
import Chartist from "chartist";
import Notifications from './components/NotificationPlugin';
import VueIframe from 'vue-iframes'
import routes from "./routes/routes";
import "element-ui/lib/theme-chalk/index.css";


// plugin setup

Vue.use(VueRouter);
Vue.use(DashboardPlugin);
Vue.use(Notifications);
Vue.use(VueIframe);


// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
  linkExactActiveClass: "nav-item active",
});

// global library setup
Vue.prototype.$Chartist = Chartist;


/* eslint-disable no-new */
new Vue({
  el: "#app",
  render: (h) => h(App),
  router,
});

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/service-worker.js')
    .then(function(registration) {
      console.log('Service Worker registered with scope:', registration.scope);
    })
    .catch(function(error) {
      console.log('Service Worker registration failed:', error);
    });
}
