<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">Configuración de Email x Representación</h4>
        </md-card-header>
        <md-card-content>
          <md-table
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover"
          >
            <md-table-toolbar>
              <md-field>
                <label for="pages">Por página</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>

              <md-field>
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style="width: 200px"
                  placeholder="Buscar registro"
                  v-model="searchQuery"
                >
                </md-input>
              </md-field>
              <md-field>
                <md-button class="md-primary" @click="ModalPersonalizado(1)"
                  >Nuevo</md-button
                >
              </md-field>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Id" md-sort-by="id">{{
                item.id
              }}</md-table-cell>
              <md-table-cell
                md-label="Usuario"
                md-sort-by="usuarioNomCompleto"
                v-if="usuario.areaId != 3"
                >{{ item.usuario.usuarioNomCompleto }}</md-table-cell
              >
              <md-table-cell
                md-label="Usuario"
                md-sort-by="usuarioNomCompleto"
                v-else
              ></md-table-cell>
              <md-table-cell md-label="Descripcion" md-sort-by="descripcion">{{
                item.descripcion
              }}</md-table-cell>
              <md-table-cell md-label="Plantilla Id" md-sort-by="plantillaId">{{
                item.plantilla.nombre
              }}</md-table-cell>
              <md-table-cell md-label="Asesor" md-sort-by="asesor">{{
                item.asesor
              }}</md-table-cell>
              <md-table-cell md-label="Arrendador" md-sort-by="arrendador">{{
                item.arrendador
              }}</md-table-cell>
              <md-table-cell
                md-label="Arrendatario"
                md-sort-by="arrendatarios"
                >{{ item.arrendatarios }}</md-table-cell
              >
              <md-table-cell md-label="Fiador" md-sort-by="fiadores">{{
                item.fiadores
              }}</md-table-cell>
              <md-table-cell md-label="Estatus" md-sort-by="activo">{{
                item.activo
              }}</md-table-cell>
              <md-table-cell md-label="Acciones">
                <md-button
                  class="md-just-icon md-success md-simple"
                  @click.native="Prueba(item.id)"
                >
                  <md-icon>brush</md-icon>
                </md-button>
                <md-button
                  class="md-just-icon md-warning md-simple"
                  @click.native="ModalPersonalizado(2, item)"
                >
                  <md-icon>edit</md-icon>
                </md-button>
                <md-button
                  class="md-just-icon md-danger md-simple"
                  @click.native="ModalPersonalizado(3, item)"
                >
                  <md-icon>close</md-icon>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
          <div class="footer-table md-table">
            <table>
              <caption>Descripción de los datos en el pie de la tabla</caption>
              <tfoot>
                <tr>
                  <th
                    v-for="item in footerTable"
                    :key="item"
                    class="md-table-head"
                  >
                    <div class="md-table-head-container md-ripple md-disabled">
                      <div class="md-table-head-label">
                        {{ item }}
                      </div>
                    </div>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          >
          </pagination>
        </md-card-actions>
      </md-card>
      <modal v-if="classicModal" @close="classicModalHide">
        <template slot="header">
          <h4 class="modal-title">{{ datosModal.titulo }}</h4>
          <md-button
            class="md-simple md-just-icon md-round modal-default-button"
            @click="classicModalHide"
          >
            <md-icon>clear</md-icon>
          </md-button>
        </template>

        <template slot="body">
          <div class="md-layout-item md-size-100 md-medium-size-100">
            <div class="md-layout">
              <div class="md-layout-item md-size-20 md-medium-size-100">
                <md-field>
                  <label>Id</label>
                  <md-input v-model="id" disabled></md-input>
                  <span class="md-helper-text">Id</span>
                </md-field>
              </div>
              <div
                class="md-layout-item md-size-80 md-medium-size100"
                v-if="this.usuario.areaId != 3"
              >
                <md-field>
                  <label for="usuariosId">(Seleccionar usuario)</label>
                  <md-select v-model="usuariosId">
                    <md-option
                      v-for="r in usuarios"
                      :key="r.usuariosId"
                      :value="r.usuariosId"
                    >
                      {{ r.usuarioNomCompleto }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>
            </div>
          </div>
          <md-field>
            <label>Descripción</label>
            <md-input v-model="descripcion"></md-input>
            <span class="md-helper-text">descripción</span>
          </md-field>
          <div class="md-layout-item md-size-100 md-medium-size-100">
            <div class="md-layout">
              <div class="md-layout-item md-size-50 md-medium-size-100">
                <md-field>
                  <label for="plantillaId">(Seleccionar Plantilla Email)</label>
                  <md-select v-model="plantillaId">
                    <md-option v-for="e in confemail" :key="e.id" :value="e.id">
                      {{ e.nombre }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>
              <div class="md-layout-item md-size-50 md-medium-size-100">
                <md-field>
                  <label for="tipoProcesoId">(Seleccionar Proceso)</label>
                  <md-select v-model="tipoProcesoId">
                    <md-option
                      v-for="t in tipoproceso"
                      :key="t.id"
                      :value="t.id"
                    >
                      {{ t.descripcion }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>
            </div>
          </div>
          <div class="md-layout-item md-size-100 md-medium-size-100">
            <div class="md-layout">
              <div class="md-layout-item md-size-50 md-medium-size-100">
                <md-field>
                  <label>Día número</label>
                  <md-input v-model="dianumero"></md-input>
                  <span class="md-helper-text">dianumero</span>
                </md-field>
              </div>
              <div class="md-layout-item md-size-50 md-medium-size-100">
                <md-field>
                  <label>Día semana</label>
                  <md-input v-model="diasemana"></md-input>
                  <span class="md-helper-text">diasemana</span>
                </md-field>
              </div>
            </div>
          </div>
          <div class="md-layout-item md-size-100 md-medium-size-100">
            <div class="md-layout">
              <div class="md-layout-item md-size-50 md-medium-size-100">
                <md-field>
                  <label>Fecha Inicio Trámite</label>
                  <md-input
                    type="date"
                    class="mb-3"
                    clearable
                    style="width: 200px"
                    placeholder="Fecha Inicio Trámite"
                    v-model="fechaInicioTramite"
                  >
                  </md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-size-50 md-medium-size-100">
                <md-field>
                  <label>Fecha Fin Trámite</label>
                  <md-input
                    type="date"
                    class="mb-3"
                    clearable
                    style="width: 200px"
                    placeholder="Fecha Fin Trámite"
                    v-model="fechaFinTramite"
                  >
                  </md-input>
                </md-field>
              </div>
            </div>
          </div>
          <div class="md-layout-item md-size-100 md-medium-size-100">
            <div class="md-layout">
              <div class="md-layout-item md-size-50 md-medium-size-100">
                <md-field>
                  <label>Fecha Inicio Ejecución</label>
                  <md-input
                    type="date"
                    class="mb-3"
                    clearable
                    style="width: 200px"
                    placeholder="Fecha Inicio Ejecución"
                    v-model="fechaInicioEjecucion"
                  >
                  </md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-size-50 md-medium-size-100">
                <md-field>
                  <label>Fecha Fin Ejecución</label>
                  <md-input
                    type="date"
                    class="mb-3"
                    clearable
                    style="width: 200px"
                    placeholder="Fecha Fin Ejecución"
                    v-model="fechaFinEjecucion"
                  >
                  </md-input>
                </md-field>
              </div>
            </div>
          </div>
          <div class="md-layout-item md-size-100 md-medium-size-100">
            <div class="md-layout">
              <div class="md-layout-item md-size-20 md-medium-size-100">
                <md-switch v-model="asesor">Asesor</md-switch>
              </div>
              <div class="md-layout-item md-size-20 md-medium-size-100">
                <md-switch v-model="arrendador">Arrendador</md-switch>
              </div>
              <div class="md-layout-item md-size-20 md-medium-size-100">
                <md-switch v-model="arrendatario">Arrendatario</md-switch>
              </div>
              <div class="md-layout-item md-size-20 md-medium-size-100">
                <md-switch v-model="fiadores">Fiadores</md-switch>
              </div>
              <div class="md-layout-item md-size-20 md-medium-size-100">
                <md-switch v-model="activo">Estatus</md-switch>
              </div>
            </div>
          </div>
          <div class="md-layout-item md-size-100 md-medium-size-100">
            <div class="md-layout">
              <div class="md-layout-item md-size-20 md-medium-size-100">
                <md-switch v-model="renovaciones">Renovaciones</md-switch>
              </div>
              <div class="md-layout-item md-size-20 md-medium-size-100">
                <md-switch v-model="cumpleaños">Cumpleaños</md-switch>
              </div>
              <div class="md-layout-item md-size-20 md-medium-size-100">
                <md-switch v-model="bienvenida">Bienvenida</md-switch>
              </div>
              <div class="md-layout-item md-size-20 md-medium-size-100">
                <md-switch v-model="ejecutivoRenovacion">Ejecutivo renovación (Recordatorio)</md-switch>
              </div>
              <div class="md-layout-item md-size-20 md-medium-size-100"></div>
            </div>
          </div>
        </template>
        <template slot="footer">
          <md-button class="md-danger md-simple" @click="classicModalHide"
            >Cerrar</md-button
          >
          <md-button
            class="md-primary"
            @click.native="postNow(datosModal.evento)"
            >{{ datosModal.boton }}</md-button
          >
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
import { Pagination, Modal } from "@/components";
import Fuse from "fuse.js";
import Swal from "sweetalert2";
import mensaje from "../../Servicios/ManjeadorErrores/Mensajes";
import servconf from "../../Servicios/Catalogos/confEmailXUsuario";
import servusuario from "../../Servicios/Usuarios/usuario";
import servconfEmail from "../../Servicios/Catalogos/confEmail";
import servTipoProcesoGeneral from "../../Servicios/Catalogos/tipoProcesoGeneral";
import store from "../../Store/index";

export default {
  components: {
    Pagination,
    Modal,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
    usuario() {
      return store.state.usuario;
    },
  },
  data() {
    return {
      currentSort: "id",
      currentSortOrder: "asc",
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      footerTable: [
        "Id",
        "Usuario",
        "Descripcion",
        "Plantilla Id",
        "Asesor",
        "Arrendador",
        "Arrendatario",
        "Fiadores",
        "Estatus",
        "Acciones",
      ],
      searchQuery: "",
      propsToSearch: ["id", "descripcion"],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      classicModal: false,
      id: 0,
      descripcion: null,
      plantillaId: 0,
      asesor: false,
      arrendador: false,
      arrendatario: false,
      fiadores: false,
      activo: false,
      dianumero: null,
      diasemana: null,
      datosModal: {
        titulo: "Crear una área",
        boton: "Confirmar",
        evento: 0,
      },
      usuariosId: 0,
      usuarios: [],
      fechaInicioTramite: null,
      fechaFinTramite: null,
      fechaInicioEjecucion: null,
      fechaFinEjecucion: null,
      confemail: [],
      tipoProcesoId: 0,
      tipoproceso: [],
      renovaciones: null,
      cumpleaños: null,
      bienvenida: null,
      ejecutivoRenovacion: null
    };
  },
  methods: {
    Prueba(id) {
      servconf
        .prueba(id)
        .then((r) => this.ProcesamosResponse(r.data))
        .catch((e) => console.log("Error en enviar prueba", e));
    },
    async getConfEmaill() {
      servconfEmail
        .all()
        .then((r) => (this.confemail = r.data))
        .catch((e) => console.log("Fallo get configuracion email"));
    },
    async getTipoProceso() {
      servTipoProcesoGeneral
        .all()
        .then((r) => (this.tipoproceso = r.data))
        .catch((e) => console.log("Fallo get tipo proceso general"));
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    postNow(exp) {
      var data = {
        id: this.id,
        usuarioId:
          this.usuario.areaId == 3 ? this.usuario.usuariosId : this.usuariosId,
        plantillaId: this.plantillaId,
        descripcion: this.descripcion,
        asesor: this.asesor,
        arrendador: this.arrendador,
        arrendatarios: this.arrendatario,
        fiadores: this.fiadores,
        activo: this.activo,
        fechaInicioTramite: this.fechaInicioTramite,
        fechaFinTramite: this.fechaFinTramite,
        fechaInicioEjecucion: this.fechaInicioEjecucion,
        fechaFinEjecucion: this.fechaFinEjecucion,
        tipoProcesoId: this.tipoProcesoId,
        renovaciones: this.renovaciones,
        cumpleaños: this.cumpleaños,
        bienvenida: this.bienvenida,
        ejecutivoRenovacion: this.ejecutivoRenovacion
      };
      switch (exp) {
        case 1:
          servconf
            .post(data)
            .then((r) => {
              this.ProcesamosResponse(r.data);
            })
            .catch(function (e) {
              mensaje.Error("post-conf", e);
            });
          break;

        case 2:
          if (this.id > 0) {
            servconf
              .put(this.id, data)
              .then((r) => {
                this.ProcesamosResponse(r.data);
              })
              .catch(function (e) {
                mensaje.Error("put-conf", e);
              });
          }
          break;
      }
    },
    getDatos() {
      switch (this.usuario.areaId) {
        case 3:
          servconf
            .usuario(this.usuario.usuariosId)
            .then((a) => {
              this.tableData = a.data;
            })
            .catch((e) => {
              mensaje.Error("conf-id", e);
            });
          break;
        case 1:
          servconf
            .all()
            .then((a) => {
              this.tableData = a.data;
            })
            .catch((e) => {
              mensaje.Error("conf-all", e);
            });
          break;
      }
      this.fuseSearch = new Fuse(this.tableData, {
        keys: ["id"],
        threshold: 0.3,
      });
    },
    classicModalHide() {
      this.Clear();
      this.classicModal = false;
    },
    ModalPersonalizado(exp, row) {
      switch (exp) {
        case 1: //Insertar
          this.classicModal = true;
          this.datosModal.titulo = "Crear configuración de email";
          this.datosModal.boton = "Confirmar";
          this.datosModal.evento = 1;
          break;
        case 2: //Actualizar
          this.id = row.id;
          this.usuariosId = row.usuariosId;
          this.descripcion = row.descripcion;
          this.plantillaId = row.plantillaId;
          this.asesor = row.asesor;
          this.arrendador = row.arrendador;
          this.arrendatario = row.arrendatario;
          this.fiadores = row.fiadores;
          this.activo = row.activo;
          this.plantillaId = row.plantillaId;
          this.tipoProcesoId = row.tipoProcesoId;
          this.renovaciones = row.renovaciones;
          this.cumpleaños = row.cumpleaños;
          this.fechaInicioTramite = row.fechaInicioTramite;
          this.fechaFinTramite = row.fechaFinTramite;
          this.fechaInicioEjecucion = row.fechaInicioEjecucion;
          this.fechaFinEjecucion = row.fechaFinEjecucion;
          this.ejecutivoRenovacion = row.ejecutivoRenovacion;
          this.classicModal = true;
          this.bienvenida = row.bienvenida
          this.datosModal.titulo = "Actualizar configuración de email";
          this.datosModal.boton = "Actualizar";
          this.datosModal.evento = 2;
          break;

        case 3: //Eliminar
          this.id = row.id;
          this.deleteRow(this.id);
          break;
      }
    },
    deleteRow(id) {
      if (this.id > 0) {
        Swal.fire({
          title: "!Eliminar!",
          text: `¿Deseas eliminar el siguiente registro: ${this.id} ?`,
          type: "success",
          showCancelButton: true,
          confirmButtonClass: "md-button md-success",
          cancelButtonClass: "md-button md-danger",
          confirmButtonText: "Si, eliminar!",
          cancelButtonText: "Cancelar",
          buttonsStyling: false,
        })
          .then((r) => {
            if (r.value) {
              servconf
                .delete(id)
                .then((response) => {
                  Swal.fire({
                    title: "Eliminado!",
                    text: `Se ha eliminado el registro ${this.id}`,
                    type: "success",
                    confirmButtonClass: "md-button md-success",
                    cancelButtonText: "Cancelar",
                    buttonsStyling: false,
                  });
                  let mens = {};
                  mens = mensaje.generico(
                    response.config.method,
                    response.status
                  );
                  if (mens.accion == true) {
                    this.getDatos();
                  }
                  this.notifyVue(mens);
                })
                .catch(function (e) {
                  mensaje.Error("usuario-crud-sercategorias-delete", e);
                });
            }
            if (r.dismiss == "cancel") {
              this.Clear();
            }
          })
          .catch(function (e) {
            mensaje.Error("usuario-crud-sercategorias-delete", e);
          });
      }
    },
    notifyVue(m) {
      this.$notify({
        message: m.mensaje,
        timeout: 30000,
        icon: "add_alert",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: m.tipo,
      });
    },
    Clear() {
      this.id = 0;
      this.descripcion = null;
      this.plantillaId = null;
      this.usuariosId = null;
      this.asesor = null;
      this.arrendador = null;
      this.arrendatario = null;
      this.fiadores = null;
      this.activo = null;
      this.bienvenida = null;
    },
    ProcesamosResponse(data) {
      data.forEach((e) => {
        let m = {
          mensaje: "",
          tipo: "",
        };
        switch (e.tipo) {
          case 2: //Atributos por completar
            // se agrega a la clase el error "md-valid" ó "md-error"
            this[e.mensaje] = "md-error";
            break;
          case 1: //Error
            this.isActivo = false;
            m.mensaje = e.mensaje;
            m.tipo = "danger";
            this.notifyVue(m);
            break;
          case 3: //success
            this.tableData = [];
            this.getDatos();
            this.classicModalHide();
            m.mensaje = e.mensaje;
            m.tipo = "success";
            this.notifyVue(m);
            break;
          case 4: //Information
            m.mensaje = e.mensaje;
            m.tipo = "info";
            this.notifyVue(m);
            break;
        }
      });
    },
    async getDatosUsuarios() {
      servusuario
        .all(3)
        .then((p) => {
          this.usuarios = p.data;
        })
        .catch((e) => {
          mensaje.Error("usuario-crud-all", e);
          this.isLoader = false;
        });
    },
  },
  created() {
    if (this.tableData.length == 0) {
      this.getDatos();
    }
    if (this.usuario.areaId != 3 && this.usuario.length == 0) {
      this.getDatosUsuarios();
    }
    if (this.tipoproceso.length == 0) {
      this.getTipoProceso();
    }
    if (this.confemail.length == 0) {
      this.getConfEmaill();
    }
  },
  mounted() {
    if (this.tableData.length == 0) {
      this.getDatos();
    }
    if (this.usuario.areaId != 3 && this.usuario.length == 0) {
      this.getDatosUsuarios();
    }
    if (this.tipoproceso.length == 0) {
      this.getTipoProceso();
    }
    if (this.confemail.length == 0) {
      this.getConfEmaill();
    }
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result.map(r => r.item);
    },
  },
};
</script>

<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.full-control > .md-list {
  width: 320px;
  max-width: 100%;
  height: 400px;
  display: inline-block;
  overflow: auto;
  border: 1px solid rgba(#000, 0.12);
  vertical-align: top;
}
</style>
