var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md-layout"},[_c('md-card',[_c('md-card-header',{staticClass:"md-card-header-text md-card-header-primary"},[_c('div',{staticClass:"card-text"},[_c('h4',{staticClass:"title"},[_vm._v("Encabezado")])]),_c('div',{staticClass:"md-layout-item md-size-100 md-medium-size-100"},[_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-size-33 md-medium-size-100"},[_c('md-field',[_c('label',[_vm._v("PólizaId")]),_c('md-input',{attrs:{"disabled":""},model:{value:(_vm.general.PolizaId),callback:function ($$v) {_vm.$set(_vm.general, "PolizaId", $$v)},expression:"general.PolizaId"}})],1)],1),_c('div',{staticClass:"md-layout-item md-size-33 md-medium-size-100"},[_c('md-field',[_c('label',[_vm._v("Solicitud Id")]),_c('md-input',{attrs:{"disabled":""},model:{value:(_vm.general.SolicitudId),callback:function ($$v) {_vm.$set(_vm.general, "SolicitudId", $$v)},expression:"general.SolicitudId"}})],1)],1),_c('div',{staticClass:"md-layout-item md-size-33 md-medium-size-100"},[_c('md-field',[_c('label',[_vm._v("Solución Id")]),_c('md-input',{attrs:{"disabled":""},model:{value:(_vm.general.SolucionId),callback:function ($$v) {_vm.$set(_vm.general, "SolucionId", $$v)},expression:"general.SolucionId"}})],1)],1),_c('div',{staticClass:"md-layout-item md-size-33 md-medium-size-100"},[_c('md-field',[_c('label',[_vm._v("FisicaMoral Id")]),_c('md-input',{attrs:{"disabled":""},model:{value:(_vm.general.FisicaMoralId),callback:function ($$v) {_vm.$set(_vm.general, "FisicaMoralId", $$v)},expression:"general.FisicaMoralId"}})],1)],1)])])])],1),_c('div',{staticClass:"md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"},[_c('collapse',{attrs:{"collapse":[
      'Solicitud',
      'Persona Fisica',
      'Arrendatarios',
      'Referencia de Arrendamiento',
      'Referencias Personales',
      'Personas que ocuparán el inmueble',
      'Documentos',
      'FiadorF',
      'FiadorM',
      'Investigación PJ',
      'Estatus',
      'Generar Contrato',
    ],"icon":"keyboard_arrow_down","color-collapse":"success"}},[_c('template',{slot:"md-collapse-pane-1"},[_c('editSolicitud')],1),_c('template',{slot:"md-collapse-pane-2"},[_c('arrendatario')],1),_c('template',{slot:"md-collapse-pane-3"},[_c('arrendatarios')],1),_c('template',{slot:"md-collapse-pane-4"},[_c('referenciaArrendamiento')],1),_c('template',{slot:"md-collapse-pane-5"},[_c('referencias')],1),_c('template',{slot:"md-collapse-pane-6"},[_c('personasOcuparanInm')],1),_c('template',{slot:"md-collapse-pane-7"},[_c('documentos')],1),_c('template',{slot:"md-collapse-pane-8"},[_c('FiadorF')],1),_c('template',{slot:"md-collapse-pane-9"},[_c('FiadorMoral')],1),_c('template',{slot:"md-collapse-pane-10"},[_c('investigaciones')],1),_c('template',{slot:"md-collapse-pane-11"},[_c('estatus')],1),(_vm.usuario.areaid == 1 | 12)?_c('template',{slot:"md-collapse-pane-12"},[_c('GenContrato')],1):_vm._e()],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }