import { http } from "@/http-commons";

class parametro {
  all() {
    return http.get('/parametro');
  }
  get(id) {
    return http.get(`/parametro/${id}`);
  }
  post(data) {
    return http.post('/parametro',data);
  }
  put(id, data) {
    return http.put(`/parametro/${id}`,data);
  }
  delete(id) {
    return http.delete(`/parametro/${id}`);
  }
}

export default new parametro();