<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-large-size-100 md-size-100">
        <edit-profile-form header-color="primary"> </edit-profile-form>
      </div>
      <!-- <div class="md-layout-item md-medium-size-100 md-size-33">
        <user-card button-color="primary"> </user-card>
      </div> -->
    </div>
  </div>
</template>

<script>
// import  UserCard  from "@/paginas/User/UserCard.vue";
import  EditProfileForm  from "@/paginas/User/EditProfileForm.vue";
export default {
  components: {
    EditProfileForm,
    // UserCard
  }
};
</script>
<style lang="scss">
.text-right {
  display: flex;
}
</style>
