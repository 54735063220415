import { render, staticRenderFns } from "./UsuariosCrud.vue?vue&type=template&id=44bbdb7e&scoped=true"
import script from "./UsuariosCrud.vue?vue&type=script&lang=js"
export * from "./UsuariosCrud.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44bbdb7e",
  null
  
)

export default component.exports