<template>
  <div class="iframe-container">
    <iframe
      :src="iframeUrl"
      frameborder="0"
      width="100%"
      height="100vh"
      allowtransparency
    >
    </iframe>
  </div>
</template>

<script>
import serMetabase from "@/Servicios/Dashboard/metabase";

export default {
  name: "Metabase",
  components: {},
  data() {
    return {
      iframeUrl:
        "https://metabase.polizajuridica.com.mx/public/dashboard/7d0c1115-5b12-4a0e-8b61-6db0c1b64300",
      myIframe: null,
    };
  },
  methods: {
    getTokenURL() {
      serMetabase.get().then((res) => {
        this.iframeUrl = res.data;
        console.log(this.iframeUrl);
      });
    },
    onLoad(frame) {
      this.myIframe = frame.contentWindow;
    },
  },
  created() {
    //this.getTokenURL();
  },
};
</script>

<style></style>
