import { http } from "@/http-commons";

class TipoProcesoPo {
  all() {
    return http.get('/TipoProcesoPo');
  }
  get(id) {
    return http.get(`/TipoProcesoPo/${id}`);
  }
  post(data) {
    return http.post('/TipoProcesoPo', data);
  }
  put(id, data) {
    return http.put(`/TipoProcesoPo/${id}`, data);
  }
  delete(id) {
    return http.delete(`/TipoProcesoPo/${id}`);
  }
}

export default new TipoProcesoPo();