import { http } from "@/http-commons";

class menu {
  all() {
    return http.get('/MenuP');
  }
  get(id) {
    return http.get(`/MenuP/${id}`);
  }
  post(data) {
    return http.post('/MenuP', data);
  }
  put(id, data) {
    return http.put(`/MenuP/${id}`, data);
  }
  delete(id) {
    return http.delete(`/MenuP/${id}`);
  }
}

export default new menu();