import { http } from "@/http-commons";

class TipoProceso {
  all() {
    return http.get('/TipoProceso');
  }
  get(id) {
    return http.get(`/TipoProceso/${id}`);
  }
  post(data) {
    return http.post('/TipoProceso', data);
  }
  put(id, data) {
    return http.put(`/TipoProceso/${id}`, data);
  }
  delete(id) {
    return http.delete(`/TipoProceso/${id}`);
  }
}

export default new TipoProceso();