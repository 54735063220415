import { http } from "@/http-commons";

class tipoRefComercial {
  all() {
    return http.get('/TipoRefComercial');
  }
  get(id) {
    return http.get(`/TipoRefComercial/${id}`);
  }
  post(data) {
    return http.post('/TipoRefComercial', data);
  }
  put(id, data) {
    return http.put(`/TipoRefComercial/${id}`, data);
  }
  delete(id) {
    return http.delete(`/TipoRefComercial/${id}`);
  }
}

export default new tipoRefComercial();