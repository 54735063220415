<template>
  <div class="md-layout text-center">
    <div class="md-layout-item md-size-50 md-small-size-100">
      <lock-card>
        <h1>Página no encontrada</h1>
      </lock-card>
    </div>
  </div>
</template>
<script>
import { LockCard } from "@/components";
export default {
  components: {
    LockCard
  },
  data() {
    return {
      password: null,
      image: "img/faces/avatar.jpg"
    };
  }
};
</script>
<style></style>
