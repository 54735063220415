import { http } from "@/http-commons";

class estatuspoliza {
  GetEstatusPoliza(id) {
    return http.get(`/UsuarioPoliza/EstatusPoliza/${id}`);
  }
  post(data) {
    return http.post('/UsuarioPoliza', data);
  }
  delete(id) {
    return http.delete(`/UsuarioPoliza/${id}`);
  }
}

export default new estatuspoliza();