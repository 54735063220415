import { http } from "@/http-commons";

class movimientos {
  get(id) {
    return http.get(`/Movimientos/General/${id}`);
  }
  getdp() {
    return http.get('/Movimientos/DetallePoliza');
  }
}


export default new movimientos();