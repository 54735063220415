import { http } from "@/http-commons";

class confemailxusuario {
  all() {
    return http.get(`/confemailxusuario/`);
  }
  usuario(id) {
    return http.get(`/confemailxusuario/usuario/${id}`);
  }
  get(id) {
    return http.get(`/confemailxusuario/${id}`);
  }
  post(data) {
    return http.post('/confemailxusuario', data);
  }
  put(id, data) {
    return http.put(`/confemailxusuario/${id}`, data);
  }
  delete(id) {
    return http.delete(`/confemailxusuario/${id}`);
  }
  prueba(id){
    return http.get(`/confemailxusuario/prueba/${id}`);
  }
}

export default new confemailxusuario();