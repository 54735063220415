<template>
  <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-25">
    <stats-card header-color="primary">
      <template slot="header">
        <div class="card-icon">
          <md-icon>equalizer</md-icon>
        </div>
        <p class="category">{{txtHeader}}</p>
        <h3 class="title" v-if="isMoney == false">
          + {{Cantidad}}
        </h3>
        <h3 class="title" v-else>
          + {{ this.$formatos.formatPrice(Cantidad)}}
        </h3>
      </template>

      <template slot="footer">
        <div class="stats">
          <md-icon>update</md-icon>
          {{txtFooter}}
        </div>
      </template>
    </stats-card>
  </div>
</template>

<script>
import { StatsCard } from "@/components";
export default {//
  name: "cards",
  components: {
    StatsCard
  },
  props: {
    Cantidad: {
      type: Number,
      default: 0,
    },
    txtHeader: {
      type: String,
      default: "",
    },
    Cantidad2: {
      type: Number,
      default: 0,
    },
    txtFooter: {
      type: String,
      default: "",
    },
    isMoney:{
      type: Boolean,
      default: false      
    }
  },
  data() {
    return {
      //auxCantidad: this.Cantidad,
    };
  },
  watch: {
    // Valor(Cantidad) {
    //   this.auxCantidad = Cantidad;
    // },
    // Cantidad(Valor) {
    //   this.$emit("Cantidad", Valor);
    // },
  },
  created() {},
  mounted() {
  },
};
</script>

<style>
</style>