import { http } from "@/http-commons";

class EstructuraKeywords {
  all() {
    return http.get('/EstructuraKeyword');
  }
  get(id) {
    return http.get(`/EstructuraKeyword/${id}`);
  }
  post(data) {
    return http.post('/EstructuraKeyword', data);
  }
  put(id, data) {
    return http.put(`/EstructuraKeyword/${id}`, data);
  }
  delete(id) {
    return http.delete(`/EstructuraKeyword/${id}`);
  }
}

export default new EstructuraKeywords();