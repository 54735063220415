<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100 md-medium-size-100">
      <tabs :tab-name="['Detalle', 'Estatus']" color-button="primary">
        <h4 class="title" slot="header-title">
          Opciones de la póliza: {{ polizaId }}
        </h4>
        <template slot="tab-pane-1">
          <detallepoliza></detallepoliza>
        </template>
        <template slot="tab-pane-2">
          <estatus></estatus>
        </template>
      </tabs>
    </div>
  </div>
</template>
<script>
import { Tabs } from "@/components";
import detallepoliza from "./detallepolizas";
import estatus from "./estatus";
import store from "../../../Store/index";
export default {
  name: "tabspoliza",
  computed: {
    General() {
      return store.state.General;
    },
  },
  components: {
    Tabs,
    detallepoliza,
    estatus,
  },
  data() {
    return {
      polizaId: 0,
    };
  },
  created() {
    this.polizaId = this.General.Poliza;
  },
};
</script>