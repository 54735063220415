<template>
  <div>
    <div>
      <md-button class="md-primary" @click="toggleAll">
        {{ allCollapsed ? 'Expandir todos' : 'Colapsar todos' }}
      </md-button>
    </div>
    <div
      :class="[
        'md-collapse',
        activeCollapse(index + 1),
        { [getColorCollapse(colorCollapse)]: true },
      ]"
      v-for="(item, index) in collapse"
      :key="item"
    >
      <div class="md-collapse-label" @click="toggle(index + 1)">
        <h5 class="md-collapse-title">
          {{ item }}
          <md-icon>{{ icon }}</md-icon>
        </h5>
      </div>

      <collapse-transition>
        <!-- <div class="md-collapse-content" v-show="getActiveCollapse(index + 1)"> -->
          <div class="md-collapse-content">
          <slot :name="getCollapseContent(index + 1)"></slot>
        </div>
      </collapse-transition>
    </div>
  </div>
</template>

<script>
import { CollapseTransition } from "vue2-transitions";

export default {
  name: "collapse",
  components: {
    CollapseTransition,
  },
  props: {
    collapse: Array,
    icon: String,
    colorCollapse: String,
  },
  data() {
    return {
      isActive: null,
      activeIndex: null,
      allCollapsed: false,
    };
  },
  methods: {
    setActive(index) {
      if (this.activeIndex !== null) {
        this.isActive[this.activeIndex] = false;
      }
      this.isActive[index] = !this.isActive[index];
      this.activeIndex = index;
    },
    toggleAll() {
      this.allCollapsed = !this.allCollapsed;
      this.isActive = this.isActive.map(() => this.allCollapsed);
    },
    getActiveCollapse(index) {
      // console.debug("getActiveCollapse: "+index,this.isActive);
      var opc = this.isActive == index
      // console.debug("opc",opc)
      return opc;
    },
    activeCollapse(index) {
      // console.debug("activeCollapse: "+index,this.isActive);
      return {
        "is-active": this.isActive == index,
      };
    },
    toggle(index) {
      // console.debug("toggle: "+index,this.isActive);
      let opc = {
        index,
        isActive: false
      };

      if (index == this.isActive) {
        this.isActive = 0;
      } 
      else {
        this.isActive = index;
      }
    },
    getCollapseContent: function (index) {
      // console.debug("getCollapseContent: "+index);
      return "md-collapse-pane-" + index + "";
    },
    getColorCollapse: function (colorCollapse) {
      // console.debug("getColorCollapse: ",colorCollapse);
      return "md-" + colorCollapse + "";
    },
  },
};
</script>

<style lang="scss" scoped>
.text-center {
  display: flex;
}
</style>
