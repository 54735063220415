import { http } from "@/http-commons";

class Arrendatarios {
  getId(id) {
    return http.get(`/Arrendatarios/${id}`);
  }
  delete(id){
    return http.delete(`/Arrendatarios/${id}`);
  }
  post(data){
    return http.post(`/Arrendatarios`,data);
  }  
  put(id,data){
    return http.put(`/Arrendatarios/${id}`,data);

  }
}
export default new Arrendatarios();