import { http } from "@/http-commons";

class log {
  all() {
    return http.get('/log');
  }
  get(id) {
    return http.get(`/log/${id}`);
  }
  post(log) {
    return http.post('/log', log);
  }
  put(id, log) {
    return http.put(`/log/${id}`, log);
  }
  delete(id) {
    return http.delete(`/log/${id}`);
  }
  deleteall() {
    return http.delete(`/log`);
  }
}

export default new log();