<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-danger">
          <div class="card-icon">
            <md-icon>person</md-icon>
          </div>
          <h4 class="title">Fiador (Persona Fisica)</h4>
        </md-card-header>
        <md-card-content>
          <md-table
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover">

            <md-table-toolbar>
              <h4 class="title">Fiador listado de personas fisícas</h4>
              <md-field>
                <md-button class="md-primary" @click="ModalPersonalizado(1)" style="float: right;"> Nuevo Fiador </md-button>
              </md-field>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Nombre" md-sort-by="areaId"></md-table-cell>
              <md-table-cell
                md-label="Apellido Paterno"
                md-sort-by="areaDescripcion"></md-table-cell
              >
              <md-table-cell md-label="Apellido Materno" md-sort-by="dashboard"></md-table-cell>
              <md-table-cell md-label="Acciones">
                <md-button
                  class="md-just-icon md-warning md-simple"
                  @click.native="ModalPersonalizado(2, item)"
                >
                  <md-icon>edit</md-icon>
                </md-button>
                <md-button
                  class="md-just-icon md-danger md-simple"
                  @click.native="ModalPersonalizado(3, item)"
                >
                  <md-icon>close</md-icon>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>

          <div class="button-container">
            <md-button class="md-default align-left"> ATRAS</md-button>
            <md-button class="md-primary align-right">SIGUIENTE </md-button>
          </div>
        </md-card-content>
      </md-card>

      
      <modal v-if="classicModal" @close="classicModalHide">
        <template slot="header">
          <h4 class="modal-title">{{ datosModal.titulo }}</h4>
          <md-button
            class="md-simple md-just-icon md-round modal-default-button"
            @click="classicModalHide"
          >
            <md-icon>clear</md-icon>
          </md-button>
        </template>

        <template slot="body">
          <md-button class="md-danger md-round" @click="ModalPersonalizado(1)"> GENERAL </md-button>
          <md-button class="md-danger md-round" @click="ModalPersonalizado(4)"> DOMICILIO PARTICULAR </md-button>
          <md-button class="md-danger md-round" @click="ModalPersonalizado(5)"> DOMICILIO GARANTÍA </md-button>
          <md-button class="md-danger md-round" @click="ModalPersonalizado(6)"> DATOS DEL CONYUGUE </md-button>
          <md-button class="md-danger md-round" @click="ModalPersonalizado(7)"> DATOS DE ESCRITURA </md-button>

          <md-card-content>
            <md-table v-model="tableData">
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell>
                  <md-field>
                <label> Nombre </label>
                <md-input v-model="areaDescripcion"></md-input>
                <span class="md-helper-text">nombre</span>
            </md-field>
            <md-field>
                <label> Apellido Paterno </label>
                <md-input v-model="areaDescripcion"></md-input>
                <span class="md-helper-text">app</span>
            </md-field>
            <md-field>
                <label> Apellido Materno </label>
                <md-input v-model="dashboard"></md-input>
                <span class="md-helper-text">apm</span>
            </md-field>
            <md-field>
                <label> Nacionalidad </label>
                <md-input v-model="areaDescripcion"></md-input>
                <span class="md-helper-text">nacionalidad</span>
            </md-field>
            <md-field>
                <label> Condicion migratoria </label>
                <md-input v-model="areaDescripcion"></md-input>
                <span class="md-helper-text">condicion</span>
            </md-field>
          </md-table-cell>

          <md-table-cell>
            <md-field>
                <label> Estado Civil </label>
                <md-input v-model="areaDescripcion"></md-input>
                <span class="md-helper-text">estadoc</span>
            </md-field>
            <md-field>
                <label> E-mail </label>
                <md-input v-model="areaDescripcion"></md-input>
                <span class="md-helper-text">email</span>
            </md-field>
            <md-field>
                <label> Telefono </label>
                <md-input v-model="areaDescripcion"></md-input>
                <span class="md-helper-text">telefono</span>
            </md-field>
            <md-field>
                <label> Celular </label>
                <md-input v-model="areaDescripcion"></md-input>
                <span class="md-helper-text">celular</span>
            </md-field>
            <md-field>
                <label> Parentesco Arrendatario </label>
                <md-input v-model="areaDescripcion"></md-input>
                <span class="md-helper-text">parentescoa</span>
            </md-field>
          </md-table-cell>

          <md-table-cell>
            <md-field>
                <label> Empresa donde labora </label>
                <md-input v-model="areaDescripcion"></md-input>
                <span class="md-helper-text">elabora</span>
            </md-field>
            <md-field>
                <label> Telefono de la Empresa </label>
                <md-input v-model="areaDescripcion"></md-input>
                <span class="md-helper-text">telempresa</span>
            </md-field>
            <md-field>
                <label> Profesión </label>
                <md-input v-model="areaDescripcion"></md-input>
                <span class="md-helper-text">profesion</span>
            </md-field>
            <md-field>
                <label> Tipo de Identificacion </label>
                <md-input v-model="areaDescripcion"></md-input>
                <span class="md-helper-text">tidentificacion</span>
            </md-field>
            <md-field>
                <label> Numero de Identificación</label>
                <md-input v-model="areaDescripcion"></md-input>
                <span class="md-helper-text">nidentificacion</span>
            </md-field>
          </md-table-cell>
            </md-table-row>
            </md-table>
          </md-card-content>

        </template>
        <template slot="footer">
          <md-button class="md-danger md-simple" @click="classicModalHide">CANCELAR</md-button>
          <md-button
            class="md-primary"
            @click.native="postNow(datosModal.evento)"
            >{{ datosModal.boton }}</md-button
          >
        </template>
      </modal>
    </div>
  </div>
</template>

<script> 
import { Pagination, Modal } from "@/components";
import Fuse from "fuse.js";
import Swal from "sweetalert2";
import mensaje from "../../Servicios/ManjeadorErrores/Mensajes";
import servarea from "../../Servicios/Area/area";

export default {
  components: {
    Modal,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      currentSort: "areaId",
      currentSortOrder: "asc",
      pagination: {
        perPage: 5,
        currentPage: 1,
        
        total: 0,
      },
      footerTable: ["Nombre", "Apellido Paterno", "Apellido Materno", "Acciones"],
      searchQuery: "",
      propsToSearch: ["Nombre", "Apellido Paterno", "Apellido Materno"],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      classicModal: false,
      areaId: 0,
      areaDescripcion: null,
      dashboard: null,
      datosModal: {
        titulo: "Crear Fiador",
        boton: "Confirmar",
        evento: 0,
      },
    };
  },
  methods: {
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    getDatosArea() {
      servarea
        .all()
        .then((a) => {
          this.tableData = a.data;
          // console.log(this.tableData);
          // Fuse search initialization.
          this.fuseSearch = new Fuse(this.tableData, {
            keys: ["Nombre"],
            threshold: 0.3,
          });
        })
        .catch((e) => {
          mensaje.Error("Fiador Creado?????", e);
        });
    },
    classicModalHide() {
      this.Clear();
      this.classicModal = false;
    },
    ModalPersonalizado(exp, row) {
      switch (exp) {
        case 1: //Insertar
          this.classicModal = true;
          this.datosModal.titulo = "Crear Fiador";
          this.datosModal.boton = "Confirmar";
          this.datosModal.evento = 1;
          break;
        case 2: //Actualizar
          this.areaId = row.areaId;
          this.areaDescripcion = row.areaDescripcion;
          this.dashboard = row.dashboard;
          this.classicModal = true;
          this.datosModal.titulo = "Actualizar fiador";
          this.datosModal.boton = "Actualizar";
          this.datosModal.evento = 2;
          break;

        case 3: //Eliminar
          this.areaId = row.areaId;
          this.deleteRow(this.areaId);
          break;
      }
    },
  
    deleteRow(id) {
      if (this.areaId > 0) {
        Swal.fire({
          title: "!Eliminar!",
          text: `¿Deseas eliminar el siguiente registro: ${this.areaId} ?`,
          type: "success",
          showCancelButton: true,
          confirmButtonClass: "md-button md-success",
          cancelButtonClass: "md-button md-danger",
          confirmButtonText: "Si, eliminar!",
          cancelButtonText: "Cancelar",
          buttonsStyling: false,
        })
          .then((r) => {
            if (r.value) {
              servarea
                .delete(id)
                .then((response) => {
                  Swal.fire({
                    title: "Eliminado!",
                    text: `Se ha eliminado el registro ${this.areaId}`,
                    type: "success",
                    confirmButtonClass: "md-button md-success",
                    cancelButtonText: "Cancelar",
                    buttonsStyling: false,
                  });
                  if (mens.accion == true) {
                    this.getDatosArea();
                  }
                  this.notifyVue(mens);
                })
                .catch(function (e) {
                  mensaje.Error("usuario-crud-sercategorias-delete", e);
                });
            }
            if (r.dismiss == "cancel") {
              this.Clear();
            }
          })
          .catch(function (e) {
            mensaje.Error("usuario-crud-sercategorias-delete", e);
          });
      }
    },
    notifyVue(Mensajes) {
      this.$notify({
        message: Mensajes.mensaje,
        timeout: 30000,
        icon: "add_alert",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: Mensajes.tipo,
      });
    },
    Clear() {
      this.areaId = 0;
      this.areaDescripcion = null;
      this.dashboard = null;
    },
  },
  created() {
    this.getDatosArea();
  },
  mounted() {},
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        // console.log(value);
        // console.log(this.searchQuery);
        console.log(this.fuseSearch);
        result = this.fuseSearch.search(this.searchQuery);
        // console.log(result);
      }
      this.searchedData = result.map(r => r.item);
    }, 
  },
};
</script>


<style lang="css" scoped> 
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.align-left {
  margin-right: auto;
}

.align-right {
  margin-left: auto;
}
.button-right {
  float: right;
}
.full-control > .md-list {
  width: 320px;
  max-width: 100%;
  height: 400px;
  display: inline-block;
  overflow: auto;
  border: 1px solid rgba(#000, 0.12);
  vertical-align: top;
}
</style>
