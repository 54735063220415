import { http } from "@/http-commons";

class Contabilidad {
//   all() {
//     return http.get('/Contabilidad');
//   }
  async get(id) {
    return http.get(`/Contabilidad/${id}`);
  }
  async post(UsuarioId,CuentaId,data) {
    return http.post(`Contabilidad/${UsuarioId}/${CuentaId}`, data);
  }
  async ConfirmDeleteCorte(id, userid,tipo) {
    return http.get(`/Contabilidad/ConfirmacionCancelacionCorte/${id}/${userid}/${tipo}`);
  }
}

export default new Contabilidad();