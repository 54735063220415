import XLSX from "xlsx";

class excel {
    GeneraExcel(tableData) {
        function DescargarExcel(s) {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
            return buf;
        }
        var wb = XLSX.utils.book_new();
        wb.Props = {
            Title: "Solicitudes",
            Author: "sispo.web",
            CreatedDate: new Date()
        };
        wb.SheetNames.push("Solicitudes");
        var ws = XLSX.utils.json_to_sheet(tableData);
        wb.Sheets["Solicitudes"] = ws;
        var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
        var excel = new Blob([DescargarExcel(wbout)], { type: "application/octet-stream" });
        var link = window.URL.createObjectURL(excel);
        var fileLink = document.createElement('a');
        fileLink.href = link;
        fileLink.download = "Solicitudes.xlsx";
        fileLink.click();
    }
}

export default new excel();