<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100 mx-auto">
      <md-card class="md-card-calendar">
        <md-card-header class="md-card-header-icon md-card-header-blue">
          <div class="card-icon">
            <md-icon>calendar_today</md-icon>
          </div>
          <h4 class="title"> Agenda </h4>
        </md-card-header>

        <md-card-content>
          <fullCalendar ref="calendar" :options="calendarOptions" />
        </md-card-content>
      </md-card>
      <modal v-if="classicModal" @close="classicModalHide">
        <template slot="body">
          <md-field>
            <label>Id</label>
            <md-input v-model="CalendarioId" disabled></md-input>
            <span class="md-helper-text">Id</span> 
          </md-field>
          <md-field>
            <label>Seleccionar Tipo Evento</label>
            <md-select v-model="CalendarioDescripcion">
              <md-option value="Fecha Tentativa Firma">Fecha Tentativa Firma</md-option>
              <md-option value="Entrega Inmueble">Entrega Inmueble</md-option>
              <md-option value="Juzgado">Juzgado</md-option>
              <md-option value="Recabar Firmas">Recabar Firmas</md-option>
              <md-option value="Platica Publica">Platica Publica</md-option>
              <md-option value="Platica Interna">Platica Interna</md-option>
            </md-select>
          </md-field>

          <md-field>
            <label>Fecha</label>
            <md-input v-model="CalendarioFechaFirma" disabled></md-input>
            <span class="md-helper-text">Fecha</span>
          </md-field>
          <md-field>
            <label>Ubicacion</label>
            <md-input v-model="CalendarioUbicacion"></md-input>
            <span class="md-helper-text">Ubicacion</span>
          </md-field>
          <md-field>
            <label>Estatus</label>
            <md-input v-model="CalendarioEstatus"></md-input>
            <span class="md-helper-text">Estatus</span> 
          </md-field>
          
          <md-field>
            <label>Usuario</label>
            <md-input v-model="UsuariosId"></md-input>
            <span class="md-helper-text">Usuario</span>
          </md-field>
        </template>
        <template slot="footer">
          <md-button class="md-danger md-simple" @click="classicModalHide">Cerrar</md-button>
          <md-button class="md-success md-simple" @click="classicModalHide">Guardar</md-button>
        </template>
      </modal>
    </div>
  </div>
</template>
<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { INITIAL_EVENTS, createEventId } from "./event-utils";
import servAgenda from "@/Servicios/Agenda/agenda";
import Swal from "sweetalert2";
import { Modal } from "@/components";

export default {
  components: {
    FullCalendar,
    Modal
  },
  computed: {
    usuario() {
      return store.state.usuario;
    },
    general() {
      return store.state.General;
    },
    modo() {
      return store.state.Modo;
    },
    messageClass () {
        return {
          'md-invalid': this.isBandera

        }
      },
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    general() {
      return store.state.General;
    },
  },
  data() {
  return {
    classicModal: false,
    calendarOptions: {
      plugins: [
        dayGridPlugin,
        timeGridPlugin,
        interactionPlugin, // needed for dateClick
      ],
      headerToolbar: {
        center: "dayGridMonth,timeGridWeek,timeGridDay",
        right: "prev,next,today",
      },
      initialView: "dayGridMonth",
      initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
      editable: true, 
      selectable: true,
      select: this.handleDateSelect,
      eventClick: this.handleEventClick,
      eventsSet: this.handleEvents,
    },
    currentEvents: [],
    //id foreign key
    FisicaMoralId: 0,
    UsuariosId: 0,
    //agregar todos los inputs
    CalendarioId: 0,
    CalendarioFechaFirma: null,
    CalendarioUbicacion: null,
    CalendarioEstatus: null,
    CalendarioDescripcion: null,
    datosModal: {
        titulo: "Crear un evento",
        boton: "Confirmar",
        evento: 0,
      },
  };
},

  methods: {
    classicModalHide() {
      this.classicModal = false;
    },
    postNow(exp) {
      var data = {
        CalendarioId: this.CalendarioId,
        CalendarioFechaFirma: this. CalendarioFechaFirma,
        CalendarioUbicacion: this.CalendarioUbicacion,
        CalendarioEstatus: this.CalendarioEstatus,
        CalendarioDescripcion: this.CalendarioDescripcion,
        FisicaMoralId: this.FisicaMoralId,
        UsuariosId: this.UsuariosId,
      };
      switch (exp) {
        case 1:
          servAgenda
            .post(data)
            .then((r) => {
              this.ProcesamosResponse(r.data)         
            })
            .catch(function (e) {
              mensaje.Error("agenda-crud-post", e);
            });
        break;

        case 2:
            servAgenda
              .put(this.CalendarioId, data)
              .then((r) => {
                this.classicModal = true;
                this.ProcesamosResponse(r.data)
              })
              .catch(function (e) {
                mensaje.Error("agenda-crud-put", e);
              });
          
        break;
      }
    },

    Asignamos(r) {
      this.CalendarioId = r.CalendarioId;
      this.CalendarioFechaFirma = r.CalendarioFechaFirma;
      this.CalendarioUbicacion = r.CalendarioUbicacion;
      this.CalendarioEstatus = r.CalendarioEstatus;
      this.CalendarioDescripcion = r.CalendarioDescripcion;
    },

    notifyVue(m) {
      this.$notify({
        message: m.mensaje,
        timeout: 30000,
        icon: "add_alert",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: m.tipo,
      });
    },

    ProcesamosResponse(data) {
      data.forEach((e) => {
        let m = {
          mensaje: "",
          tipo: "",
        };
        switch (e.tipo) {
          case 1: //Error
            m.mensaje = e.mensaje;
            m.tipo = "danger";
            this.notifyVue(m);
            break;
          case 2: //Atributos por completar
            this[e.mensaje];
            m.mensaje = e.mensaje;
            m.tipo = "danger";
            this.notifyVue(m);
            break;
          case 3: //success
            this.getDatos();
            m.mensaje = e.mensaje;
            m.tipo = "success";
            this.notifyVue(m);
            break;
          case 4: //Information
            m.mensaje = e.mensaje;
            m.tipo = "info";
            this.notifyVue(m);
            break;
        }
      });
    },




    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends; 
    },

    handleDateSelect(selectInfo) {
      this.classicModal = true;
    const eventDate = selectInfo.event.start;
    const formattedDate = eventDate.toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' });
    
    this.CalendarioFechaFirma = formattedDate;
  },

    handleEventClick(clickInfo) {
    this.classicModal = true;
    const eventDate = clickInfo.event.start;
    const formattedDate = eventDate.toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' });
    
    this.CalendarioFechaFirma = formattedDate;
  },

    handleEvents(events) {
      this.currentEvents = events;
    },
  },
};
</script>
<style lang="scss" scoped>
#fullCalendar {
  min-height: 300px;
}

.md-card-calendar {
  .md-card-content {
    padding: 0 !important;
  }
}

.text-center {
  text-align: center;
}
</style>