import { http } from "@/http-commons";

class concluidaSol {
  all() {
    return http.get('/dashboardsoluciones/Concluida');
  }
  get(id) {
    return http.get(`/dashboardsoluciones/Concluida${id}`);  
  }
  
}

export default new concluidaSol();