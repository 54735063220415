<template>
  <div class="iframe-container">
    <div class="container-phone" id="connect-container"></div>
  </div>
</template>

<script>
import "amazon-connect-streams";

export default {
  name: "Phone",
  mounted() {
    const containerDiv = document.getElementById("connect-container");
    connect.core.initCCP(containerDiv, {
      ccpUrl: "https://polizajuridica.my.connect.aws/connect/ccp-v2/",
      loginPopup: true,
      softphone: {
        allowFramedSoftphone: true,
      },
      region: "us-east-1",
    });

    // connect.contact((contact) => {
    //   console.log("Contacto: ", contact).onIncoming();
    //   contact.onIncoming(() => {
    //     const phoneNumber = contact.getCustomerEndpoint().phoneNumber;
    //     console.log("Llamada entrante No: " + phoneNumber);
    //     this.$notify({
    //       message: "Llamada entrante No: " + phoneNumber,
    //       timeout: 30000,
    //       icon: "add_alert",
    //       horizontalAlign: "right",
    //       verticalAlign: "top",
    //       type: "info",
    //     });
    //   });
    // });

    connect.contact((contact) => {
      console.log("Contacto: ", contact.getStatus().type);
      contact.onIncoming(() =>{
        console.log("Llamada entrante");
      });
    });
    
    connect.agent((agent) => {
      this.$notify({
          message: "Te encuentras: " + agent.getState().name,
          timeout: 30000,
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "info",
        });
      console.log("Estado del agente: ", agent.getState().name);
      // Esto te ayudará a entender si el agente se está conectando correctamente
    });

    connect.streams.onInitialized(() => {
      console.log("Streams API initialized");
    });
  },
};
</script>

<style>
.container-phone {
  width: "100%";
  height: 600px;
}
</style>
