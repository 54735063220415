<template>
  <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50">
    <chart-card
      header-animation="false"
      :chart-data="dataChart.data"
      :chart-options="dataChart.options"
      :chart-responsive-options="dataChart.responsiveOptions"
      chart-type="Bar"
      chart-inside-header
      background-color="primary"
    >
      <!-- <md-icon slot="fixed-button">build</md-icon>
      <md-button class="md-simple md-info md-just-icon" slot="first-button">
        <md-icon>refresh</md-icon>
        <md-tooltip md-direction="bottom">Refresh</md-tooltip>
      </md-button>
      <md-button class="md-simple md-just-icon" slot="second-button">
        <md-icon>edit</md-icon>
        <md-tooltip md-direction="bottom">Change Date</md-tooltip>
      </md-button> -->

      <template slot="content">
        <h4 class="title">{{Title}}</h4>
        <p class="category">{{subTitle}}</p>
      </template>

      <!-- <template slot="footer">
        <div class="stats">
          <md-icon>access_time</md-icon>
          updated <animated-number :value="10"></animated-number> days ago
        </div>
      </template> -->
    </chart-card>
  </div>
</template>

<script>
import { ChartCard } from "@/components";
export default {
  components: {
    ChartCard,    
  },
  props: {
    labels: {
      type: Array,
      //default: []
    },
    series: {
      type: Array,
      //default: []
    },
    Title: {
      type: String,
      default: "",
    },
    subTitle: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dataChart: {
        data: {
          labels: this.labels, //etiquetas en horizontal
          series: [this.series], //datos vertical
        },
        options: {
          axisX: {
            showGrid: false,
          },
          low: 0,
          high: 1000,
          chartPadding: {
            top: 0,
            right: 5,
            bottom: 0,
            left: 0,
          },
        },
        responsiveOptions: [
          [
            "screen and (max-width: 640px)",
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function (value) {
                  return value[0];
                },
              },
            },
          ],
        ],
      },
    };
  },
  created(){
    console.log(this.dataChart.data.label);
  },
  mounted(){
    console.log(this.dataChart);
  }
};
</script>

<style>
</style>