import aws from "aws-sdk";
const BUCKET_NAME = process.env.VUE_APP_AWS_BUCKET_NAME;
const pathPrincila = process.env.VUE_APP_AWS_PATH;
const s3 = new aws.S3({
    accessKeyId: process.env.VUE_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.VUE_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.VUE_APP_AWS_REGION,
    Bucket: BUCKET_NAME
});

class servicioStorage {

    async upload(filePath, file, type) {
        console.log("filePathdentro servicio", filePath);
        const params = {
            Bucket: BUCKET_NAME,
            Key: `${pathPrincila}${filePath}`,
            Body: file,
            Expires: 3000,
            ContentType: type
        };
        return await s3.upload(params)
            .promise(); 

    }
    async delete(filePath, file, type) {
        const params = {
            Bucket: BUCKET_NAME,
            Key: `${pathPrincila}${filePath}`,
            Body: file,
            Expires: 3000,
            ContentType: type
        };
        return await s3.deleteObject(params)
            .promise(response => {
                return response.Location
            })
            .catch((err) => {
                console.log("errorS3", err);
                return "error" + err
            });
    }
    async get(id, data, type) {
        const params = {
            Bucket: BUCKET_NAME,
            Key: `${pathPrincila}${filePath}`,
            Body: file,
            Expires: 3000,
            ContentType: type
        };
        return await s3.getObject(params)
            .promise(response => response.Location)
            .catch((err) => "error" + err);
    }
}
export default new servicioStorage();
