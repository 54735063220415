import { http } from "@/http-commons";

class eventos {
  getVentasGerencia(data) {
    console.log(data);
    return http.post('/Reportes',data,{timeout: 1500000});
  }
  EstadoCuenta(data) {    
    return http.post('/EstadoCuenta',data,{timeout: 1500000});
  }
  HistorialPoliza(data) {
    return http.post('/HistorialPolizas',data,{timeout: 1500000});
  }
  Renovaciones(data) {
    return http.post('/Renovaciones',data,{timeout: 1500000});
  }
}

export default new eventos();