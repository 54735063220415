<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">Log</h4>
        </md-card-header>
        <md-card-content>
          <md-table
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover"
          >
            <md-table-toolbar>
              <md-field>
                <label for="pages">Por página</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >{{ item }}</md-option>
                </md-select>
              </md-field>

              <md-field>
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style="width: 200px"
                  placeholder="Buscar registro"
                  v-model="searchQuery"
                ></md-input>
              </md-field>
              <md-field>
                <md-button class="md-primary" @click="ModalPersonalizado(1)">Nuevo</md-button>
              </md-field>
            </md-table-toolbar> 
            <md-table-row slot="md-table-row" slot-scope="{ item }"> 
              <md-table-cell md-label="Id" md-sort-by="logId"> 
                {{
                item.logId
                }}
              </md-table-cell>
              <md-table-cell
                md-label="Pantalla"
                md-sort-by="logPantalla"
              >{{ item.logPantalla }}</md-table-cell>
              <md-table-cell md-label="Proceso" md-sort-by="logProceso">
                {{
                item.logProceso
                }}
              </md-table-cell>
              <md-table-cell
                md-label="Objeto In"
                md-sort-by="logObjetoIn"
              >{{ item.logObjetoIn }}</md-table-cell>
              <md-table-cell
                md-label="Objeto out"
                md-sort-by="logObjetoOut"
              >{{ item.logObjetoOut }}</md-table-cell>
              <md-table-cell
                md-label="Fecha"
                md-sort-by="logFecha"
              >{{ item.logFecha }}</md-table-cell>
              
              <md-table-cell md-label="Acciones">
                <md-button
                  class="md-just-icon md-warning md-simple"
                  @click.native="ModalPersonalizado(2, item)"
                >
                  <md-icon>edit</md-icon>
                </md-button>
                <md-button
                  class="md-just-icon md-danger md-simple"
                  @click.native="ModalPersonalizado(3, item)"
                >
                  <md-icon>close</md-icon>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
          <div class="footer-table md-table">
            <table>
              <caption>Descripción de los datos en el pie de la tabla</caption>
              <tfoot>
                <tr>
                  <th v-for="item in footerTable" :key="item" class="md-table-head">
                    <div class="md-table-head-container md-ripple md-disabled">
                      <div class="md-table-head-label">{{ item }}</div>
                    </div>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class>
            <p class="card-category">Showing {{ from + 1 }} to {{ to }} of {{ total }} entries</p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          ></pagination>
        </md-card-actions>
      </md-card>
      <modal v-if="classicModal" @close="classicModalHide">
        <template slot="header">
          <h4 class="modal-title">{{ datosModal.titulo }}</h4>
          <md-button
            class="md-simple md-just-icon md-round modal-default-button"
            @click="classicModalHide"
          >
            <md-icon>clear</md-icon>
          </md-button>
        </template>

        <template slot="body">
          <md-field>
            <label>Id</label>
            <md-input v-model="logId" disabled></md-input>
            <span class="md-helper-text">Id</span>
          </md-field>
          <md-field>
            <label>Pantalla</label>
            <md-input v-model="logPantalla"></md-input>
            <span class="md-helper-text">Pantalla</span>
          </md-field>
          <md-field>
            <label>Proceso</label>
            <md-input v-model="logProceso"></md-input>
            <span class="md-helper-text">Proceso</span>
          </md-field>
          <md-field>
            <label>Objeto In</label>
            <md-input v-model="logObjetoIn"></md-input>
            <span class="md-helper-text">Objeto In</span>
          </md-field>
          <md-field>
            <label>Objeto Out</label>
            <md-input v-model="logObjetoOut"></md-input>
            <span class="md-helper-text">Objeto Out</span>
          </md-field>
          <!--<md-field>
            <label>Fecha</label>
            <md-input v-model="logFecha"></md-input>
            <span class="md-helper-text">Fecha</span>
          </md-field>-->
        </template>
        <template slot="footer">
          <md-button class="md-danger md-simple" @click="classicModalHide">Cerrar</md-button>
          <md-button
            class="md-primary"
            @click.native="postNow(datosModal.evento)"
          >{{ datosModal.boton }}</md-button>
        </template>
      </modal>
    </div>
  </div>
</template>
  
  <script>
import { Pagination, Modal } from "@/components";
import Fuse from "fuse.js";
import Swal from "sweetalert2";
import mensaje from "../../Servicios/ManjeadorErrores/Mensajes";
import servlog from "../../Servicios/Seguridad/log";

export default {
  components: {
    Pagination,
    Modal,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      currentSort: "logId",
      currentSortOrder: "asc",
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      footerTable: ["Id", "Pantalla", "Proceso", "Objeto In", "Objeto Out", "Fecha", "Acciones"],   
      searchQuery: "",
      propsToSearch: ["logId", "logPantalla", "logProceso","logObjetoIn", "logObjetoOut", "logFecha"],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      classicModal: false,
      logId: 0,
      logPantalla: null,
      logProceso: null,
      logObjetoIn: null,
      logObjetoOut: null,
      logFecha: null, 
      datosModal: {
        titulo: "Crear un nuevo Log",
        boton: "Confirmar",
        evento: 0,
      },
    };
  },
  methods: {
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    postNow(exp) {
      let today= new Date();
      var data = {
        logId: this.logId,
        logPantalla: this.logPantalla,
        logProceso: this.logProceso,
        logObjetoIn: this.logObjetoIn,
        logObjetoOut: this.logObjetoOut,
        logFecha: today,
      };
      switch (exp) {
        case 1:
          servlog
            .post(data)
            .then((r) => {
              //hay que cerrar el model
              this.classicModal = false;
              let mens = {};
              mens = mensaje.generico(r.config.method, r.status);
              if (mens.accion == true) {
                this.getDatos();
              }
              this.notifyVue(mens);
              //Aqui recuperamos los datos
            })
            .catch(function (e) {
              mensaje.Error("Log-crud-post", e);
            });
          break;

        case 2:
          if (this.logId > 0) {
            servlog
              .put(this.logId, data)
              .then((r) => {
                //hay que cerrar el model
                this.classicModal = false;
                let mens = {};
                mens = mensaje.generico(r.config.method, r.status);
                if (mens.accion == true) {
                  this.getDatos();
                }
                this.notifyVue(mens);
                //Aqui recuperamos los datos
              })
              .catch(function (e) {
                mensaje.Error("Log-crud-put", e);
              });
          }
          break;
      }
    },
    getDatos() {
      servlog
        .all()
        .then((a) => {
          this.tableData = a.data;
          // console.log(this.tableData);
          // Fuse search initialization.
          this.fuseSearch = new Fuse(this.tableData, {
            keys: ["logId"],
            threshold: 0.3,
          });
        })
        .catch((e) => {
          mensaje.Error("Log-all", e);
        });
    },
    classicModalHide() {
      this.Clear();
      this.classicModal = false;
    },
    ModalPersonalizado(exp, row) {
      switch (exp) {
        case 1: //Insertar
          this.classicModal = true;
          this.datosModal.titulo = "Crear un Log";
          this.datosModal.boton = "Confirmar";
          this.datosModal.evento = 1;
          break;
        case 2: //Actualizar
          this.logId = row.logId;
          this.logPantalla = row.logPantalla;
          this.logProceso = row.logProceso;
          this.logObjetoIn = row.logObjetoIn;
          this.logObjetoOut = row.logObjetoOut;
          this.logFecha = row.logFecha;
          this.classicModal = true;
          this.datosModal.titulo = "Actualizar Log";
          this.datosModal.boton = "Actualizar";
          this.datosModal.evento = 2;
          break;

        case 3: //Eliminar
          this.logId = row.logId;
          this.deleteRow(this.logId);
          break;
      }
    },
    deleteRow(id) {
      if (this.logId > 0) {
        Swal.fire({
          title: "!Eliminar!",
          text: `¿Deseas eliminar el siguiente registro: ${this.logId} ?`,
          type: "success",
          showCancelButton: true,
          confirmButtonClass: "md-button md-success",
          cancelButtonClass: "md-button md-danger",
          confirmButtonText: "Si, eliminar!",
          cancelButtonText: "Cancelar",
          buttonsStyling: false,
        })
          .then((r) => {
            if (r.value) {
              servlog
                .delete(id)
                .then((response) => {
                  Swal.fire({
                    title: "Eliminado!",
                    text: `Se ha eliminado el registro ${this.logId}`,
                    type: "success",
                    confirmButtonClass: "md-button md-success",
                    cancelButtonText: "Cancelar",
                    buttonsStyling: false,
                  });
                  let mens = {};
                  mens = mensaje.generico(
                    response.config.method,
                    response.status
                  );
                  if (mens.accion == true) {
                    this.getDatos();
                  }
                  this.notifyVue(mens);
                })
                .catch(function (e) {
                  mensaje.Error("usuario-crud-sercategorias-delete", e);
                });
            }
            if (r.dismiss == "cancel") {
              this.Clear();
            }
          })
          .catch(function (e) {
            mensaje.Error("usuario-crud-sercategorias-delete", e);
          });
      }
    },
    notifyVue(Mensajes) {
      this.$notify({
        message: Mensajes.mensaje,
        timeout: 30000,
        icon: "add_alert",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: Mensajes.tipo,
      });
    },
    Clear() {
      this.logId = 0;
      this.logPantalla = null;
      this.logProceso = null;
      this.logObjetoIn = null;
      this.logObjetoOut = null;
      this.logFecha = null;
    },
  },
  created() {
    this.getDatos();
  },
  mounted() {},
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        // console.log(value);
        // console.log(this.searchQuery);
        console.log(this.fuseSearch);
        result = this.fuseSearch.search(this.searchQuery);
        // console.log(result);
      }
      this.searchedData = result.map(r => r.item);
    },
  },
};
</script>
  
  <style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.full-control > .md-list {
  width: 320px;
  max-width: 100%;
  height: 400px;
  display: inline-block;
  overflow: auto;
  border: 1px solid rgba(#000, 0.12);
  vertical-align: top;
}
</style>
  