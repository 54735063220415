import { http } from "@/http-commons";

class EventoUsuario {
  all() {
    return http.get('/EventoUsuario');
  }
  get(id) {
    return http.get(`/EventoUsuario/${id}`);
  }
  post(representacion) {
    return http.post('/EventoUsuario',representacion);
  }
  put(id, representacion) {
    return http.put(`/EventoUsuario/${id}`,representacion);
  }
  delete(id) {
    return http.delete(`/EventoUsuario/${id}`);
  }
  GetUsuariosPorEvento(id) {
    return http.get(`/EventoUsuario/GetUsuariosPorEvento/${id}`);
  }
}

export default new EventoUsuario();