<template>
    <div class="md-layout">
        <md-card>
            <md-card-header class="md-card-header-text md-card-header-primary">
                <div class="card-text">
                    <h4 class="title">Polizas</h4>
                </div>
            </md-card-header>
            <md-card-content>
                <md-table :value="queriedData" :md-sort.sync="currentSort" :md-sort-order.sync="currentSortOrder"
                    :md-sort-fn="customSort" class="paginated-table table-striped table-hover">
                    <md-table-toolbar>
                        <md-field>
                            <label for="pages">Por página</label>
                            <md-select v-model="pagination.perPage" name="pages" style="width: 75px">
                                <md-option v-for="item in pagination.perPageOptions" :key="item" :label="item"
                                    :value="item">
                                    {{ item }}
                                </md-option>
                            </md-select>
                        </md-field>
                        <md-field>
                            <md-input type="search" class="mb-3" clearable style="width: 200px"
                                placeholder="Buscar registro" v-model="searchQuery"></md-input>
                        </md-field>
                    </md-table-toolbar>
                    <md-table-row slot="md-table-row" slot-scope="{ item }">
                        <md-table-cell md-label="Ejecutiva">{{
                            item.nombre
                        }}</md-table-cell>
                        <md-table-cell md-label="Cantidad Pólizas">{{
                            item.cantidadPoliza
                        }}</md-table-cell>
                        <md-table-cell md-label="Total Venta" v-if="isAdministracion">{{
                            item.totalVenta
                        }}</md-table-cell>
                        <md-table-cell md-label="Total Regalias" v-if="isAdministracion">{{
                            item.totalRegalias
                        }}</md-table-cell>
                        <md-table-cell md-label="Cobrado" v-if="isAdministracion">{{
                            item.totalCobrado
                        }}</md-table-cell>
                        <md-table-cell md-label="Pendiente Cobro" v-if="isAdministracion">{{
                            item.pendienteCobro
                        }}</md-table-cell>
                    </md-table-row>
                </md-table>
                <div class="footer-table md-table">
                    <table>
                        <tfoot>
                            <tr>
                                <th v-for="item in footerTable" :key="item" class="md-table-head">
                                    <div class="md-table-head-container md-ripple md-disabled">
                                        <div class="md-table-head-label">
                                            {{ item }}
                                        </div>
                                    </div>
                                </th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </md-card-content>
            <md-card-actions md-alignment="space-between">
                <div class="">
                    <p class="card-category">
                        Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                    </p>
                </div>
                <pagination class="pagination-no-border pagination-primary" v-model="pagination.currentPage"
                    :per-page="pagination.perPage" :total="total">
                </pagination>
            </md-card-actions>
        </md-card>
    </div>
</template>

<script>
import { Pagination } from "@/components";
import store from "@/Store";
import Fuse from "fuse.js";

export default {
    props: {
        tableData: {
            type: Array,
            required: true,
            default: () => [],
        },
        isAdministracion: {
            type: Boolean,
            required: true,
            default: false,
        },
    },
    components: {
        Pagination,
    },
    computed: {
        queriedData() {
            let result = this.tableData;
            if (this.searchedData.length > 0) {
                result = this.searchedData;
            }
            return result.slice(this.from, this.to);
        },
        to() {
            let highBound = this.from + this.pagination.perPage;
            if (this.total < highBound) {
                highBound = this.total;
            }
            return highBound;
        },
        from() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
            return this.searchedData.length > 0
                ? this.searchedData.length
                : this.tableData.length;
        },
    },
    data() {
        return {
            propsToSearch: ["nombre"],
            currentSort: "representanteId",
            currentSortOrder: "desc",
            searchedData: [],
            searchQuery: "",
            fuseSearch: null,
            isLoader: false,
            pagination: {
                perPage: 5,
                currentPage: 1,
                perPageOptions: [5, 10, 25, 50],
                total: 0,
            },
            footerTable: [
                "Ejecutiv@",
                "Cantidad Polizas",
                "Total Venta",
                "Total Regalias",
                "Cobrado",
            ],
        }
    },
    methods: {
        Redirecciono(item) {
            store.state.Modo = 5;
            store.state.General.SolicitudId = item.solicitudId;
            this.$router.push("operacion");
        },
        customSort(value) {
            return value.sort((a, b) => {
                const sortBy = this.currentSort;
                if (this.currentSortOrder === "desc") {
                    return a[sortBy].localeCompare(b[sortBy]);
                }
                return b[sortBy].localeCompare(a[sortBy]);
            });
        },
    },
    created() {

        this.fuseSearch = new Fuse(this.tableData, {
            keys: this.propsToSearch,
            threshold: 0.3,
        });

        if(this.isAdministracion){
            this.footerTable = [
                "Ejecutiv@",
                "Cantidad Polizas",
                "Total Venta",
                "Total Regalias",
                "Cobrado",
            ]
        }else{
            this.footerTable = [
                "Ejecutiv@",
                "Cantidad Polizas",
            ]
        }

    },
    watch: {
        searchQuery(value) {
            let result = this.tableData;
            if (value !== "") {
                result = this.fuseSearch.search(this.searchQuery);
            }
            this.searchedData = result.map(r => r.item);
        }
    }
}
</script>
<style></style>