<template> 
  <div class="md-layout">
    <div class="md-layout-item md-small-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-primary">
          <div class="card-icon">
            <md-icon>person</md-icon>
          </div>
          <h4 class="title">Referencia de arrendamiento</h4>
        </md-card-header>
        <md-card-content>
          <div class="md-layout-item md-size-100 md-medium-size-100">
            <div class="md-layout-item md-size-100 md-medium-size-100">
              <div class="md-layout">
                <div class="md-layout-item md-size-33 md-medium-size-100">
                  <md-field>
                    <label>Nombre(s)</label>
                    <md-input
                      v-model="refArrenNombres"
                      :disabled="this.modo == 5 ? true : false"
                      required
                    ></md-input>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-33 md-medium-size-100">
                  <md-field>
                    <label>Apellido Paterno</label>
                    <md-input
                      v-model="refArrenApePaterno"
                      :disabled="this.modo == 5 ? true : false"
                      required
                    ></md-input>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-33 md-medium-size-100">
                  <md-field>
                    <label>Apellido Materno</label>
                    <md-input
                      v-model="refArrenApeMaterno"
                      :disabled="this.modo == 5 ? true : false"
                      required
                    ></md-input>
                  </md-field>
                </div>
              </div>
            </div>
            <div class="md-layout-item md-size-100 md-medium-size-100">
              <div class="md-layout">
                <div class="md-layout-item md-size-50 md-medium-size-100">
                  <md-field>
                    <label>Teléfono</label>
                    <md-input
                      v-model="refArrenTelefono"
                      :disabled="this.modo == 5 ? true : false"
                      required
                    ></md-input>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-50 md-medium-size-100">
                  <md-field>
                    <label>Domicilio</label>
                    <md-input
                      v-model="refArrenDomicilio"
                      :disabled="this.modo == 5 ? true : false"
                      required
                    ></md-input>
                  </md-field>
                </div>
              </div>
            </div>
            <div class="md-layout-item md-size-100 md-medium-size-100">
              <div class="md-layout">
                <div class="md-layout-item md-size-33 md-medium-size-100">
                  <md-field>
                    <label>Monto</label>
                    <md-input
                      v-model="refArrenMonto"
                      :disabled="this.modo == 5 ? true : false"
                    ></md-input>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-33 md-medium-size-100">
                  <md-field>
                    <label>Motivo de Cambio</label>
                    <md-textarea 
                      v-model="refArrenMotivoCambio"
                      :disabled="this.modo == 5 ? true : false"
                    ></md-textarea >
                  </md-field>
                </div>
              </div>
            </div>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="right">
          <md-button
            class="md-primary"
            @click.native="postNow()"
            >GUARDAR </md-button>
        </md-card-actions>
      </md-card>
    </div>
  </div>
 </template>
<script> 
import store from "@/Store/index";
import servRA from "@/Servicios/Operacion/referenciasArrendamiento.js";
import mensaje from "@/Servicios/ManjeadorErrores/Mensajes";

export default {
  components: {
  },
  computed: {
    usuario() {
      return store.state.usuario;
    },
    general() {
      return store.state.General;
    },
    modo() {
      return store.state.Modo;
    },
  },
  data() {
  return {
    refArrendamientoId: 0, // Initialize with an appropriate default value
    refArrenNombres: "",
    refArrenApePaterno: "",
    refArrenApeMaterno: "",
    refArrenTelefono: "",
    refArrenDomicilio: "",
    refArrenMonto: 0.0,
    refArrenMotivoCambio: "",
    fisicaMoralId: 0,
  };
},
methods: {
  postNow() {
    var data = {
      refArrendamientoId: this.refArrendamientoId,
      refArrenNombres: this.refArrenNombres,
      refArrenApePaterno: this.refArrenApePaterno,
      refArrenApeMaterno: this.refArrenApeMaterno,
      refArrenTelefono: this.refArrenTelefono,
      refArrenDomicilio: this.refArrenDomicilio,
      refArrenMonto: this.refArrenMonto,
      refArrenMotivoCambio: this.refArrenMotivoCambio,
      fisicaMoralId: this.general.FisicaMoralId,
    };

    if (this.refArrendamientoId > 0) {
        // Operación de edición
        servRA
            .put(this.refArrendamientoId, data)
            .then((r) => {
                this.ProcesamosResponse(r.data);
            })
            .catch(function (e) {
                mensaje.Error("ReferenciaArrendamiento-crud-put", e);
            });
    } else {
      // Operación de inserción
      servRA
            .post(data)
            .then((r) => {
                this.ProcesamosResponse(r.data);
            })
            .catch(function (e) {
                mensaje.Error("ReferenciaArrendamiento-crud-post", e);
            });
    }
  },
    getDatos() {
      servRA
        .getId(this.fisicaMoralId)
        .then((r) => this.Asignamos(r.data))
        .catch((e) => console.log("Error - fisicaMoral", e));
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    Asignamos(r) {
      this.refArrenNombres = r.refArrenNombres;
      this.refArrenApePaterno = r.refArrenApePaterno;
      this.refArrenApeMaterno = r.refArrenApeMaterno;
      this.refArrenTelefono = r.refArrenTelefono;
      this.refArrenDomicilio = r.refArrenDomicilio;
      this.refArrenMonto = r.refArrenMonto;
      this.refArrenMotivoCambio = r.refArrenMotivoCambio;    
    },
    notifyVue(m) {
      this.$notify({
        message: m.mensaje,
        timeout: 30000,
        icon: "add_alert",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: m.tipo,
      });
    },
    ProcesamosResponse(data) {
      //console.debug("datafiadorf",data)
      data.forEach((e) => {
        let m = {
          mensaje: "",
          tipo: "",
        };
        switch (e.tipo) {
          case 1: //Error
            m.mensaje = e.mensaje;
            m.tipo = "danger";
            
            this.notifyVue(m);
            break;
          case 2: //Atributos por completar
            // se agrega a la clase el error "md-valid" ó "md-error"
            const m = {};
            const valor = this[e.mensaje];
            m.mensaje = e.mensaje;
            m.tipo = "danger";
            
            this.notifyVue(m);
            break;
          case 3: //success
            this.getDatos();
            
            m.mensaje = e.mensaje;
            m.tipo = "success";
            this.notifyVue(m);
            break;
          case 4: //Information
            m.mensaje = e.mensaje;
            m.tipo = "info";
            this.notifyVue(m);
            break;
        }
      });
    },
  },
  
  created() {
    console.log("Entro Referecias Arrendamiento");
    this.fisicaMoralId = this.general.FisicaMoralId;
    this.getDatos();
},
};
</script>
<style lang="scss" scoped>
</style>
