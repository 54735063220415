<template>
  <div class="md-layout">
    <md-card>
      <md-card-header class="md-card-header-text md-card-header-primary">
        <div class="card-text">
          <h4 class="title">Encabezado</h4>
        </div>
        <div class="md-layout-item md-size-100 md-medium-size-100">
          <div class="md-layout">
            <div class="md-layout-item md-size-33 md-medium-size-100">
              <md-field>
                <label>PólizaId</label>
                <md-input v-model="general.PolizaId" disabled></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-33 md-medium-size-100">
              <md-field>
                <label>Solicitud Id</label>
                <md-input v-model="general.SolicitudId" disabled></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-33 md-medium-size-100">
              <md-field>
                <label>Solución Id</label>
                <md-input v-model="general.SolucionId" disabled></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-33 md-medium-size-100">
              <md-field>
                <label>FisicaMoral Id</label>
                <md-input v-model="general.FisicaMoralId" disabled></md-input>
              </md-field>
            </div>
          </div>
        </div>
      </md-card-header>
    </md-card>
    <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
      <collapse :collapse="[
        'Solicitud',
        'Persona Fisica',
        'Arrendatarios',
        'Referencia de Arrendamiento',
        'Referencias Personales',
        'Personas que ocuparán el inmueble',
        'Documentos',
        'FiadorF',
        'FiadorM',
        'Investigación PJ',
        'Estatus',
        'Generar Contrato',
      ]" icon="keyboard_arrow_down" color-collapse="success">
        <!-- <h4 class="title" slot="header-title">
          Opciones de Operación - <small class="description">Pantalla</small>
        </h4> -->

        <!-- here you can add your content for tab-content -->
        <template slot="md-collapse-pane-1">
          <editSolicitud />
        </template>
        <template slot="md-collapse-pane-2">
          <arrendatario />
        </template>
        <template slot="md-collapse-pane-3">
          <arrendatarios />
        </template>
        <template slot="md-collapse-pane-4">
          <referenciaArrendamiento />
        </template>
        <template slot="md-collapse-pane-5">
          <referencias />
        </template>
        <template slot="md-collapse-pane-6">
          <personasOcuparanInm />
        </template>
        <template slot="md-collapse-pane-7">
          <documentos />
        </template>
        <template slot="md-collapse-pane-8">
          <FiadorF />
        </template>
        <template slot="md-collapse-pane-9">
          <FiadorMoral />
        </template>
        <template slot="md-collapse-pane-10">
          <investigaciones />
        </template>
        <template slot="md-collapse-pane-11">
          <estatus />
        </template>
        <template slot="md-collapse-pane-12" v-if="usuario.areaid == 1 | 12">
          <GenContrato />
        </template>
      </collapse>
    </div>
  </div>
</template>
<script>


import { Collapse } from "@/components";
import editSolicitud from "../Solicitudes/CreateUpdate.vue";
import arrendatario from "./Arrendatario.vue";
import arrendatarios from "./arrendatarios.vue";
import referenciaArrendamiento from "./referenciaArrendamiento.vue";
import personasOcuparanInm from "./personasOcupanInmueble.vue";
import documentos from "./documentos.vue";
import estatus from "./estatus.vue";
import investigaciones from "./investigacion.vue";
import referencias from "./referenciasPersonales.vue";
import store from "@/Store/index";
import FiadorF from "./personasFisicasFiador.vue";
import FiadorMoral from "./FiadorMoral.vue";
import GenContrato from "./GenContratos.vue";

export default {
  computed: {
    general() {
      return store.state.General;
    },
    usuario() {
      return store.state.usuario;
    },
  },
  data() {
  },
  components: {
    Collapse,
    editSolicitud,
    arrendatario,
    arrendatarios,
    referenciaArrendamiento,
    personasOcuparanInm,
    documentos,
    estatus,
    //generarContratos,
    investigaciones,
    referencias,
    FiadorF,
    FiadorMoral,
    GenContrato,
},
created() {
  console.log("Operacion",this.usuario);
},

};
</script>
<style lang="scss" scoped>
.conteiner-tab {
  width: 100%;
}
</style>