import { http } from "@/http-commons";

class dashboard {
  GetCantidadEstatusxUsuario(fechainicio, fechafin,UsuarioId) {
    //console.log(usuario);
    return http.get(`/Dashboard/GetCantidadEstatusxUsuario/${fechainicio}/${fechafin}/${UsuarioId}`);
  }
  VentaGlobalEjecutivo(fechainicio, fechafin) {
    //console.log(usuario);
    return http.get(`/Dashboard/VentaGlobalEjecutivo/${fechainicio}/${fechafin}`);
  }
  VentaGlobalRepresentacion(fechainicio, fechafin) {
    //console.log(usuario);
    return http.get(`/Dashboard/VentaGlobalRepresentacion/${fechainicio}/${fechafin}`);
  }
}

export default new dashboard();