<template>
  <div class="md-layout">
    <div class="md-layout-item md-small-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>contacts</md-icon>
          </div>
          <h4 class="title">Cargar Excel de pólizas cobradas</h4>
        </md-card-header>

        <md-card-content>
          <form class="form-horizontal" action="" method="post">
            <div class="md-layout">
              <label class="md-layout-item md-size-15 md-form-label">
                Nombre de la columna de Póliza
              </label>
              <div class="md-layout-item">
                <md-field>
                  <md-input v-model="polizaid" type="text"></md-input>
                </md-field>
              </div>
            </div>
            <div class="md-layout">
              <label class="md-layout-item md-size-15 md-form-label">
                Nombre de la columna de Estatus
              </label>
              <div class="md-layout-item">
                <md-field>
                  <md-input v-model="estatus" type="text"></md-input>
                </md-field>
              </div>
            </div>
            <div class="md-layout">
              <label class="md-layout-item md-size-15 md-form-label">
                Cantidad de hojas a procesar
              </label>
              <div class="md-layout-item">
                <md-field>
                  <md-input v-model="cantidadHojas" type="text"></md-input>
                </md-field>
              </div>
            </div>
            <div class="md-layout">
              <label class="md-layout-item md-size-15 md-form-label">
                Descrición de estatus Cobrada
              </label>
              <div class="md-layout-item">
                <md-field>
                  <md-input v-model="estatusCobrado" type="text"></md-input>
                </md-field>
              </div>
            </div>
            <div class="md-layout">
              <label class="md-layout-item md-size-15 md-form-label">
                Descrición de estatus Cobrada
              </label>
              <div class="md-layout-item">
                <md-field>
                  <md-checkbox v-model="Activo">Activo</md-checkbox>
                </md-field>
              </div>
            </div>
            <div class="md-layout">
              <label class="md-layout-item md-size-15 md-form-label">
                Descripcion de estatus Cancelada
              </label>
              <div class="md-layout-item">
                <md-field>
                  <md-input v-model="estatusCancelado" type="text"></md-input>
                </md-field>
              </div>
            </div>
            <div class="md-layout">
              <label class="md-layout-item md-size-15 md-form-label">
                Seleccionar cuenta
              </label>
              <div class="md-layout-item">
                <md-field>
                  <label for="cuentaId">(Seleccionar cuenta)</label>
                  <md-select v-model="cuentaId">
                    <md-option v-for="c in tableData" :key="c.cuentaId" :value="c.cuentaId">
                      {{ c.nombre }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>
            </div>
            <div class="md-layout">
              <label class="md-layout-item md-size-25 md-form-label">
                Subir Excel
              </label>
              <div class="md-layout-item">
                <md-field>
                  <md-input id="archivoExcel" type="file"></md-input>
                </md-field>
              </div>
            </div>
          </form>
        </md-card-content>

        <md-card-actions md-alignment="right">
          <md-button class="md-success" @click="subirExcel()" @disabled="isDisable">{{ btnName }}</md-button>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>

<script>
import readXlsFile from "read-excel-file";
import servPoliza from "@/Servicios/Polizas/polizas";
import servContabilidad from "@/Servicios/Contabilidad/contabilidad";
import excel from "@/Utilerias/GenerarExcel";
import store from "@/Store/index";
import servcuentas from "@/Servicios/Movimientos/cuentas";
export default {
  components: {},
  computed: {
    usuario() {
      return store.state.usuario;
    }
  },
  data() {
    return {
      items: [],
      polizaid: "Póliza",
      estatus: "Fecha Cobro",
      cantidadHojas: 7,
      estatusCobrado: "PAGADA",
      estatusCancelado: "CANCELADO",
      cuentaId: 0,
      tableData: [],
      objList: [],
      isDisable: false,
      btnName: "Procesar archivo",
      Activo: false
    };
  },
  methods: {
    getCuentas() {
      servcuentas
        .all()
        .then((r) => {
          this.tableData = r.data;
        })
        .catch((e) => {
          console.log("Error en movientos en getCuentas all", e);
        });
    },
    //Crear una funcion que me ayude a leer el excel
    subirExcel() {
      this.btnName = "Procesando...";
      this.isDisable = true;
      this.ProcesamosExcel().then(o => {
        o = o.filter(x => x);
        o = o.filter(x => typeof x.polizaId === "number");
        let mensaje = {
          mensaje: "Se ESTAN procesando " + o.length + " polizas",
          tipo: "success"
        };
        this.notifyVue(mensaje);
        console.log("Termino de procesar el excel");
        console.log("objList", o);
        //this.Procesamos(o);
      });
    },
    async ProcesamosExcel() {
      const input = document.getElementById("archivoExcel");
      let objto = [];
      for (var i = 0; i < this.cantidadHojas; i++) {
        await readXlsFile(input.files[0], { sheet: i + 1 }).then((rows) => {
          let posicionPolizaId = 0;
          let posicionEstatus = 0;
          let isPoliza = false;
          let isEstatus = false;
          let isBandera = false;
          rows.map((r) => {

            if (isPoliza === false) {
              posicionPolizaId = r.indexOf(this.polizaid);
              if (posicionPolizaId !== -1) {
                isPoliza = true;
              }
            }
            if (isEstatus === false) {
              if (this.Activo === true) {
                posicionEstatus = this.estatusCobrado;
              } else {
                posicionEstatus = r.indexOf(this.estatus);
              }
              if (posicionEstatus !== -1) {
                isEstatus = true;
              }
            }
            if (isPoliza === true && isEstatus === true
              && r[posicionPolizaId] !== undefined
              && r[posicionEstatus] !== undefined
              && r[posicionPolizaId] !== null
              && r[posicionEstatus] !== null
              && isBandera === true) {
              let obj = {
                polizaId: r[posicionPolizaId],
                estatus: r[posicionEstatus],
              }
              objto.push(obj);
              // return {
              //   polizaId: r[posicionPolizaId],
              //   estatus: r[posicionEstatus],
              // }
            }
            if (isBandera === false) {
              isBandera = true;
            }
          });
        });
      }
      return objto;
    },
    Procesamos(objList) {
      if (objList.length > 0) {
        servPoliza.CargaMasivaExcel(objList, this.estatusCobrado, this.estatusCancelado, this.usuario.usuariosId, this.cuentaId)
          .then((result) => {
            this.btnName = "Procesar archivo";
            this.isDisable = false;
            this.ProcesamosMensaje(result.data)
          }).catch((e) => {
            console.log("Error en movientos en subirExcel", e);
            console.log("Error en movientos en subirExcel data", e.data);
            this.ProcesamosMensaje(e.data)
            this.btnName = "Procesar archivo";
            this.isDisable = false;
          });
      } else {
        let m = {
          mensaje: "",
          tipo: "",
        };
        this.isActivo = false;
        m.mensaje = "No tiene polizas para procesar";
        m.tipo = "danger";
        this.notifyVue(m);
        this.btnName = "Procesar archivo";
        this.isDisable = false;
      }
    },
    ProcesamosMensaje(result) {
      if (result != undefined) {
        result.forEach((e) => {
          let m = {
            mensaje: "",
            tipo: "",
          };
          switch (e.tipo) {
            case 2: //Atributos por completar
              // se agrega a la clase el error "md-valid" ó "md-error"
              this[e.mensaje] = "md-error";
              break;
            case 1: //Error
              this.isActivo = false;
              m.mensaje = e.mensaje;
              m.tipo = "danger";
              this.notifyVue(m);
              break;
            case 3: //success
              m.mensaje = e.mensaje;
              m.tipo = "success";
              this.notifyVue(m);
              break;
            case 4: //Information
              m.mensaje = e.mensaje;
              m.tipo = "info";
              this.notifyVue(m);
              break;
            case 5: //Parametro
              let id = parseInt(e.mensaje);
              this.GenerarRecibo(id);
              break;
          }
        });
      }
    },

    GenerarRecibo(id) {
      servContabilidad
        .get(id)
        .then((r) => {
          excel.GeneraExcelMovimientos(r.data);
        })
        .catch((e) => {
          console.log("Error en generar el recibo", e);
        });
    },
    notifyVue(Mensajes) {
      this.$notify({
        message: Mensajes.mensaje,
        timeout: 30000,
        icon: "add_alert",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: Mensajes.tipo,
      });
    },
    limpiar() {
      this.items = [];
      this.polizaid = null;
      this.estatus = null;
      this.estatusDesc = null;
      this.estatusDescCancelada = null;
      this.cantidadHojas = 0;
    }
  },
  created() {
    this.getCuentas();
  },
};
</script>

<style lang="scss" scoped>
.md-card .md-card-actions {
  border: none;
}

.md-inline-checkboxes {
  display: inline-flex;

  .md-checkbox {
    margin-top: 15px !important;
  }
}

.md-checkbox,
.md-radio {
  margin-top: 15px;
  margin-bottom: 0.5rem;
}

.md-checkbox,
.md-radio {
  display: flex;
}

.md-radio .md-radio-container {
  margin-left: 5px;
  position: relative;
  left: -3px;
}

.md-form-label+.md-layout-item .md-checkbox:not(:first-child),
.md-form-label+.md-layout-item+.md-layout-item .md-radio:not(:first-child),
.md-form-label+.md-layout-item .md-radio {
  margin-top: 0;
}

.form-control-static {
  margin-top: 6px;
}
</style>