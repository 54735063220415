<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-text md-card-header-primary">
          <div class="card-text">
            <h4 class="title">Bienvenido a Póliza</h4>
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-size-50">
              <md-field>
                <label> Fecha Inicio</label>
                <md-input v-model="fechainicio" type="date"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-50">
              <md-field>
                <label>Fecha Fin</label>
                <md-input v-model="fechafin" type="date"></md-input>
              </md-field>
            </div>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="right">
          <md-button class="md-primary" @click.native="Priorizamos()"
            >Filtrar</md-button
          >
        </md-card-actions>
      </md-card>
    </div>
    <cards
      :Cantidad="this.cantidad.solicitudes"
      txtHeader="Nueva"
      txtFooter="Soluciones Nuevas"
    ></cards>
    <cards
      :Cantidad.sync="this.cantidad.polizas"
      txtHeader="Gestión Telefónica"
      txtFooter= "Gestión Telefónica"
    ></cards>
    <cards
      :Cantidad.sync="this.cantidad.soluciones"
      txtHeader="Requerimiento Pago 1"
      txtFooter="Requerimiento Pago 1"
    ></cards>
    <cards
      :Cantidad.sync="this.cantidad.regalias"
      txtHeader= "Requerimiento Pago 2"
      txtFooter= "Requerimiento Pago 2"
      :isMoney="true"
    ></cards>
    <cards
      :Cantidad.sync="this.cantidad.regaliasPagadasTotal"
      txtHeader= "Aviso de Demanda"
      txtFooter="Aviso de demanda"
      :isMoney="true"
    ></cards>
    <cards
      :Cantidad.sync="this.cantidad.solicitudesCanceladas"
      txtHeader="Recuperación de inmueble extra Judicial"
      txtFooter="Recuperación de inmueble extra Judicial"
    ></cards>
    <cards
      :Cantidad.sync="this.cantidad.solicitudesCanceladas"
      txtHeader="Demanda"
      txtFooter="Demanda"
    ></cards>
    <cards
      :Cantidad.sync="this.cantidad.ventaTotalPasado"
      txtHeader="Contestación de Demanda"
      txtFooter="Contestación de Demanda"
      :isMoney="true"
    ></cards>
    <cards
      :Cantidad.sync="this.cantidad.regaliasPasado"
      txtHeader="Ofrecimiento y Desahogo de Pruebas"
      txtFooter="Ofrecimiento y Desahogo de Pruebas"
      :isMoney="true"
    ></cards>
    <cards
      :Cantidad.sync="this.cantidad.regaliasPagadasTotalPasado"
      txtHeader="Alegatos"
      txtFooter="Alegatos"
      :isMoney="true"
    ></cards>
    <cards
      :Cantidad.sync="this.cantidad.deudaRegaliasPagadasTotalPasado"
      txtHeader="Sentencia"
      txtFooter="Sentencia"
      :isMoney="true"
    ></cards>
    <cards
      :Cantidad.sync="this.cantidad.deudaRegaliasPagadasTotalPasado"
      txtHeader="Apelación"
      txtFooter="Apelación"
      :isMoney="true"
    ></cards>
    <cards
      :Cantidad.sync="this.cantidad.deudaRegaliasPagadasTotalPasado"
      txtHeader="Amparo"
      txtFooter="Amparo"
      :isMoney="true"
    ></cards>
    <cards
      :Cantidad.sync="this.cantidad.deudaRegaliasPagadasTotalPasado"
      txtHeader="Queja"
      txtFooter="Queja"
      :isMoney="true"
    ></cards>
    <cards
      :Cantidad.sync="this.cantidad.deudaRegaliasPagadasTotalPasado"
      txtHeader="Revisión"
      txtFooter="Revisión"
      :isMoney="true"
    ></cards>
    <cards
      :Cantidad.sync="this.cantidad.deudaRegaliasPagadasTotalPasado"
      txtHeader="Recuperación de inmueble por ejecución de sentencia"
      txtFooter="Recuperación de inmueble por ejecución de sentencia"
      :isMoney="true"
    ></cards>

    <cards
      :Cantidad.sync="this.cantidad.deudaRegaliasPagadasTotalPasado"
      txtHeader="Concluida"
      txtFooter="Concluida"
      :isMoney="true"
    ></cards>
    <!-- <graficas :labels="this.labels" :series="this.series" Title="Solicitudes por semana"></graficas> -->
    <div class="md-layout-item md-size-50">
      <div class="content">
        <div class="md-layout">
          <div
            class="
              md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100
            "
          >
          </div>
        </div>
      </div>
    </div>
    <div class="md-layout-item md-size-50">
      <div class="content">
        <div class="md-layout">
          <div
            class="
              md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100
            "
          >
            <md-card>
              <md-card-header class="md-card-header-icon md-card-header-primary">
                <div class="card-icon">
                  <md-icon>assignment</md-icon>
                </div>
                <h4 class="title">Ventas por Representación</h4>
              </md-card-header>
              <md-card-content>
                <md-table v-model="dataTableProcesoSoluciones" table-header-color="red">
                  <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <md-table-cell md-label="Ejecutiva">{{
                      item.nombre
                    }}</md-table-cell>
                    <md-table-cell md-label="Cantidad Pólizas">{{
                      item.cantidadPoliza
                    }}</md-table-cell>
                    <!-- <md-table-cell md-label="Acumulado">{{
                      item.costoVenta
                    }}</md-table-cell> -->
                  </md-table-row>
                </md-table>
              </md-card-content>
            </md-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cards from "./cards.vue";
import graficas from "./graficas.vue";
import servdash from "../../Servicios/Dashboard/dashboard";
import mensaje from "../../Servicios/ManjeadorErrores/Mensajes";
import store from "../../Store/index";

export default {
  computed: {
    // cantidad(){
    //   return store.state.cantidad;
    // }
   usuariosId() {
      return store.state.usuario.usuariosId;
    },
  },
  components: {
    // GlobalSalesCard,
    // GlobalSalesTable,
    cards,
    // graficas,
  },

  data() {
    return {
      fechainicio: "",
      fechafin: "",
      dataTableEjecutivo: [],
      dataTableRepresentacion: [],
      cantidad: {},
      labels: [
        "Ja",
        "Fe",
        "Ma",
        "Ap",
        "Mai",
        "Ju",
        "Jul",
        "Au",
        "Se",
        "Oc",
        "No",
        "De",
      ],
      series: [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895],
    };
  },
  methods: {
    async getadminCantidad() {
      console.log("entro");
      servdash
        .GetCantidadEstatusxUsuario(
          this.fechainicio,
          this.fechafin,
          this.usuariosId
        )
        .then((a) => {
          console.log("Cantidad",a.data);
          this.cantidad = a.data;
        })
        .catch((e) => {  
          mensaje.Error("Dashboard-admin-GetCantidadEstatusxUsuario", e);
        });
    },
    async getVentaGlobalEjecutivo() {
      servdash
        .VentaGlobalEjecutivo(this.fechainicio, this.fechafin)
        .then((a) => {
          console.log("dataTableEjecutivo", a.data);
          this.dataTableEjecutivo = a.data;
        })
        .catch((e) => {
          mensaje.Error("Dashboard-admin-VentaGlobalEjecutivo", e);
        });
    },
    async getVentaGlobalRepresentacion() {
      servdash
        .VentaGlobalRepresentacion(this.fechainicio, this.fechafin)
        .then((a) => {
          console.log("dataTableRepresentacion",a.data);
          this.dataTableRepresentacion = a.data;
        })
        .catch((e) => {
          mensaje.Error("Dashboard-admin-VentaGlobalRepresentacion", e);
        });
    },
    SetFechas() {
      var date = new Date(),
        y = date.getFullYear(),
        m = date.getMonth();
      var firstDay = new Date(y, m, 1);
      var lastDay = new Date(y, m + 1, 0);
      this.fechainicio = firstDay.toISOString();
      this.fechafin = lastDay.toISOString();
    },
    Priorizamos() {
      this.getadminCantidad();
      this.getVentaGlobalEjecutivo();
      this.getVentaGlobalRepresentacion();
    },
  },
  mounted() {},
  created() {
    this.SetFechas();
    this.getadminCantidad();
    this.getVentaGlobalEjecutivo();
    this.getVentaGlobalRepresentacion();
  },
  watch: {},
};
</script>
