<template>
  <div class="md-layout text-center">
    <div class="
        md-layout-item
        md-size-33
        md-medium-size-100
        md-small-size-70
        md-xsmall-size-100
      ">
      <login-card header-color="primary">
        <h4 slot="title" class="title">Iniciar sesión</h4>
        <md-field class="md-form-group" slot="inputs">
          <md-icon>email</md-icon>
          <label>Email...</label>
          <md-input v-model="email" type="email"></md-input>
        </md-field>
        <md-field class="md-form-group" slot="inputs">
          <md-icon>lock_outline</md-icon>
          <label>Contraseña...</label>
          <md-input v-model="password" type="password" v-on:keyup.enter="iniciar()"> 
          </md-input>
        </md-field>
        <div v-if="this.visible == true" style="margin-top: 10px;" slot="inputs">
          <span class="loader"></span>
        </div>
        <md-button v-if="this.visible == false" slot="footer" class="md-simple md-primary md-lg" @click="iniciar()">
          ¡Vamos!
        </md-button>
        
      </login-card>
    </div>
  </div>
</template>
<script>
import { LoginCard } from "@/components";
import servLogin from "@/Servicios/Login/login";
import mensaje from "@/Servicios/ManjeadorErrores/Mensajes";
import store from "@/Store/index";
import servToken from "@/Servicios/Login/token";

export default {
  name: "Login",
  components: {
    LoginCard,
  },
  data() {
    return {
      firstname: null,
      email: null,
      password: null,
      signedIn: true,
      myI: "",
      visible : false
    };
  },
  methods: {
    async iniciar() {
      this.visible = true;
      let isBandera = false;
      if (this.email == null || this.email == undefined) {
        let mensajes = {
          tipo: "danger",
          mensaje: "El E-mail, no puede estar vacío",
          accion: false,
        };
        this.notifyVue(mensajes);
        isBandera = true;
        this.visible = false;
      }
      if (this.password == null || this.password == undefined) {
        let mensajes = {
          tipo: "danger",
          mensaje: "La contraseña, no puede estar vacío",
          accion: false,
        };
        this.notifyVue(mensajes);
        isBandera = true;
        this.visible = false;
      }
      if (isBandera == false) {
        //console.log('entro');
        servLogin.getIp().then((r) => {
          this.myI = r.ip;
          let usuario = {
            usurioEmail: this.email,
            contraEncrypt: btoa(this.password),
            myi: this.myI,
          };
          servLogin
            .login(usuario)
            .then((r) => {
              let method = r.config.method;
              let status = r.status;
              let mens = {};
              mens = mensaje.login(method, status);
              if (mens.accion == true) {
                let usuario = JSON.stringify(r.data)
                localStorage.setItem('pjUser', usuario);
                store.state.usuario = r.data;
                let dashboard = r.data.dashboard;
                servToken.getToken().then((r) => {
                  let token = r.data.token;
                  localStorage.setItem("pjToken", token);
                  this.$router.push(dashboard);
                });

              } else {
                this.password = null;
                this.notifyVue(mens);
              }
            })
            .catch((e) => {
              mensaje.Error("Login", e);
              alert("E-mail y/o Contraseña inrrectos");
              this.password = null;
              this.visible = false;
            });
        });
      }
    },
    notifyVue(mensajes) {
      alert(mensajes.mensaje);
    },
  },
  mounted() {
  }
};
</script>

<style>
.loader {
  width: 48px;
  height: 48px;
  border: 5px dotted #b60606;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 2.9s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
</style>
