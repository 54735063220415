<template>
  <ul class="nav-mobile-menu">
    <li>
      <a href="#/buscar" class="dropdown-toggle" data-toggle="dropdown">
        <i class="material-icons" >search</i></a>
    </li>
    <li>
      <a :href="'#/' + this.usuario.dashboard" class="dropdown-toggle" data-toggle="dropdown">
        <i class="material-icons" >dashboard</i></a>
    </li>
    <li>
      <a href="#/phone" class="dropdown-toggle" data-toggle="dropdown">
        <i class="material-icons" >phone</i></a>
    </li>
  </ul>
</template>
<script>
import store from "@/Store/index";
export default {
  computed: {
    usuario() {
      return store.state.usuario;
    },
  },
  data() {
    return {      
    };
  }
};
</script>
<style>
.nav-mobile-menu {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.classcolor{
  color: #000;
}
</style>
