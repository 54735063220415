import { http } from "@/http-commons";

class ClienteUsuario {
  all() {
    return http.get('/ClienteUsuario');
  }
  get(ClienteUsuario1) {
    return http.get(`/ClienteUsuario/${ClienteUsuario1}`);
  }
  post(ClienteUsuario) {
    return http.post('/ClienteUsuario',ClienteUsuario);
  }
  put(ClienteUsuario1, ClienteUsuario) {
    return http.put(`/ClienteUsuario/${ClienteUsuario1}`,ClienteUsuario);
  }
  delete(ClienteUsuario1) {
    return http.delete(`/ClienteUsuario/${ClienteUsuario1}`);
  }
  
}

export default new ClienteUsuario();