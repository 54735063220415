import { http } from "@/http-commons";

class agenda {
  all() {
    return http.get('/agenda');
  }
  get(id) {
    return http.get(`/agenda/${id}`);
  }
  post(agenda) {
    return http.post('/agenda', area);
  }
  put(id, agenda) {
    return http.put(`/agenda/${id}`, area);
  }
  delete(id) {
    return http.delete(`/agenda/${id}`);
  }
}

export default new agenda();