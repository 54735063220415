<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-blue">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">USUARIOS POR EVENTO</h4>
        </md-card-header>
        <md-card-content>
          <md-table
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover"
          >
            <md-table-toolbar>
              <md-field>
                <label for="pages">Por página</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>

              <md-field>
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style="width: 200px"
                  placeholder="Buscar registro"
                  v-model="searchQuery"
                >
                </md-input>
              </md-field>
              <md-field style="width: 500px">
                <label for="FiltroAreaId"
                  >(Seleccionar evento para filtrar)</label
                >
                <md-select v-model="FiltroEventoId" >
                  <md-option
                    v-for="e in Eventos"
                    :key="e.eventosId"
                    :value="e.eventosId"
                  >
                    {{ e.descripcion}}
                  </md-option>
                </md-select>
              </md-field>
              <md-button class="md-primary" @click="getDatos()"
                >Filtrar</md-button
              >
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Id" md-sort-by="eventoUsarioId">{{
                item.eventoUsarioId
              }}</md-table-cell>
              <md-table-cell md-label="Correo" md-sort-by="correo">{{
                item.correo
              }}</md-table-cell>
              <md-table-cell md-label="Nombre" md-sort-by="nombre">{{
                item.nombre
              }}</md-table-cell>
              <md-table-cell md-label="Celular" md-sort-by="celular">{{
                item.celular }}
              </md-table-cell>
              <md-table-cell md-label="Descripción" md-sort-by="descripcionEvento">{{
                item.descripcionEvento
              }}</md-table-cell>
              <md-table-cell md-label="Representante" md-sort-by="representante">{{
                item.representante
              }}</md-table-cell>
              <md-table-cell md-label="Estado" md-sort-by="dashboard">{{
                item.estadoNombre
              }}</md-table-cell>
            </md-table-row>
          </md-table>
          <div class="footer-table md-table">
            <table>
              <caption></caption>
              <tfoot>
                <tr>
                  <th
                    v-for="item in footerTable"
                    :key="item"
                    class="md-table-head"
                  >
                    <div class="md-table-head-container md-ripple md-disabled">
                      <div class="md-table-head-label">
                        {{ item }}
                      </div>
                    </div>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          >
          </pagination>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>

<script>
import { Pagination, Modal } from "@/components";
import Fuse from "fuse.js";
import Swal from "sweetalert2";
import mensaje from "../../Servicios/ManjeadorErrores/Mensajes";
import serveu from "../../Servicios/Representacion/EventosUsuario";
import servEvento from "../../Servicios/Representacion/eventos";

export default {
  components: {
    Pagination,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      currentSort: "eventoUsarioId",
      currentSortOrder: "asc",
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      footerTable: [
        "Id",
        "Correo ",
        "Nombre",
        "Celular",
        "Descripcion Evento",
        "Estado Nombre",
        "Representante",
        "Estado",
      ],
      RepresentanteId: 0,
      searchQuery: "",
      propsToSearch: ["eventoUsarioId", "correo", "nombre","celular",
      "descripcionEvento","estadoNombre","representante","estadoId"],
      tableData: [],
      searchedData: [],
      fuseSearch: "",
      classicModal: false,
      eventoUsarioId: 0,
      correo: "",
      nombre: "",
      celular:"",
      descripcionEvento:"",
      estadoNombre:"",
      representante:"",
      estadoId:"",

      Eventos: [],
      FiltroEventoId: 1
    };
  },

  methods: {
    getEvent(){
      this.tableData = [];
      servEvento.all().then(r => {
        this.$forceUpdate();
        this.Eventos = r.data;
      })
      .catch(e => console.log("Error al cargar eventos",e));
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },

    getDatos() {

        serveu
        .GetUsuariosPorEvento(this.FiltroEventoId)
        .then((a) => {
          this.tableData = a.data;
          // Fuse search initialization.
          this.fuseSearch = new Fuse(this.tableData, {
            keys: ["eventoUsarioId", "correo", "nombre","celular",
      "descripcionEvento","estadoNombre","representante","estadoId"],
            threshold: 0.3,
          });
        })
        .catch((e) => {
          mensaje.Error("usuario-area-all", e);
        });

      
    },
    Limpiar() {
      Swal.fire({
        title: "!Eliminar!",
        text: `¿Deseas limpiar todo el log ?`,
        type: "success",
        showCancelButton: true,
        confirmButtonClass: "md-button md-success",
        cancelButtonClass: "md-button md-danger",
        confirmButtonText: "Si, eliminar!",
        cancelButtonText: "Cancelar",
        buttonsStyling: false,
      })
        .then((r) => {
          if (r.value) {
            serveu
              .deleteall()
              .then((response) => {
                Swal.fire({
                  title: "Eliminado!",
                  text: `Se ha limpiado el log`,
                  type: "success",
                  confirmButtonClass: "md-button md-success",
                  cancelButtonText: "Cancelar",
                  buttonsStyling: false,
                });
                this.tableData = [];
              })
              .catch(function (e) {
                mensaje.Error("usuario-crud-sercategorias-delete", e);
              });
          }
          if (r.dismiss == "cancel") {
            this.Clear();
          }
        })
        .catch(function (e) {
          mensaje.Error("usuario-crud-sercategorias-delete", e);
        });
    },
  },
  created() {
    this.getEvent();
    this.getDatos();

  
  },
  mounted() {},
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result.map(r => r.item);
    },
  },
};
</script>

<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.full-control > .md-list {
  width: 320px;
  max-width: 100%;
  height: 400px;
  display: inline-block;
  overflow: auto;
  border: 1px solid rgba(#000, 0.12);
  vertical-align: top;
}
</style>
