import { http } from "@/http-commons";

class dm {
  all(id) {
    return http.get(`/DetalleMovimiento`);
  }
  post(data) {
    return http.post(`/DetalleMovimiento`,data);
  }
  put(id,data) {
    return http.put(`/DetalleMovimiento/${id}`,data);
  }
  delete(id) {
    return http.delete(`/DetalleMovimiento/${id}`);
  }
  MoviDetalleMovimiento() {
    return http.get(`/DetalleMovimiento/MoviDetalleMovimiento`);
  }
  CargaMasiva(data) {
    return http.post(`/DetalleMovimiento/CargaMasiva`,data);
  }
}

export default new dm();