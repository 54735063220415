import { http } from "@/http-commons";

class EstructuraKeywords {
  all() {
    return http.get('/DocumentoPlantilla');
  }
  getid(id) {
    return http.get(`/DocumentoPlantilla/${id}`, {responseType : 'blob'});
  }
  post(data) {
    return http.post('/DocumentoPlantilla', data);
  }
  put(id, data) {
    return http.put(`/DocumentoPlantilla/${id}`, data);
  }
  delete(id) {
    return http.delete(`/DocumentoPlantilla/${id}`);
  }
}

export default new EstructuraKeywords();