import { http } from "@/http-commons";

class ConfCuentaRep {
  all() {
    return http.get('/ConfCuentaRep');
  }
  post(data) {
    return http.post('/ConfCuentaRep',data);
  }
  put(id,data) {
    return http.put(`/ConfCuentaRep/${id}`,data);
  }
  delete(id) {
    return http.delete(`/ConfCuentaRep/${id}`);
  }
}

export default new ConfCuentaRep();