import axios from "axios";

console.debug("Entorno:", process.env.VUE_APP_HTTP_API)
const token = localStorage.getItem('pjToken');
//V1
export const http = axios.create({
    baseURL: process.env.VUE_APP_HTTP_API,
    headers: {
        "Content-type": "application/json",
        "accept": "*/*",
        "Authorization": "Bearer " + token  //the token is a variable which holds the token
    }
});

http.interceptors.request.use((config) => {
    const token = localStorage.getItem('pjToken');
    config.headers.Authorization = `Bearer ${token}`;
    return config;
}, (error) => {
    return Promise.reject(error);
});

