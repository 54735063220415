import { http } from "@/http-commons";

class FiadorM {
    getId(id) {
        return http.get(`/FiadorM/${id}`);
    }
    delete(id) {
        return http.delete(`/FiadorM/${id}`);
    }
    post(data) {
        return http.post(`/FiadorM`, data);
    }
    put(id, data) {
        return http.put(`/FiadorM/${id}`, data)
    }
}
export default new FiadorM();