import { http } from "@/http-commons";

class Solicitudes {
    // getSolicitudes(tipoProceso, fechaInicio, fechafin, renovacion, usuario) {
    //     return http.get(`/Solicitud/${tipoProceso}/${fechaInicio}/${fechafin}/${renovacion}/${usuario}`);
    // }
    BuscaSolicitud(id, usuarioid) {
        return http.get(`/Solicitud/solicitudespecifica/${id}/${usuarioid}`);
    }
    post(data) {
        return http.post('/Solicitud',data);
    }
    getId(id) {
        return http.get(`/Solicitud/${id}`);
    }
    GetSolicitudes(data){
        return http.post('/Solicitud/Solicitudes',data);
    }
    putSolicitud(id, data){
       return http.put(`/Solicitud/${id}`, data)
    }
    GetIndicesSolicitud(id){
        return http.get(`/Solicitud/GetIndicesSolicitud/${id}`);
    }
    reciclarSolicitud(id){
        return http.get(`/Solicitud/ReciclarSolicitud/${id}`)
    }
}

export default new Solicitudes();