<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">Parametros</h4>
        </md-card-header>
        <md-card-content>
          <md-table
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover"
          >
            <md-table-toolbar>
              <md-field>
                <label for="pages">Por página</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>

              <md-field>
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style="width: 200px"
                  placeholder="Buscar registro"
                  v-model="searchQuery"
                >
                </md-input>
              </md-field>
              <md-field>
                <md-button class="md-primary" @click="ModalPersonalizado(1)"
                  >Nuevo</md-button
                >
              </md-field>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Id" md-sort-by="parametrosId">{{
                item.parametrosId
              }}</md-table-cell>
              <md-table-cell md-label="Nombre" md-sort-by="parametroNombre">{{
                item.parametroNombre
              }}</md-table-cell>
              <md-table-cell md-label="Valor" md-sort-by="parametroValor">{{
                item.parametroValor
              }}</md-table-cell>
              <md-table-cell
                md-label="Numerico"
                md-sort-by="parametroValorNumerico"
                >{{ item.parametroValorNumerico }}</md-table-cell
              >
              <md-table-cell md-label="Acciones">
                <md-button
                  class="md-just-icon md-warning md-simple"
                  @click.native="ModalPersonalizado(2, item)"
                  style="width: 50px"
                >
                  <md-icon>edit</md-icon>
                </md-button>
                <md-button
                  class="md-just-icon md-danger md-simple"
                  @click.native="ModalPersonalizado(3, item)"
                  style="width: 50px"
                >
                  <md-icon>close</md-icon>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
          <div class="footer-table md-table">
            <table>
              <caption>Descripción de los datos en el pie de la tabla</caption>
              <tfoot>
                <tr>
                  <th
                    v-for="item in footerTable"
                    :key="item"
                    class="md-table-head"
                  >
                    <div class="md-table-head-container md-ripple md-disabled">
                      <div class="md-table-head-label">
                        {{ item }}
                      </div>
                    </div>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          >
          </pagination>
        </md-card-actions>
      </md-card>
      <modal v-if="classicModal" @close="classicModalHide">
        <template slot="header">
          <h4 class="modal-title">{{ datosModal.titulo }}</h4>
          <md-button
            class="md-simple md-just-icon md-round modal-default-button"
            @click="classicModalHide"
          >
            <md-icon>clear</md-icon>
          </md-button>
        </template>

        <template slot="body">
          <md-field>
            <label>Id</label>
            <md-input v-model="parametrosId" disabled></md-input>
            <span class="md-helper-text">Id</span> </md-field
          ><md-field>
            <label>Nombre</label>
            <md-input v-model="parametroNombre"></md-input>
            <span class="md-helper-text">Nombre</span>
          </md-field>
          <md-field>
            <label>Valor</label>
            <md-input v-model="parametroValor"></md-input>
            <span class="md-helper-text">Valor</span>
          </md-field>
          <md-field>
            <label>Numerico</label>
            <md-input v-model="parametroValorNumerico"></md-input>
            <span class="md-helper-text">Numerico</span>
          </md-field>
          <div class="md-layout">
            <div class="md-layout-item md-size-50">
              <md-field>
                <label>Valor 1</label>
                <md-textarea v-model="parametroValor1"></md-textarea>
                <span class="md-helper-text">Valor 1</span>
              </md-field>
              <md-field>
                <label>Valor 2</label>
                <md-textarea v-model="parametroValor2"></md-textarea>
                <span class="md-helper-text">Valor 2</span>
              </md-field>
            </div>
            <div class="md-layout-item md-size-50">
              <md-field>
                <label>Valor 3</label>
                <md-textarea v-model="parametroValor3"></md-textarea>
                <span class="md-helper-text">Valor 3</span>
              </md-field>
              <md-field>
                <label>Valor 4</label>
                <md-textarea v-model="parametroValor4"></md-textarea>
                <span class="md-helper-text">Valor 4</span>
              </md-field>
            </div>
          </div>
        </template>
        <template slot="footer">
          <md-button class="md-danger md-simple" @click="classicModalHide"
            >Cerrar</md-button
          >
          <md-button
            class="md-primary"
            @click.native="postNow(datosModal.evento)"
            >{{ datosModal.boton }}</md-button
          >
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
import { Pagination, Modal } from "@/components";
import Fuse from "fuse.js";
import Swal from "sweetalert2";
import mensaje from "../../../Servicios/ManjeadorErrores/Mensajes";
import servpara from "../../../Servicios/Catalogos/parametros";

export default {
  components: {
    Pagination,
    Modal,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      currentSort: "areaId",
      currentSortOrder: "asc",
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      footerTable: ["Id", "Nombre", "Valor", "Numerico"],
      searchQuery: "",
      propsToSearch: [
        "parametrosId",
        "parametroNombre",
        "parametroValor",
        "parametroValorNumerico",
      ],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      datosModal: {
        titulo: "",
        boton: "",
        evento: 0,
      },
      parametrosId: 0,
      parametroNombre: null,
      parametroValor: null,
      parametroValorNumerico: null,
      parametroValor1: null,
      parametroValor2: null,
      parametroValor3: null,
      parametroValor4: null,
      classicModal: false,
    };
  },
  methods: {
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    postNow(exp) {
      var data = {
        parametrosId: this.parametrosId,
        parametroNombre: this.parametroNombre,
        parametroValor: this.parametroValor,
        parametroValorNumerico: this.parametroValorNumerico,
        parametroValor1: this.parametroValor1,
        parametroValor2: this.parametroValor2,
        parametroValor3: this.parametroValor3,
        parametroValor4: this.parametroValor4,
      };
      switch (exp) {
        case 1:
          servpara
            .post(data)
            .then((r) => {
              //hay que cerrar el model
              this.ProcesamosResponse(r.data);
              //Aqui recuperamos los datos
            })
            .catch(function (e) {
              mensaje.Error("area-crud-post", e);
            });
          break;

        case 2:
          if (this.parametrosId > 0) {
            servpara
              .put(this.parametrosId, data)
              .then((r) => {
                //hay que cerrar el model
                this.ProcesamosResponse(r.data);
                //Aqui recuperamos los datos
              })
              .catch(function (e) {
                mensaje.Error("area-crud-put", e);
              });
          }
          break;
      }
    },
    getDatos() {
      servpara
        .all()
        .then((a) => {
          this.tableData = a.data;
          //console.log(this.tableData);
          // Fuse search initialization.
          this.fuseSearch = new Fuse(this.tableData, {
            keys: ["parametrosId"],
            threshold: 0.3,
          });
        })
        .catch((e) => {
          mensaje.Error("parametro-ser-all", e);
        });
    },
    classicModalHide() {
      this.Clear();
      this.classicModal = false;
    },
    ModalPersonalizado(exp, row) {
      switch (exp) {
        case 1: //Insertar
          this.classicModal = true;
          this.datosModal.titulo = "Crear parametro";
          this.datosModal.boton = "Confirmar";
          this.datosModal.evento = 1;
          break;
        case 2: //Actualizar
          this.parametrosId = row.parametrosId;
          this.parametroNombre = row.parametroNombre;
          this.parametroValor = row.parametroValor;
          this.parametroValorNumerico = row.parametroValorNumerico;
          this.parametroValor1 = row.parametroValor1;
          this.parametroValor2 = row.parametroValor2;
          this.parametroValor3 = row.parametroValor3;
          this.parametroValor4 = row.parametroValor4;
          this.classicModal = true;
          this.datosModal.titulo = "Actualizar parametro";
          this.datosModal.boton = "Actualizar";
          this.datosModal.evento = 2;
          break;

        case 3: //Eliminar
          this.parametrosId = row.parametrosId;
          this.deleteRow();
          break;
      }
    },
    deleteRow() {
      if (this.parametrosId > 0) {
        Swal.fire({
          title: "!Eliminar!",
          text: `¿Deseas eliminar el siguiente registro: ${this.parametrosId} ?`,
          type: "success",
          showCancelButton: true,
          confirmButtonClass: "md-button md-success",
          cancelButtonClass: "md-button md-danger",
          confirmButtonText: "Si, eliminar!",
          cancelButtonText: "Cancelar",
          buttonsStyling: false,
        })
          .then((r) => {
            if (r.value) {
              servpara
                .delete(this.parametrosId)
                .then((response) => {
                  this.getDatos();
                  Swal.fire({
                    title: "Eliminado!",
                    text: `Se ha eliminado el registro ${this.this.parametrosId}`,
                    type: "success",
                    confirmButtonClass: "md-button md-success",
                    cancelButtonText: "Cancelar",
                    buttonsStyling: false,
                  });
                })
                .catch(function (e) {
                  mensaje.Error("parametro-crud-serparametro-delete", e);
                });
            }
            if (r.dismiss == "cancel") {
              this.Clear();
            }
          })
          .catch(function (e) {
            mensaje.Error("parametro-crud-serparametro-delete", e);
          });
      }
    },
    notifyVue(m) {
      this.$notify({
        message: m.mensaje,
        timeout: 30000,
        icon: "add_alert",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: m.tipo,
      });
    },
    Clear() {
      this.parametrosId = 0;
      this.parametroNombre = null;
      this.parametroValor = null;
      this.parametroValorNumerico = null;
      this.parametroValor1 = null;
      this.parametroValor2 = null;
      this.parametroValor3 = null;
      this.parametroValor4 = null;
    },
    ProcesamosResponse(data) {
      data.forEach((e) => {
        let m = {
          mensaje: "",
          tipo: "",
        };
        switch (e.tipo) {
          case 2: //Atributos por completar
            // se agrega a la clase el error "md-valid" ó "md-error"
            this[e.mensaje] = "md-error";
            break;
          case 1: //Error
            this.isActivo = false;
            m.mensaje = e.mensaje;
            m.tipo = "danger";
            this.notifyVue(m);
            break;
          case 3: //success
            this.tableData = [];
            this.getDatos();
            this.classicModalHide();
            m.mensaje = e.mensaje;
            m.tipo = "success";
            this.notifyVue(m);
            break;
          case 4: //Information
            m.mensaje = e.mensaje;
            m.tipo = "info";
            this.notifyVue(m);
            break;
        }
      });
    },
  },
  created() {
    this.getDatos();
  },
  mounted() {},
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        console.log(this.fuseSearch);
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result.map(r => r.item);
    },
  },
};
</script>

<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.full-control > .md-list {
  width: 320px;
  max-width: 100%;
  height: 400px;
  display: inline-block;
  overflow: auto;
  border: 1px solid rgba(#000, 0.12);
  vertical-align: top;
}
</style>
