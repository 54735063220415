<template>
  <div class="md-layout">
    <div :class="computedSize" v-for="stage in stages" :key="stage.id">
      <md-card>
        <md-card-header class="md-card-header-text md-card-header-primary">
          <div class="card-icon">
            <md-icon>search</md-icon>
          </div>
          <h4 class="title">Filtros</h4>
        </md-card-header>
        <md-card-content>
          <md-card class="md-primary">
            <md-card-header>
              <md-card-header-text>
                <div class="md-title">Primary color</div>
                <div class="md-subhead">Subtitle here</div>
              </md-card-header-text>

              <md-card-media>
                <img
                  src="/vuematerial/assets/examples/avatar-2.jpg"
                  alt="Avatar"
                />
              </md-card-media>
            </md-card-header>

            <md-card-actions>
              <md-button>Action</md-button>
              <md-button>Action</md-button>
            </md-card-actions>
          </md-card>
        </md-card-content>
        <md-card-actions> </md-card-actions>
      </md-card>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";

export default {
  components: {
    draggable,
  },
  computed: {
    computedSize() {
      return ` md-layout-item md-size-${
        100 / this.stages.length
      } md-small-size-100`;
    },
  },
  data() {
    return {
      stages: [
        { id: 1, name: "To Do" },
        { id: 2, name: "In Progress" },
        { id: 3, name: "Done" },
      ],
      tasks: {
        1: [{ id: 1, title: "Task 1" }],
        2: [{ id: 2, title: "Task 2" }],
        3: [{ id: 3, title: "Task 3" }],
      },
    };
  },
};
</script>
