<template>
  <div class="user">
    <div class="photo">
      <img :src="avatar" alt="avatar" />
    </div>
    <div class="user-info">
      <a
        data-toggle="collapse"
        :aria-expanded="!isClosed"
        @click.stop="toggleMenu"
        @click.capture="clicked"
      >
        <span v-if="$route.meta.rtlActive">
          {{ rtlTitle }}
          <b class="caret"></b>
        </span>
        <span v-else>
          <div class="title">{{ title }}</div>
          <b class="caret"></b>
        </span>
      </a>

      <collapse-transition>
        <div v-show="!isClosed">
          <ul class="nav">
            <slot>
              <li>
                <a href="#/user">
                  <span class="sidebar-mini">MP</span>
                  <span class="sidebar-normal">Mi perfil</span>
                </a>
              </li>
              <li>
                <a href="#/login" @click="CerrarSesion()">
                  <span class="sidebar-normal">Cerrar sesión</span>
                </a>
              </li>
            </slot>
          </ul>
        </div>
      </collapse-transition>
    </div>
  </div>
</template>
<script>
import { CollapseTransition } from "vue2-transitions";

export default {
  
  components: {
    CollapseTransition
  },
  props: {
    cardUserImage: {
      type: String,
      default: "./img/faces/marc.jpg"
    },
    title: {
      type: String,
      default: "Tania Andrew"
    },
    rtlTitle: {
      type: String,
      default: "تانيا أندرو"
    },
    avatar: {
      type: String,
      default: "./img/faces/avatar.jpg"
    }
  },
  data() {
    return {
      isClosed: true
    };
  },
  methods: {
    clicked: function(e) {
      e.preventDefault();
    },
    toggleMenu: function() {
      this.isClosed = !this.isClosed;
    },
    CerrarSesion(){
      localStorage.clear();
      this.$router.push('Login');
    },
  }
};
</script>
<style>
.collapsed {
  transition: opacity 1s;
}
.title {
  width: 100%; /* Ajusta esto a la anchura que necesites */
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* limita a 2 líneas */
  -webkit-box-orient: vertical;
}
</style>
