import { data } from "jquery";
import { http } from "@/http-commons";

class ReferenciasArrendamiento {
  all(){
    return http.get('/RefArrendamiento');
  }
  getId(id) {
    return http.get(`/RefArrendamiento/${id}`);
  }
  delete(id){
    return http.delete(`/RefArrendamiento/${id}`);
  }
  post(data){
    return http.post('/RefArrendamiento', data);
  }
  put(id,data)
  {
    return http.put(`/RefArrendamiento/${id}`, data);
  }
  
}

export default new ReferenciasArrendamiento();
