import { http } from "@/http-commons";

class TipoEstatus {
  all() {
    return http.get('/ProcesoSoluciones');
  }
  get(id) {
    return http.get(`/ProcesoSoluciones/${id}`);
  }
  post(data) {
    return http.post('/ProcesoSoluciones', data);
  }
  put(id, data) {
    return http.put(`/ProcesoSoluciones/${id}`, data);
  }
  delete(id) {
    return http.delete(`/ProcesoSoluciones/${id}`);
  }
}

export default new TipoEstatus();