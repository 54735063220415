<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">Representaciones</h4>
        </md-card-header>
        <md-card-content>
          <md-table
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover"
          >
            <md-table-toolbar>
              <md-field>
                <label for="pages">Por página</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>

              <md-field>
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style="width: 200px"
                  placeholder="Buscar registro"
                  v-model="searchQuery"
                >
                </md-input>
              </md-field>
              <md-field>
                <md-button class="md-primary" @click="ModalPersonalizado(1)"
                  >Nuevo</md-button
                >
              </md-field>
            </md-table-toolbar>
            <md-progress-bar
              class="md-warning"
              md-mode="buffer"
              v-if="isLoader == true"
            ></md-progress-bar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Id" md-sort-by="representacionId">{{
                item.representacionId
              }}</md-table-cell>
              <md-table-cell
                md-label="Nombre"
                md-sort-by="representacionNombre"
                style="width: 300px"
                >{{ item.representacionNombre }}</md-table-cell
              >
              <md-table-cell md-label="Dirección" md-sort-by="direccion">{{
                item.direccion
              }}</md-table-cell>
              <md-table-cell
                md-label="Oficina Emisora"
                md-sort-by="oficinaEmisora"
                >{{ item.oficinaEmisora }}</md-table-cell
              >
              <md-table-cell md-label="Porcentaje" md-sort-by="porcentaje">{{
                item.porcentaje
              }}</md-table-cell>
              <md-table-cell
                md-label="Porcentaje al asesor"
                md-sort-by="porcentajeAsesor"
                >{{ item.porcentajeAsesor }}</md-table-cell
              >
              <md-table-cell md-label="Acciones" style="width: 100px">
                <md-button
                  style="width: 50px"
                  class="md-just-icon md-warning md-simple"
                  @click.native="ModalPersonalizado(2, item)"
                >
                  <md-icon>edit</md-icon>
                </md-button>
                <md-button
                  style="width: 50px"
                  class="md-just-icon md-danger md-simple"
                  @click.native="ModalPersonalizado(3, item)"
                >
                  <md-icon>close</md-icon>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
          <div class="footer-table md-table">
            <table>
              <caption>Descripción de los datos en el pie de la tabla</caption>
              <tfoot>
                <tr>
                  <th
                    v-for="item in footerTable"
                    :key="item"
                    class="md-table-head"
                  >
                    <div class="md-table-head-container md-ripple md-disabled">
                      <div class="md-table-head-label">
                        {{ item }}
                      </div>
                    </div>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          >
          </pagination>
        </md-card-actions>
      </md-card>
      <modal v-if="classicModal" @close="classicModalHide">
        <template slot="header">
          <h4 class="modal-title">{{ datosModal.titulo }}</h4>
          <md-button
            class="md-simple md-just-icon md-round modal-default-button"
            @click="classicModalHide"
          >
            <md-icon>clear</md-icon>
          </md-button>
        </template>

        <template slot="body">
          <div class="md-layout">
            <div class="md-layout-item md-small-size-100">
              <md-field>
                <label>Id</label>
                <md-input v-model="representacionId" disabled></md-input>
                <span class="md-helper-text">Id</span> </md-field
              ><md-field :class="representacionNombreError">
                <label>Nombre de la representación</label>
                <md-input
                  v-model="representacionNombre"
                  type="email"
                ></md-input>
                <span class="md-helper-text">Nombre</span>
              </md-field>
              <md-field :class="direccionError">
                <label>Dirección</label>
                <md-input v-model="direccion"></md-input>
                <span class="md-helper-text">dirección</span>
              </md-field>
              <md-field :class="telefonoOficinaError">
                <label>Teléfono</label>
                <md-input v-model="telefonoOficina"></md-input>
                <span class="md-helper-text">Teléfono</span>
              </md-field>
              <md-field :class="oficinaEmisoraError">
                <label>Oficina Emisora</label>
                <md-input v-model="oficinaEmisora"></md-input>
                <span class="md-helper-text">Oficina Emisora</span>
              </md-field>
              <md-field :class="porcentajeError">
                <label>Porcentaje</label>
                <md-input v-model="porcentaje"></md-input>
                <span class="md-helper-text">porcentaje</span>
              </md-field>
              <md-field :class="porcentajeAsesorError">
                <label>Porcentaje del asesor</label>
                <md-input v-model="porcentajeAsesor"></md-input>
                <span class="md-helper-text">Porcentaje del asesor</span>
              </md-field>
              <md-field :class="porcentajeEjecutivoError">
                <label>Porcentaje del Ejecutivo</label>
                <md-input v-model="porcentajeEjecutivo"></md-input>
                <span class="md-helper-text">Procentaje del ejecutivo</span>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100">
              <md-field :class="latitudError">
                <label>Latitud</label>
                <md-input v-model="latitud"></md-input>
                <span class="md-helper-text">latitud</span>
              </md-field>
              <md-field :class="longitudError">
                <label>Longitud</label>
                <md-input v-model="longitud"></md-input>
                <span class="md-helper-text">longitud</span>
              </md-field>
              <md-field :class="foraneaError">
                <md-checkbox v-model="foranea">Es foranea</md-checkbox>
              </md-field>
              <md-field :class="activaError">
                <md-checkbox v-model="activa">Es activa</md-checkbox>
              </md-field>
              <md-field :class="activoWebError">
                <md-checkbox v-model="activoWeb">Es activa web</md-checkbox>
              </md-field>
            </div>
          </div>
        </template>
        <template slot="footer">
          <md-button class="md-danger md-simple" @click="classicModalHide"
            >Cerrar</md-button
          >
          <md-button
            class="md-primary"
            @click.native="createOrUpdateOrDelete(datosModal.evento)"
            :disabled="isActivo"
            >{{ datosModal.boton }}</md-button
          >
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
import { Pagination, Modal } from "@/components";
import Fuse from "fuse.js";
import Swal from "sweetalert2";
import servrepresentaciones from "../../Servicios/Representacion/representacion.js";
import mensaje from "../../Servicios/ManjeadorErrores/Mensajes";

export default {
  components: {
    Pagination,
    Modal,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      currentSort: "representacionId",
      currentSortOrder: "asc",
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      footerTable: [
        "Id",
        "Representación",
        "Dirección",
        "Teléfono",
        "Oficina",
        "Procentaje",
        "Acciones",
      ],
      searchQuery: "",
      propsToSearch: ["representacionId", "representacionNombre"],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      classicModal: false,
      Id: 0,
      datosModal: {
        titulo: "Crear una representacion",
        boton: "Confirmar",
        evento: 0,
      },
      isActivo: false,
      representacionId: 0,
      representacionNombre: null,
      direccion: null,
      telefonoOficina: null,
      oficinaEmisora: null,
      porcentaje: 0,
      porcentajeAsesor: 0,
      porcentajeEjecutivo: 0,
      foranea: true,
      latitud: null,
      longitud: null,
      activa: true,
      activoWeb: true,
      representacionIdError: 0,
      representacionNombreError: null,
      direccionError: null,
      telefonoOficinaError: null,
      oficinaEmisoraError: null,
      porcentajeError: 0,
      porcentajeAsesorError: 0,
      porcentajeEjecutivoError: 0,
      foraneaError: true,
      latitudError: null,
      longitudError: null,
      activaError: true,
      activoWebError: true,
    };
  },
  methods: {
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    createOrUpdateOrDelete(exp) {
      this.isActivo = true;
      var isBAndera = false;
      if (this.representacionNombre == null) {
        let mens = {
          tipo: "danger",
          mensaje: "El nombre de la representación no puede estar vacío.",
        };
        this.notifyVue(mens);
        isBAndera = true;
        this.isActivo = false;
      }
      if (isBAndera == false && (exp == 1 || exp == 2)) {
        var data = {
          representacionId: this.representacionId,
          representacionNombre: this.representacionNombre,
          direccion: this.direccion,
          telefonoOficina: this.telefonoOficina,
          oficinaEmisora: this.oficinaEmisora,
          porcentaje: this.porcentaje == null ? 0 : this.porcentaje,
          porcentajeAsesor: this.porcentajeAsesor == null ? 0 : this.porcentajeAsesor,
          porcentajeEjecutivo: this.porcentajeEjecutivo == null ? 0 : this.porcentajeEjecutivo,
          foranea: this.foranea,
          activa:this.activa,
          activoWeb:this.activoWeb,
          latitud: this.latitud,
          longitud: this.longitud,
        };
      }
      switch (exp) {
        case 1:
          if (isBAndera == false) {
            data.representacionId = 0;
            servrepresentaciones
              .post(data)
              .then((r) => {
                //hay que cerrar el model
                this.ProcesamosResponse(r.data);
                //Aqui recuperamos los datos
              })
              .catch((e) => {
                this.classicModalHide();
                mensaje.Error(
                  "representaciones-crud-servrepresentaciones-post",
                  e
                );
              });
          }

          break;
          case 2:
  if (this.representacionId > 0 && isBAndera == false) {
    servrepresentaciones
      .put(this.representacionId, data)
      .then((r) => {
        //hay que cerrar el model
        this.ProcesamosResponse(r.data);
        //Aqui recuperamos los datos
      })
      .catch((e) => {
        this.classicModalHide();
        mensaje.Error(
          "representaciones-crud-servrepresentaciones-put",
          e
        );
      });
  }
  break;


      }
    },
    ProcesamosResponse(data) {
      console.log(data);
      data.forEach((e) => {
        let m = {
          mensaje: "",
          tipo: "",
        };
        switch (e.tipo) {
          case 2: //Atributos por completar
            // se agrega a la clase el error "md-valid" ó "md-error"
            this[e.mensaje] = "md-error";
            break;
          case 1: //Error
            m.mensaje = e.mensaje;
            m.tipo = "danger";
            this.notifyVue(m);
            break;
          case 3: //success
            this.getDatos();
            this.classicModalHide();
            m.mensaje = e.mensaje;
            m.tipo = "success";
            this.notifyVue(m);
            break;
          case 4: //Information
            m.mensaje = e.mensaje;
            m.tipo = "info";
            this.notifyVue(m);
            break;
        }
      });
    },
    getDatos() {
      this.isLoader = true;
      this.tableData = [];
      servrepresentaciones
        .all()
        .then((p) => {
          this.tableData = p.data;
          this.isLoader = false;
          // Fuse search initialization.
          this.fuseSearch = new Fuse(this.tableData, {
            keys: ["representacionId", "representacionNombre"],
            threshold: 0.3,
          });
        })
        .catch((e) => {
          mensaje.Error("usuario-crud-all", e);
          this.isLoader = false;
        });
    },
    classicModalHide() {
      this.Clear();
      this.classicModal = false;
    },
    ModalPersonalizado(exp, row) {
      switch (exp) {
        case 1: //Insertar
          this.classicModal = true;
          this.datosModal.titulo = "Crear una representación";
          this.datosModal.boton = "Confirmar";
          this.datosModal.evento = 1;
          break;
        case 2: //Actualizar
          this.representacionId = row.representacionId;
          this.representacionNombre = row.representacionNombre;
          this.direccion = row.direccion;
          this.telefonoOficina = row.telefonoOficina;
          this.oficinaEmisora = row.oficinaEmisora;
          this.porcentaje = row.porcentaje;
          this.porcentajeAsesor = row.porcentajeAsesor;
          this.porcentajeEjecutivo = row.porcentajeEjecutivo;
          this.foranea = row.foranea;
          this.activa=row.activa;
          this.activoWeb=row.activoWeb;
          this.latitud = row.latitud;
          this.longitud = row.longitud;
          this.classicModal = true;
          this.datosModal.titulo = "Actualizar la representación";
          this.datosModal.boton = "Actualizar";
          this.datosModal.evento = 2;
          break;

        case 3: //Eliminar
          this.id = row.representacionId;
          this.deleteRow(this.id);
          break;
      }
    },
    deleteRow(row) {
      if (this.id > 0) {
        Swal.fire({
          title: "!Eliminar!",
          text: `¿Deseas eliminar el siguiente registro: ${this.id} ?`,
          type: "success",
          showCancelButton: true,
          confirmButtonClass: "md-button md-success",
          cancelButtonClass: "md-button md-danger",
          confirmButtonText: "Si, eliminar!",
          cancelButtonText: "Cancelar",
          buttonsStyling: false,
        })
          .then((r) => {
            if (r.value) {
              servrepresentaciones
                .delete(this.id)
                .then((response) => {
                  this.getDatos();
                  Swal.fire({
                    title: "Eliminado!",
                    text: `Se ha eliminado el registro ${this.id}`,
                    type: "success",
                    confirmButtonClass: "md-button md-success",
                    cancelButtonText: "Cancelar",
                    buttonsStyling: false,
                  });
                })
                .catch(function (e) {
                  mensaje.Error("usuario-crud-sercategorias-delete", e);
                });
            }
          })
          .catch(function (e) {
            mensaje.Error("usuario-crud-sercategorias-delete", e);
          });
      }
    },
    notifyVue(m) {
      this.$notify({
        message: m.mensaje,
        timeout: 30000,
        icon: "add_alert",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: m.tipo,
      });
    },
    Clear() {
      this.representacionId = 0;
      this.representacionNombre = null;
      this.direccion = null;
      this.telefonoOficina = null;
      this.oficinaEmisora = null;
      this.porcentaje = 0;
      this.porcentajeAsesor = 0;
      this.porcentajeEjecutivo = 0;
      this.foranea = false;
      this.activa=false;
      this.activoWeb=false;
      this.latitud = null;
      this.longitud = null;
      this.isActivo = false;
    },
  },
  created() {
    this.getDatos();
  },
  mounted() {},
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result.map(r => r.item);
    },
  },
};
</script>

<style lang="css" scoped>
</style>
