<template>
  <div class="md-layout">
    <div class="md-layout-item md-large-size-100">
      <collapse :collapse="[
        'Detalle', 
        'Seguimiento',
        'Estatus']" icon="keyboard_arrow_down" color-collapse="success"> 

        <template slot="md-collapse-pane-1">
          <createUpdate/>
        </template>

        <template slot="md-collapse-pane-2">
          <seguimiento/>
        </template>

        <template slot="md-collapse-pane-3">
          <estatus/>
        </template>

      </collapse>
    </div>
  </div>
</template>
<script>  

import { Collapse } from "@/components";
import seguimiento from "./seguimiento.vue";
import createUpdate from "./createupdate.vue";
import estatus from "./estatus.vue";

export default {
  components: {
    Collapse,
    seguimiento,
    createUpdate,
    estatus,
  },
  methods: {    
  },
};

</script>
<style lang="scss" scoped>
.conteiner-tab {
  width: 100%;
}
</style>