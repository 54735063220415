import { http } from "@/http-commons";

class Documentos {
  getId(id) {
    return http.get(`/Documentos/${id}`);
  }
  post(data){
    return http.post(`/Documentos`, data);
  }
  getVisual(id){ 
    return http.get(`/Documentos/Visualizacion/${id}`);
  }
  delete(id){
    return http.delete(`/Documentos/${id}`)
  }
  getDownload(id) {
    return http.get(`/Documentos/Download/${id}`, {responseType : 'blob'});
  }
}

export default new Documentos();