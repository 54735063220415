<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-primary">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">Generar contrato</h4>
        </md-card-header>

        <md-card-content>
          <md-table
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover"
          >
            <md-table-toolbar>
              <md-field>
                <label for="pages">Por página</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>

              <md-field>
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style="width: 200px"
                  placeholder="Buscar registro"
                  v-model="searchQuery"
                ></md-input>
              </md-field>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Tipo">{{
                item.documentoPlantillaTipo
              }}</md-table-cell>
              <md-table-cell md-label="ID">{{
                item.documentoPlantillaId
              }}</md-table-cell>
              <md-table-cell md-label="Nombre">{{
                item.documentoPlantillaNombre
              }}</md-table-cell>
              <md-table-cell md-label="Acciones"
                ><button
                  style="
                    font-size: 16px;
                    padding: 8px 16px;
                    background-color: rgb(170, 13, 13);
                    color: white;
                    border: none;
                    cursor: pointer;
                  "
                  class="accion-text"
                  @click="generarContrato(item)"
                  :disabled="isDisabled"
                >
                  Generar
                </button></md-table-cell
              >
            </md-table-row>
          </md-table>
        </md-card-content>

        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          >
          </pagination>
        </md-card-actions>
      </md-card>
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-primary">
          <h3 class="title">Jurisdiccion</h3>
        </md-card-header>
        <md-card-content>
          <div class="md-layout-item md-size-100 md-medium-size-100">
            <div class="md-layout">
              <div class="md-layout-item md-size-80 md-medium-size-100">
                <md-field>
                  <label>*Jurisdiccion</label>
                  <md-input v-model="jurisdiccion"></md-input>
                </md-field>
              </div>
              <div
                class="md-layout-item md-size-20 md-medium-size-100 md-layout-item--right"
              >
                <md-button class="md-primary" @click.native="Jurisdiccion()"
                  >Guardar</md-button
                >
              </div>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>
<script>
import { Pagination } from "@/components";
import servDoc from "@/Servicios/Operacion/documentoPlantilla.js";
import Fuse from "fuse.js";
import store from "@/Store/index";
export default {
  computed: {
    usuario() {
      return store.state.usuario;
    },
    general() {
      return store.state.General;
    },
    modoPantalla() {
      return store.state.Modo;
    },
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  components: {
    Pagination,
  },
  data() {
    return {
      currentSort: "",
      currentSortOrder: "asc",
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      tableData: [],
      searchQuery: [],
      searchedData: "",
      jurisdiccion: null,
      fuseSearch: null,
      isDisabled: false,
    };
  },
  methods: {
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    getAllDoc() {
      servDoc.all().then((r) => {
        this.tableData = r.data;
        this.fuseSearch = new Fuse(this.tableData, {
          keys: ["documentoPlantillaNombre", "documentoPlantillaTipo"],
          threshold: 0.3,
        });
      });
    },
    Jurisdiccion() {
      //inserte la logica de funcion del boton de jurisdiccion
    },
    generarContrato(item) {
      this.isDisabled = true;
      servDoc
        .getContrato(
          this.general.FisicaMoralId,
          item.documentoPlantillaId,
          this.usuario.usuariosId
        )
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${item.documentoPlantillaNombre}.docx`
          );
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.isDisabled = false;
        });
    },
  },
  created() {
    this.getAllDoc();
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        console.log(this.fuseSearch);
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result.map((r) => r.item);
    },
  },
};
</script>
<style scoped></style>
