import { http } from "@/http-commons";

class DetalleSolucion {
  getId(id) {
    return http.get(`/SolucionDetalle/${id}`);
  }
  post(data) {
    return http.post('/SolucionDetalle', data);
  }
  put(id, data) {
    return http.put(`/SolucionDetalle/${id}`, data);
  }
  delete(id) {
    return http.delete(`/SolucionDetalle/${id}`);
  }
}

export default new DetalleSolucion();