import { http } from "@/http-commons";

class tiporefpersonal {
  all() {
    return http.get('/tiporefpersonal');
  }
  get(id) {
    return http.get(`/tiporefpersonal/${id}`);
  }
  post(tiporefpersonal) {
    return http.post('/tiporefpersonal', tiporefpersonal);
  }
  put(id, tiporefpersonal) {
    return http.put(`/tiporefpersonal/${id}`, tiporefpersonal);
  }
  delete(id) {
    return http.delete(`/tiporefpersonal/${id}`);
  }
}

export default new tiporefpersonal();