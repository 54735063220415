<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-40">
      <listEmail v-bind:tableData="dataTable" @emailSelected="handleEmailSelected" />
    </div>
    <div class="md-layout-item md-size-60">
      <cardEmail v-bind:emailObj="emailSelected" />
    </div>
  </div>
</template>

<script>

import ListEmail from './Componentes/listEmail.vue'
import cardEmail from './Componentes/cardEmail.vue'
import servCorreo from "@/Servicios/Correo/Correo"

export default {
  components: {
    ListEmail,
    cardEmail
  },
  computed: {
  },
  data() {
    return {
      listEmails: [
        {
          body: "Lorem ipsum dolor sit amet conse"
        }
      ],
      dataTable: [],
      emailSelected: {},
    };
  },
  methods: {
    getDatos() {
      servCorreo.all().then((res) => {
        console.log(res);
        this.dataTable = res.data;
      });
    },
    handleEmailSelected(email) {
      this.emailSelected = email;
      // maneja el correo seleccionado
    },
  },
  created() {
    this.getDatos();
  },
  mounted() { },
  watch: {},
};
</script>

<style lang="css" scoped></style>
