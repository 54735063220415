<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">Pólizas</h4>
        </md-card-header>
        <md-card-content>
          <md-progress-bar class="md-warning" md-mode="buffer" v-if="isLoader == true"></md-progress-bar>
          <md-table :value="queriedData" :md-sort.sync="currentSort" :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort" class="paginated-table table-striped table-hover">
            <md-table-toolbar>
              <md-field>
                <label for="pages">Por página</label>
                <md-select v-model="pagination.perPage" name="pages" style="width: 75px">
                  <md-option v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>
              <md-field>
                <md-input type="search" class="mb-3" clearable style="width: 150px" placeholder="Buscar registro"
                  v-model="searchQuery">
                </md-input>
              </md-field>
              <md-field>
                <md-input type="date" class="mb-3" clearable style="width: 200px" placeholder="Dedes"
                  v-model="fechainicio">
                </md-input>
              </md-field>
              <md-field>
                <md-input type="date" class="mb-3" clearable style="width: 200px" placeholder="Hasta" v-model="fechafin">
                </md-input>
              </md-field>
              <md-field>
                <md-input type="numeric" class="mb-3" clearable style="width: 100px" placeholder="No. Póliza"
                  v-model="polizaId" v-on:keyup.enter="Buscamos()">
                </md-input>
              </md-field>
              <md-field>
                <md-button class="md-primary" @click="Buscamos()">Buscar</md-button>
              </md-field>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Póliza Id" md-sort-by="polizaId">{{
                item.polizaId
              }}</md-table-cell>
              <md-table-cell md-label="Solicitud Id" md-sort-by="solicitudId"><a
                  @click="Redirecciono(item)">{{
                    item.solicitudId
                  }}</a></md-table-cell>
              <md-table-cell md-label="Tipo" md-sort-by="tipopoliza">{{
                item.tipopoliza
              }}</md-table-cell>
              <md-table-cell md-label="Costo">{{
                item.costopoliza
              }}</md-table-cell>
              <md-table-cell md-label="Representación" md-sort-by="representacion">{{ item.representacion
              }}</md-table-cell>
              <md-table-cell md-label="Ejecutivo" md-sort-by="ejecutivo">{{
                item.ejecutivo
              }}</md-table-cell>
              <md-table-cell md-label="Fecha" md-sort-by="fechacreacion">{{
                item.fechacreacion
              }}</md-table-cell>
              <md-table-cell md-label="Estatus" md-sort-by="estatus">{{
                item.estatus
              }}</md-table-cell>
              <md-table-cell md-label="Renovación" md-sort-by="isrenovacion">{{
                item.isrenovacion
              }}</md-table-cell>
              <md-table-cell md-label="Acciones">
                <md-button class="md-just-icon md-warning md-simple" @click.native="
                Redireccionamiento('tabspoliza', item.polizaId)
                  ">
                  <md-icon>edit</md-icon>
                </md-button>
                <!-- <md-button
                  style="width: 50px"
                  class="md-just-icon md-danger md-simple"
                  @click.native="ModalPersonalizado(1, item)"
                >
                  <md-icon>mail</md-icon>
                </md-button> -->
              </md-table-cell>
            </md-table-row>
          </md-table>
          <div class="footer-table md-table">
            <table>
              <tfoot>
                <tr>
                  <th v-for="item in footerTable" :key="item" class="md-table-head">
                    <div class="md-table-head-container md-ripple md-disabled">
                      <div class="md-table-head-label">
                        {{ item }}
                      </div>
                    </div>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Mostrando {{ from + 1 }} de {{ to }} total {{ total }} de
              registros.
            </p>
          </div>
          <pagination class="pagination-no-border pagination-success" v-model="pagination.currentPage"
            :per-page="pagination.perPage" :total="total">
          </pagination>
          <!-- <md-button class="md-default" @click="ModalPersonalizado(2, null)"
            >Email Masivo</md-button
          > -->
        </md-card-actions>
      </md-card>
      <modal v-if="classicModal" @close="classicModalHide">
        <template slot="header">
          <h4 class="modal-title">{{ datosModal.titulo }}</h4>
          <md-button class="md-simple md-just-icon md-round modal-default-button" @click="classicModalHide">
            <md-icon>clear</md-icon>
          </md-button>
        </template>
        <template slot="body" v-if="datosModal.evento == 1">
          <md-field>
            <md-checkbox v-model="arrendador">Arrendador</md-checkbox></md-field>
          <md-field>
            <md-checkbox v-model="asesor">Asesor</md-checkbox></md-field>
          <md-field>
            <el-tree :data="dataOPC" :props="defaultProps" @node-click="handleNodeClick" highlight-current></el-tree>
          </md-field>
        </template>
        <template slot="body" v-if="datosModal.evento == 2">
          <md-field>
            <md-checkbox v-model="arrendador">Arrendador</md-checkbox>
            <md-checkbox v-model="asesor">Asesor</md-checkbox>
            <el-tree :data="dataOPC" :props="defaultProps" @node-click="handleNodeClick" highlight-current></el-tree>
          </md-field>
          <md-card-content>
            <md-table :value="queriedData" :md-sort.sync="currentSort" :md-sort-order.sync="currentSortOrder"
              :md-sort-fn="customSort" class="paginated-table table-striped table-hover" @md-selected="onSelect">
              <md-table-toolbar>
                <md-field>
                  <md-input type="date" class="mb-3" clearable style="width: 200px" placeholder="Desdes"
                    v-model="fechainicio">
                  </md-input>
                </md-field>
                <md-field>
                  <md-input type="date" class="mb-3" clearable style="width: 200px" placeholder="Hasta"
                    v-model="fechafin">
                  </md-input>
                </md-field>
                <md-field>
                  <md-button class="md-primary" @click="Buscamos()">Buscar</md-button>
                </md-field>
                <md-field>
                  <md-input type="search" class="mb-3" clearable style="width: 150px" placeholder="Buscar registro"
                    v-model="searchQuery">
                  </md-input>
                </md-field>
              </md-table-toolbar>
              <md-table-row slot="md-table-row" slot-scope="{ item }" md-selectable="multiple">
                <md-table-cell md-label="Póliza Id" md-sort-by="polizaId">{{
                  item.polizaId
                }}</md-table-cell>
                <md-table-cell md-label="Tipo" md-sort-by="tipopoliza">{{
                  item.tipopoliza
                }}</md-table-cell>
                <md-table-cell md-label="Costo">{{
                  item.costopoliza
                }}</md-table-cell>
                <md-table-cell md-label="Representación" md-sort-by="representacion">{{ item.representacion
                }}</md-table-cell>
                <md-table-cell md-label="Ejecutivo" md-sort-by="ejecutivo">{{
                  item.ejecutivo
                }}</md-table-cell>
                <md-table-cell md-label="Fecha" md-sort-by="fechacreacion">{{
                  item.fechacreacion
                }}</md-table-cell>
              </md-table-row>
            </md-table>
          </md-card-content>
          <md-card-actions md-alignment="space-between">
            <div class="">
              <p class="card-category">
                Mostrando {{ from + 1 }} de {{ to }} total {{ total }} de
                registros.
              </p>
            </div>
            <pagination class="pagination-no-border pagination-success" v-model="pagination.currentPage"
              :per-page="pagination.perPage" :total="total">
            </pagination>
          </md-card-actions>
        </template>
        <template slot="footer">
          <md-button class="md-danger md-simple" @click="classicModalHide">Cerrar</md-button>
          <md-button class="md-primary" @click.native="Accion(datosModal.evento)" :disabled="isActivo">{{ datosModal.boton
          }}</md-button>
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
import { Pagination, Modal } from "@/components";
import Fuse from "fuse.js";
import Swal from "sweetalert2";
import mensaje from "@/Servicios/ManjeadorErrores/Mensajes";
import servpoliza from "@/Servicios/Polizas/polizas";
import store from "@/Store/index";
import servpara from "@/Servicios/Catalogos/parametros";
import excel from "@/Utilerias/GenerarExcel.js";

export default {
  components: {
    Pagination,
    Modal,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
    usuario() {
      return store.state.usuario;
    },
  },
  data() {
    return {
      isLoader: true,
      currentSort: "polizaId",
      currentSortOrder: "asc",
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      footerTable: [
        "Póliza Id",
        "Solicitud Id",
        "Tipo ",
        "Costo",
        "Representación",
        "Ejecutivo",
        "Fecha",
        "Renovación",
        "Acciones",
      ],
      searchQuery: "",
      propsToSearch: ["polizaId", "solicitudId", "representacion", "ejecutivo"],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      fechainicio: "",
      fechafin: "",
      polizaId: null,
      datosModal: {
        titulo: "",
        boton: "",
        evento: 0,
      },
      classicModal: false,
      SujetosArray: [],
      dataOPC: [],
      ParametroId: 0,
      StringHtml: null,
      arrendador: false,
      asesor: false,
      selected: [],
      isActivo: false,
      parametroopc: 0,
    };
  },
  methods: {
    Redirecciono(item) {
      store.state.Modo = 5;
      store.state.General.SolicitudId = item.solicitudId;
      store.state.General.PolizaId = item.polizaId,
      store.state.General.FisicaMoralId = item.fisicaMoralId;
      this.$router.push("operacion");
    },
    onSelect(items) {
      this.selected = [];
      this.selected = items;
    },
    handleNodeClick(data) {
      console.log("Opc", data);
      this.StringHtml = data.value;
      this.ParametroId = data.id;
      switch (data.label) {
        case "Renovaciones":
          this.parametroopc = 1;
          break;
        case "Opción 2":
          this.parametroopc = 2;
          break;
        case "Opción 3":
          this.parametroopc = 3;
          break;
        case "Opción 4":
          this.parametroopc = 4;
          break;
      }
    },
    defaultProps() { },
    classicModalHide() {
      this.classicModal = false;
      this.ParametroId = 0;
      this.parametroopc = 0;
    },
    ModalPersonalizado(tipo, item) {
      switch (tipo) {
        case 1: //Insertar
          this.RetrieveParametros();
          this.classicModal = true;
          this.datosModal.titulo = "Enviar notificación individual";
          this.datosModal.boton = "Notificar";
          this.datosModal.evento = 1;
          this.polizaId = item.polizaId;
          break;
        case 2: //Email Masivo
          this.RetrieveParametros();
          this.classicModal = true;
          this.datosModal.titulo = "Enviar notificación masiva";
          this.datosModal.boton = "Notificar";
          this.datosModal.evento = 2;
          break;
      }
    },
    Accion(tipo) {
      this.isActivo = true;
      let m = {
        mensaje: "",
        tipo: "",
      };
      switch (tipo) {
        case 1: //Insertar
          let data = {
            polizaId: this.polizaId,
            asesor: this.asesor,
            arrendador: this.arrendador,
            parametroId: this.ParametroId,
            parametroopc: this.parametroopc,
          };
          servpoliza
            .NotificacionEmail(data)
            .then((r) => {
              this.ProcesamosResponse(r.data);
              this.isActivo = false;
            })
            .catch((e) => {
              this.isActivo = false;
              console.log("serv-polizanotiviacion-listado-poliza", e);
            });
          break;
        case 2:
          let isBandera = false;
          if (this.selected.length <= 0) {
            isBandera = true;
            m.mensaje = "Al menos debe seleccionar una póliza";
            m.tipo = "danger";
            this.notifyVue(m);
          }

          if (this.arrendador == false && this.asesor == false) {
            isBandera = true;
            m.mensaje =
              "Al menos debe seleccionar notificar a un Arrendador o Asesor o Ambos";
            m.tipo = "danger";
            this.notifyVue(m);
          }
          if (this.parametroopc == 0 || this.parametroopc == null) {
            isBandera = true;
            m.mensaje = "Al menos debe seleccionar una plantilla de correo";
            m.tipo = "danger";
            this.notifyVue(m);
          }
          if (isBandera == false) {
            let dataCol = [];
            if (this.selected.length == this.pagination.perPage) {
              this.searchedData.forEach((s) => {
                let data = {
                  polizaId: s.polizaId,
                  asesor: this.asesor,
                  arrendador: this.arrendador,
                  parametroId: this.ParametroId,
                  parametroopc: this.parametroopc,
                };
                dataCol.push(data);
              });
            } else {
              this.selected.forEach((s) => {
                let data = {
                  polizaId: s.polizaId,
                  asesor: this.asesor,
                  arrendador: this.arrendador,
                  parametroId: this.ParametroId,
                  parametroopc: this.parametroopc,
                };
                dataCol.push(data);
              });
            }

            //console.log(dataCol);
            let m = {
              mensaje: "",
              tipo: "",
            };
            m.mensaje =
              "Estamos trabajando una vez, concluido el envió se genera un excel con las pólizas que pude notificar y las que NO";
            m.tipo = "info";
            this.notifyVue(m);
            //console.log("envió: " + dataCol.length);
            let cadena = { candea: this.StringHtml };
            servpoliza
              .NotificacionPolizaMasiva(dataCol, cadena)
              .then((r) => {
                //this.ProcesamosResponse(r.data);
                //console.log(r.data);
                excel.GeneraExcel(r.data);

                this.isActivo = false;
              })
              .catch((e) => {
                this.isActivo = false;
                console.log("serv-NotificacionEmailMasiva-listado-poliza", e);
              });
          } else {
            this.isActivo = false;
            isBandera == false;
          }
          break;
      }
    },
    Redireccionamiento(pantalla, id) {
      store.state.General.Poliza = id;
      this.$router.push({ name: pantalla });
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    SetFechas() {
      var date = new Date(),
        y = date.getFullYear() - 1,
        m = date.getMonth();
      var firstDay = new Date(y, m, 1);
      var lastDay = new Date(y, m + 1, 0);
      this.fechainicio = firstDay.toISOString();
      this.fechafin = lastDay.toISOString();
    },
    getDatos() {
      console.log(this.fechainicio, this.fechafin);
      servpoliza
        .PolizasFechas(this.fechainicio, this.fechafin, this.usuario.usuariosId)
        .then((a) => {
          //console.log(a.data);
          this.tableData = a.data;
          // Fuse search initialization.
          this.fuseSearch = new Fuse(this.tableData, {
            keys: ["polizaId", "solicitudId", "representacion", "ejecutivo"],
            threshold: 0.3,
          });
          this.isLoader = false;
        })
        .catch((e) => {
          this.isLoader = false;
          mensaje.Error("poliza-PolizasFechas-all", e);
        });
    },
    getDatosPoliza() {
      servpoliza
        .BuscaPoliza(this.polizaId, this.usuario.usuariosId)
        .then((a) => {
          this.tableData = a.data;
          this.polizaId = 0;
        })
        .catch((e) => {
          mensaje.Error("poliza-PolizasFechas-specificPoliza", e);
          console.log(e);
        });
    },
    Buscamos() {
      if (this.polizaId > 0) {
        this.getDatosPoliza();
      } else if (
        this.fechainicio == "" ||
        (this.fechafin == "" && this.polizaId == 0)
      ) {
        this.$notify({
          message: "Las fechas no pueden estar vacías",
          timeout: 30000,
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger",
        });
      } else {
        this.getDatos();
      }
    },
    RetrieveParametros() {
      this.dataOPC = [];
      servpara
        .all()
        .then((r) => {
          console.log(r.data);
          r.data.forEach((v) => {
            //esta funcionalidad carga el arbol
            if (v.parametroValor == "Email") {
              let padre = {
                id: v.parametrosId,
                label: v.parametroNombre,
                children: [],
              };
              if (v.parametroValor1 != null) {
                let children = {
                  id: v.parametrosId,
                  label: "Renovaciones",
                  value: v.parametroValor1,
                };
                padre.children.push(children);
              }
              if (v.parametroValor2 != null) {
                let children = {
                  id: v.parametrosId,
                  label: "Opción 2",
                  value: v.parametroValor2,
                };
                padre.children.push(children);
              }
              if (v.parametroValor3 != null) {
                let children = {
                  id: v.parametrosId,
                  label: "Opción 3",
                  value: v.parametroValor3,
                };
                padre.children.push(children);
              }
              if (v.parametroValor4 != null) {
                let children = {
                  id: v.parametrosId,
                  label: "Opción 4",
                  value: v.parametroValor4,
                };
                padre.children.push(children);
              }
              this.dataOPC.push(padre);
            }
          });
          console.log("Datos de parametros: ", this.dataOPC);
        })
        .catch((e) => {
          mensaje.Error("parametro-ser-all-listado-polizas", e);
        });
    },
    ProcesamosResponse(data) {
      data.forEach((e) => {
        let m = {
          mensaje: "",
          tipo: "",
        };
        switch (e.tipo) {
          case 2: //Atributos por completar
            // se agrega a la clase el error "md-valid" ó "md-error"
            this[e.mensaje] = "md-error";
            break;
          case 1: //Error
            this.isActivo = false;
            m.mensaje = e.mensaje;
            m.tipo = "danger";
            this.notifyVue(m);
            break;
          case 3: //success
            this.classicModalHide();
            m.mensaje = e.mensaje;
            m.tipo = "success";
            this.notifyVue(m);
            break;
          case 4: //Information
            m.mensaje = e.mensaje;
            m.tipo = "info";
            this.notifyVue(m);
            break;
        }
      });
    },
    notifyVue(m) {
      this.$notify({
        message: m.mensaje,
        timeout: 30000,
        icon: "add_alert",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: m.tipo,
      });
    },
  },
  created() {
    this.SetFechas();
    this.getDatos();
  },
  mounted() { },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result.map(r => r.item);
    },
  },
};
</script>

<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.full-control>.md-list {
  width: 320px;
  max-width: 100%;
  height: 400px;
  display: inline-block;
  overflow: auto;
  border: 1px solid rgba(#000, 0.12);
  vertical-align: top;
}

.scrollable {
  overflow: auto;
}
</style>
