import { http } from "@/http-commons";

class TipoPolizaSol {
  all() {
    return http.get('/dashboardsoluciones/ListadoPorProcesoTipoPoliza');
  }
  get(id) {
    return http.get(`/dashboardsoluciones/ListadoPorProcesoTipoPoliza${id}`);  
  }
  
}

export default new TipoPolizaSol();