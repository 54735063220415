import { render, staticRenderFns } from "./HistoricoEmails.vue?vue&type=template&id=f8256ad8&scoped=true"
import script from "./HistoricoEmails.vue?vue&type=script&lang=js"
export * from "./HistoricoEmails.vue?vue&type=script&lang=js"
import style0 from "./HistoricoEmails.vue?vue&type=style&index=0&id=f8256ad8&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8256ad8",
  null
  
)

export default component.exports