<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">Cuentas por pagar</h4>
        </md-card-header>
        <md-card-content>
          <md-table
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover"
          >
            <md-table-toolbar>
              <md-field>
                <label for="pages">Por página</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>

              <md-field>
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style="width: 200px"
                  placeholder="Buscar registro"
                  v-model="searchQuery"
                >
                </md-input>
              </md-field>
              <md-field>
                <md-button class="md-primary" @click="ModalPersonalizado(1)"
                  >Nuevo</md-button
                >
              </md-field>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Id" md-sort-by="cpid">{{
                item.cpid
              }}</md-table-cell>
              <md-table-cell md-label="Póliza Id" md-sort-by="polizaId">{{
                item.polizaId
              }}</md-table-cell>
              <md-table-cell md-label="Categoria" md-sort-by="descripcion">{{
                item.categoria.descripcion
              }}</md-table-cell>
              <md-table-cell md-label="Importe" md-sort-by="importe">{{
                item.importe
              }}</md-table-cell>
              <md-table-cell md-label="Usuario" md-sort-by="usuario" v-if="item.usuario == null"></md-table-cell>
              <md-table-cell md-label="Usuario" md-sort-by="usuario" v-else>{{
                item.usuario.usuarioNomCompleto 
              }}</md-table-cell>
              <md-table-cell md-label="Acciones">
                <md-button
                  class="md-just-icon md-warning md-simple"
                  @click.native="ModalPersonalizado(2, item)"
                >
                  <md-icon>edit</md-icon>
                </md-button>
                <md-button
                  class="md-just-icon md-danger md-simple"
                  @click.native="ModalPersonalizado(3, item)"
                >
                  <md-icon>close</md-icon>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
          <div class="footer-table md-table">
            <table>
              <caption>Descripción de los datos en el pie de la tabla</caption>
              <tfoot>
                <tr>
                  <th
                    v-for="item in footerTable"
                    :key="item"
                    class="md-table-head"
                  >
                    <div class="md-table-head-container md-ripple md-disabled">
                      <div class="md-table-head-label">
                        {{ item }}
                      </div>
                    </div>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          >
          </pagination>
        </md-card-actions>
      </md-card>
      <modal v-if="classicModal" @close="classicModalHide">
        <template slot="header">
          <h4 class="modal-title">{{ datosModal.titulo }}</h4>
          <md-button
            class="md-simple md-just-icon md-round modal-default-button"
            @click="classicModalHide"
          >
            <md-icon>clear</md-icon>
          </md-button>
        </template>

        <template slot="body">
          <md-field>
            <label>Id</label>
            <md-input v-model="Id"></md-input>
            <span class="md-helper-text">Id:</span> </md-field
          ><md-field>
            <label>Póliza Id</label>
            <md-input v-model="polizaid"></md-input>
            <span class="md-helper-text">Póliza Id</span>
          </md-field>
          <div class="full-control">
            <md-list>
              <md-subheader>Categorias</md-subheader>
              <md-list-item v-for="c in categorias" :key="c.categoriaEsid">
                <md-checkbox v-model="categoriaId" :value="c.categoriaEsid" />
                <span class="md-list-item-text">{{ c.descripcion }}</span>
              </md-list-item>
            </md-list>
          </div>
        </template>
        <template slot="footer">
          <md-button class="md-danger md-simple" @click="classicModalHide"
            >Close</md-button
          >
          <md-button class="md-primary" @click.native="postNow(datosModal.evento)">{{datosModal.boton}}</md-button>
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
import { Pagination, Modal } from "@/components";
import users from "../../../pages/Dashboard/Tables/users";
import Fuse from "fuse.js";
import Swal from "sweetalert2";
import servcuentas from "../../../Servicios/Administracion/cuentasxpagar";
import store from "../../../Store/index";
import mensaje from "../../../Servicios/ManjeadorErrores/Mensajes";
import sercategorias from "../../../Servicios/Administracion/categorias";

export default {
  components: {
    Pagination,
    Modal,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
    usuario() {
      return store.state.usuario;
    },
  },
  data() {
    return {
      currentSort: "polizaId",
      currentSortOrder: "asc",
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      footerTable: ["Id", "Póliza Id", "Categoria", "Importe","Acciones"],
      searchQuery: "",
      propsToSearch: ["cpid", "polizaId", "categoria.descripcion"],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      user: null,
      classicModal: false,
      categorias: [],
      categoriaId: 0,
      Id: 0,
      polizaid: 0,
      datosModal: {
        titulo: "Crear un pago",
        boton: "Confirmar",
        evento: 0,
      },
    };
  },
  methods: {
    customSort(value) {
      console.log(value);
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    postNow(exp) {
      var data = {
        id: this.Id,
        categoriaId: this.categoriaId,
        polizaid: this.polizaid,
        usuarioId: this.usuario.usuariosId
      };
      console.log(data);
      this.sendNoty("success","correcto");
      //console.log(data);
      // switch (exp) {
      //   case 1:
      //     servcuentas
      //       .post(data)
      //       .then((response) => {
      //         //hay que cerrar el model
      //         this.classicModal = false;
      //         this.Notificaciones(response);
      //         //Aqui recuperamos los datos
      //       })
      //       .catch(function (e) {
      //         mensaje.Error("cxp-crud-servcuentas-post", e);
      //       });
      //     break;

      //   case 2:
      //     if (this.id > 0) {
      //       servcuentas
      //         .put(this.id, data)
      //         .then((response) => {
      //           //hay que cerrar el model
      //           this.classicModal = false;
      //           this.Notificaciones(response);
      //           //Aqui recuperamos los datos
      //         })
      //         .catch(function (e) {
      //           mensaje.Error("cxp-crud-servcuentas-put", e);
      //         });
      //     }

      //     break;
      // }
    },
    getDatos() {
      servcuentas
        .all()
        .then((p) => {
          //console.log('contesto');
          //console.log(p.data);
          //categoria: null
          // categoriaId: 7
          // cpid: 1
          // importe: 230
          // movimientos: []
          // poliza: null
          // polizaId: 50132
          // usuario: null
          // usuarioId: null
          this.tableData = p.data;
          //console.log(p);
        })
        .catch((e) => {
          mensaje.Error("cxp-crud-all", e);
        });
    },
    classicModalHide() {
      this.Clear();
      this.classicModal = false;
    },
    async getCategorias() {
      sercategorias
        .categoriapormodulo(false, true)
        .then((c) => {
          console.log(c);
          this.categorias = c.data;
        })
        .catch((e) => {
          mensaje.Error("cxp-crud-categoriapormodulo-get", e);
        });
    },
    ModalPersonalizado(exp, row) {
      // console.log(row);//este es el bueno
      // console.log("Usuario id: "+ row.id)
      switch (exp) {
        case 1: //Insertar
          this.classicModal = true;
          this.datosModal.titulo = "Crear Categoría";
          this.datosModal.boton = "Confirmar";
          this.datosModal.evento = 1;
          break;
        case 2: //Actualizar
        console.log(row);
          this.Id = row.cpid;
          this.categoriaId = row.categoriaId;
          this.polizaid = row.polizaId;
          this.classicModal = true;
          this.datosModal.titulo = "Actualizar Categoria";
          this.datosModal.boton = "Actualizar";
          this.datosModal.evento = 2;
          break;

        case 3: //Eliminar
          this.id = row.cpid;
          this.deleteRow(this.id);
          console.log("entro");
          break;
      }
    },
    deleteRow(row) {
      if (this.id > 0) {
        Swal.fire({
          title: "!Eliminar!",
          text: `¿Deseas eliminar el siguiente registro: ${this.id} ?`,
          type: "success",
          showCancelButton: true,
          confirmButtonClass: "md-button md-success",
          cancelButtonClass: "md-button md-danger",
          confirmButtonText: "Si, eliminar!",  
          cancelButtonText: "Cancelar",        
          buttonsStyling: false,
        })
          .then((r) => {
            if (result.value) {
              sercategorias
                .delete(row.id)
                .then((response) => {
                  //hay que cerrar el model
                  this.Notificaciones(response);
                  Swal.fire({
                    title: "Eliminado!",
                    text: `Se ha eliminado el registro ${this.id}`,
                    type: "success",
                    confirmButtonClass: "md-button md-success",
                    cancelButtonText: "Cancelar",
                    buttonsStyling: false,
                  });
                })
                .catch(function (e) {
                  mensaje.Error("cxp-crud-sercategorias-delete", e);
                });
            }
          })
          .catch(function (e) {
            mensaje.Error("cxp-crud-sercategorias-delete", e);
          });
      }
    },
    sendNoty(type, menssage) {
      this.$notify({
        message: menssage,
        timeout: 30000,
        icon: "add_alert",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: type,
      });
    },
    Clear() {
      this.categoriaId = 0;
      this.Id = 0;
      this.polizaid = 0;
    },
  },
  created() {
    console.log('create');
    this.getDatos();
    this.getCategorias();
  },
  mounted() {
    // Fuse search initialization.
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ["polizaId","usuario"],
      threshold: 0.3,
    });
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result.map(r => r.item);
    },
  },
};
</script>

<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.full-control > .md-list {
  width: 320px;
  max-width: 100%;
  height: 400px;
  display: inline-block;
  overflow: auto;
  border: 1px solid rgba(#000, 0.12);
  vertical-align: top;
}
</style>
