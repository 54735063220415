import { http } from "@/http-commons";

class representacion {
  all() {
    return http.get('/representacion');
  }
  get(id) {
    return http.get(`/representacion/${id}`);
  }
  post(representacion) {
    return http.post('/representacion',representacion);
  }
  put(id, representacion) {
    return http.put(`/representacion/${id}`,representacion);
  }
  delete(id) {
    return http.delete(`/representacion/${id}`);
  }
  allActivos() {
    return http.get('/representacion/activas');
  }
}

export default new representacion();