import { http } from "@/http-commons";

class eventos {
  all() {
    return http.get('/evento');
  }
  get(id) {
    return http.get(`/evento/${id}`);
  }
  post(eventos) {
    return http.post('/evento',eventos);
  }
  put(id, eventos) {
    return http.put(`/evento/${id}`,eventos);
  }
  delete(id) {
    return http.delete(`/evento/${id}`);
  }
  Notificacionusuariosdelevento(id) {
    return http.get(`/evento/notificacion/${id}`);
  }
}

export default new eventos();