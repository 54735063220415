import { data } from "jquery";
import { http } from "@/http-commons";

class ReferenciasPersonales {
  all(){
    return http.get('/referenciasPersonales');
  }
  getId(id) {
    return http.get(`/referenciasPersonales/${id}`);
  }
  delete(id){
    return http.delete(`/referenciasPersonales/${id}`);
  }
  post(data){
    return http.post('/referenciasPersonales', data);
  }
  put(id,data)
  {
    return http.put(`/referenciasPersonales/${id}`, data);
  }
  
}

export default new ReferenciasPersonales();

