import XLSX from "xlsx";


class excel {
    GenerarEstadoCuenta(tableData) {
        function DescargarExcel(s) {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
            return buf;
        }
        var wb = XLSX.utils.book_new();
        wb.Props = {
            Title: "EstadoCuenta",
            Author: "sispo.web",
            CreatedDate: new Date()
        };

        let contador = 0;
        let contadorMes = 1;
        console.log("datosResumen", tableData.resumenEst);
        tableData.resumenEst.forEach(i => {
            if (contadorMes <= tableData.resumenEst.length - 2) {
                wb.SheetNames.push(i.mes);
                let endContador = contador +i.polizas;                
                let datos = tableData.estadoCuentaViewModels.slice(contador, endContador);
                contador += i.polizas;
                var ws = XLSX.utils.json_to_sheet(datos);
                wb.Sheets[i.mes] = ws;
            }
            contadorMes++;
        });

        wb.SheetNames.push("Resumen");
        var ws = XLSX.utils.json_to_sheet(tableData.resumenEst);
        wb.Sheets["Resumen"] = ws;

        var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
        var excel = new Blob([DescargarExcel(wbout)], { type: "application/octet-stream" });
        var link = window.URL.createObjectURL(excel);
        var fileLink = document.createElement('a');
        fileLink.href = link;
        fileLink.download = "EstadoCuenta.xlsx";
        fileLink.click();
    }
    
    GenerarReporteVentas(tableData) {
        function DescargarExcel(s) {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
            return buf;
        }
        var wb = XLSX.utils.book_new();
        wb.Props = {
            Title: "ReporteVentas",
            Author: "sispo.web",
            CreatedDate: new Date()
        };

        let contador = 0;
        let contadorMes = 1;
        console.log("datosResumen", tableData.resumenEst);
        tableData.resumenEst.forEach(i => {
            if (contadorMes <= tableData.resumenEst.length - 2) {
                wb.SheetNames.push(i.mes);
                let endContador = contador +i.polizas;                
                let datos = tableData.estadoCuentaViewModels.slice(contador, endContador);
                contador += i.polizas;
                var ws = XLSX.utils.json_to_sheet(datos);
                wb.Sheets[i.mes] = ws;
            }
            contadorMes++;
        });

        wb.SheetNames.push("Resumen");
        var ws = XLSX.utils.json_to_sheet(tableData.resumenEst);
        wb.Sheets["Resumen"] = ws;

        var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
        var excel = new Blob([DescargarExcel(wbout)], { type: "application/octet-stream" });
        var link = window.URL.createObjectURL(excel);
        var fileLink = document.createElement('a');
        fileLink.href = link;
        fileLink.download = "ReporteVentas.xlsx";
        fileLink.click();
    }
}

export default new excel();