import { render, staticRenderFns } from "./tipoinmobiliario.vue?vue&type=template&id=441856e2&scoped=true"
import script from "./tipoinmobiliario.vue?vue&type=script&lang=js"
export * from "./tipoinmobiliario.vue?vue&type=script&lang=js"
import style0 from "./tipoinmobiliario.vue?vue&type=style&index=0&id=441856e2&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "441856e2",
  null
  
)

export default component.exports