import DropDown from "./components/Dropdown.vue";
import { ValidationProvider } from "vee-validate";
import { ValidationObserver } from "vee-validate";
import { formatos } from "./Utilerias/formatos.js";
import { Tree } from "element-ui";


const GlobalComponents = {
  install(Vue) {
    
    Vue.component("el-tree", Tree);
    Vue.component("drop-down", DropDown);
    Vue.component("ValidationProvider", ValidationProvider);
    Vue.component("ValidationObserver", ValidationObserver);
    Vue.prototype.$formatos = formatos;
  },
};

export default GlobalComponents;
