export const formatos = {
    formatPrice(value) {
        if (value > 0) {
            let val = (value / 1).toFixed(2).replace(".", ".");
            return "$ " + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
            return "$ 0";
        }
    }
}
