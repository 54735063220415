import xlsx from "xlsx";

class excel {
    GeneraExcel(tableData) {
        console.log(tableData);
        function DescargarExcel(s) {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
            return buf;
        }
        var wb = xlsx.utils.book_new();
        wb.Props = {
            Title: "Solicitudes",
            Author: "sispo.web",
            CreatedDate: new Date()
        };
        wb.SheetNames.push("Solicitudes");
        var ws = xlsx.utils.json_to_sheet(tableData);
        wb.Sheets["Solicitudes"] = ws;
        var wbout = xlsx.write(wb, { bookType: 'xlsx', type: 'binary' });
        var excel = new Blob([DescargarExcel(wbout)], { type: "application/octet-stream" });
        var link = window.URL.createObjectURL(excel);
        var fileLink = document.createElement('a');
        fileLink.href = link;
        fileLink.download = "Solicitudes.xlsx";
        fileLink.click();
    }

    GeneraExcelMovimientos(tableData) {
        function DescargarExcel(s) {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
            return buf;
        }
        var wb = xlsx.utils.book_new();
        wb.Props = {
            Title: "Recibo",
            Author: "sispo.web",
            CreatedDate: new Date()
        };
        wb.SheetNames.push("Movimientos");
        const obj = [];
        tableData.forEach(e =>{
            let encabezado = {
                "ReciboId": e.mmId,
                "fecha": e.fecha,
                "usuarioMovimiento": e.usuarioMovimiento,
                "cuenta": e.cuenta
            }
            obj.push(encabezado);
            tableData[0].conceptos.forEach(c => {
                let concepto = {
                    "polizaId": c.polizaId,
                    "direccion del inmueble": c.direccion,
                    "concepto": c.concepto,
                    "flujo": c.flujo,
                    "importe": c.importe,
                    "representante": c.representante,                    
                    
                }
                obj.push(concepto);
            });
        });
        
        const excelConvert = xlsx.utils.json_to_sheet(obj);
        wb.Sheets["Movimientos"] = excelConvert;
        var wbout = xlsx.write(wb, { bookType: 'xlsx', type: 'binary' });
        var excel = new Blob([DescargarExcel(wbout)], { type: "application/octet-stream" });
        var link = window.URL.createObjectURL(excel);
        var fileLink = document.createElement('a');
        fileLink.href = link;
        fileLink.download = "Recibo.xlsx";
        fileLink.click();
    }
    GenerarExcelAJSON(e){      
        var json;
        var file = e.target.files ? e.target.files[0] : null;
      if (file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target.result;
          const wb = xlsx.read(bstr, { type: 'binary' });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          json = xlsx.utils.sheet_to_json(ws, { header: 1 });
        }

        reader.readAsBinaryString(file);
      }

        reader.readAsArrayBuffer(f);   
        return json;
    }
}

export default new excel();