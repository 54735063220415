import { http } from "@/http-commons";
import httpdownload from "../../http-download";

class polizas {
  dpall(id) {
    return http.get(`/DetallePoliza/dpall/${id}`);
  }
  post(dp) {
    return http.post('/DetallePoliza',dp);
  }
  put(id, dp) {
    return http.put(`/DetallePoliza/${id}`,dp);
  }
  delete(id) {
    return http.delete(`/DetallePoliza/${id}`);
  }
  GenerarMovimiento(CuentaId, PolizaId,UsuarioId){
    return httpdownload.get(`/DetallePoliza/GenerarMovimiento/${CuentaId}/${PolizaId}/${UsuarioId}`,{responseType: 'blob'});
  }
  MoviDetallePolizas(UsuarioId,categoriaId) {
    return http.get(`/DetallePoliza/MoviDetallePolizas/${UsuarioId}/${categoriaId}`);
  }
  MoviDetallePolizasId(UsuarioId,id) {
    return http.get(`/DetallePoliza/MoviDetallePolizasId/${UsuarioId}/${id}`);
  }
}

export default new polizas();