var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper",class:[
    { 'nav-open': _vm.$sidebar.showSidebar },
    { rtl: _vm.$route.meta.rtlActive },
  ]},[_c('notifications'),_c('side-bar',{attrs:{"active-color":_vm.sidebarBackground,"background-image":_vm.sidebarBackgroundImage,"data-background-color":_vm.sidebarBackgroundColor}},[_c('user-menu',{attrs:{"title":_vm.usuario.usuarioNomCompleto,"rtlTitle":_vm.usuario.usuarioNombre,"avatar":_vm.usuario.imagen}}),_c('mobile-menu'),_c('template',{slot:"links"},_vm._l((_vm.menu),function(link){return _c('sidebar-item',{key:link.id,attrs:{"link":{
          name: link.nombre,
          icon: 'dashboard',
          path: '/' + link.controlador,
        }}},_vm._l((link.inverseMenuPpadre),function(subLink){return _c('sidebar-item',{key:subLink.id,attrs:{"link":{
            name: subLink.nombre,
            icon: 'dashboard',
            path: '/' + subLink.controlador,
          }}})}),1)}),1)],2),_c('div',{staticClass:"main-panel"},[_c('top-navbar'),_c('div',{class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }