<template>
  <div class="md-layout">
    <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-25">
      <md-card>
        <md-card-content>
          <label> <strong>Cuenta:</strong> {{ cuenta.Nombre }}</label
          ><br />
          <label v-if="usuario.areaId == 1">
            <strong>Saldo Actual:</strong>
            {{ this.$formatos.formatPrice(cuenta.Saldo) }}</label
          ><br />
          <label v-if="usuario.areaId == 1">
            <strong>Saldo pre-proyectado:</strong>
            {{ this.$formatos.formatPrice(cuenta.proyeccion) }}</label
          >
        </md-card-content>
      </md-card>
    </div>
    <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-25">
      <md-card>
        <md-card-content>
          <label>
            <strong>Entrada:</strong>
            {{ this.$formatos.formatPrice(cuenta.entrada) }}</label
          >
        </md-card-content>
      </md-card>
    </div>
    <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-25">
      <md-card>
        <md-card-content>
          <label>
            <strong>Salida:</strong>
            {{ this.$formatos.formatPrice(cuenta.salida) }}</label
          >
        </md-card-content>
      </md-card>
    </div>
    <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-25">
      <md-card>
        <md-card-content>
          <md-button class="md-success" @click="GenerarMovimiento()"
            >Generar corte</md-button
          >
        </md-card-content>
      </md-card>
    </div>
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header>
          <h4 class="title">Módulos administrativos</h4>
        </md-card-header>
        <md-card-content>
          <detallepoliza></detallepoliza>
          <detalleMovimientos></detalleMovimientos>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
//import { Collapse } from "@/components";
import servmovimientos from "@/Servicios/Movimientos/movimientos";
import detallepoliza from "./detallepoliza.vue";
import detalleMovimientos from "./transferencias.vue";
import store from "@/Store";
import Swal from "sweetalert2";
import servconta from "@/Servicios/Contabilidad/contabilidad";
import excel from "@/Utilerias/GenerarExcel";

export default {
  name: "Detalle",
  computed: {
    cuenta() {
      return store.state.Cuenta;
    },
    Movimientos() {
      return store.state.Movimientos;
    },
    usuario() {
      return store.state.usuario;
    },
  },
  components: {
    //Collapse,
    detallepoliza,
    detalleMovimientos,
  },
  data() {
    return {
      searchQuery: "",
      cuentaId: 0,
      tableData: [],
      mens: {
        tipo: "",
        mensaje: "",
      },
      MMid: 0,
    };
  },
  methods: {
    GetDetallePoliza() {
      servmovimientos
        .getdp()
        .then((r) => {
          this.tableDataDPlist = r.data;
          console.log(r.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    GenerarMovimiento() {
      if (
        this.Movimientos.DetallePolizas.length > 0 ||
        this.Movimientos.Transferencias.length > 0 ||
        this.Movimientos.CuentasXPagar.length > 0 ||
        this.Movimientos.CuentasXCobrar.length > 0
      ) {
        Swal.fire({
          title: "Generar movimiento",
          text: `¿ Estas seguro del siguiente movimiento?`,
          type: "success",
          showCancelButton: true,
          confirmButtonClass: "md-button md-success",
          cancelButtonClass: "md-button md-danger",
          confirmButtonText: "Si, Generar!",
          cancelButtonText: "Cancelar",
          buttonsStyling: false,
        })
          .then((r) => {
            if (r.value) {
              let DataMovimiento = {
                dm: this.Movimientos.Transferencias,
                dp: this.Movimientos.DetallePolizas,
              };
              servconta
                .post(this.usuario.usuariosId, this.cuentaId, DataMovimiento)
                .then((r) => {
                  //hay que cerrar el model
                  console.log("response generar Corte",r.data);
                  if (r.data.length > 0) {
                    this.ProcesamosResponse(r.data);
                  }
                })
                .catch(function (e) {
                  console.log("generarMovimiento", e);
                });
            }
          })
          .catch(function (e) {
            console.log("generarMovimiento", e);
          });
      } else {
        this.mens.tipo = "danger";
        this.mens.mensaje =
          "Para poder generar un movimiento al menos debe de existir un registro de: detalle de poliza/transferencia/cuenta por pagar/ cuenta por cobrar";
        this.notifyVue(this.mens);
      }
    },
    notifyVue(m) {
      this.$notify({
        message: m.mensaje,
        timeout: 30000,
        icon: "add_alert",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: m.tipo,
      });
    },
    ProcesamosResponse(data) {
      data.forEach((e) => {
        let m = {
          mensaje: "",
          tipo: "",
        };
        switch (e.tipo) {
          case 2: //Atributos por completar
            // se agrega a la clase el error "md-valid" ó "md-error"
            this[e.mensaje] = "md-error";
            break;
          case 1: //Error
            this.isActivo = false;
            m.mensaje = e.mensaje;
            m.tipo = "danger";
            this.notifyVue(m);
            break;
          case 3: //success
            m.mensaje = e.mensaje;
            m.tipo = "success";
            this.notifyVue(m);
            break;
          case 4: //Information
            m.mensaje = e.mensaje;
            m.tipo = "info";
            this.notifyVue(m);
            break;
          case 5: //Parametros
            this.MMid = parseInt(e.mensaje);
            if (this.MMid > 0) {
              servconta
                .get(this.MMid)
                .then((r) => {
                  if (r.data.length > 0) {
                    console.log("datos Excel",r.data);
                    excel.GeneraExcelMovimientos(r.data);
                    Swal.fire({
                      title: "Se ha generado correctamente",
                      text: `Se registro correctamente, un par de segundo se generar el recibo correspondiente`,
                      type: "success",
                      confirmButtonClass: "md-button md-success",
                      cancelButtonText: "Cancelar",
                      buttonsStyling: false,
                    }).then((r) => {
                      if (r.value) {
                        //redireccionamos a la pantalla de movimientos principal}
                        this.$router.push({
                          name: "Movimientos",
                        });
                      }
                    });
                  }
                })
                .catch((e) => {
                  console.log("Error en generar el recibo", e);
                });
            }
            break;
        }
      });
    },
  },
  created() {
    this.cuentaId = this.$route.params.id;
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result.map(r => r.item);
    },
  },
};
</script>

<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
</style>
