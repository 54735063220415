<template>
  <div class="md-layout">
    <div class="md-layout-item md-small-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-primary">
          <div class="card-icon">
            <md-icon>mail_outline</md-icon>
          </div>
          <h4 class="title">Investigación</h4>
        </md-card-header>
        <md-card-content>
          <div class="md-layout-item md-size-100 md-medium-size-100">
            <div class="md-layout">
              <div class="md-layout-item md-size-50 md-medium-size-100">
                <md-field>
                  <label>Texto 1</label>
                  <md-textarea v-model="texto1"></md-textarea>
                </md-field>
              </div>
              <div class="md-layout-item md-size-50 md-medium-size-100">
                <md-field>
                  <label>Texto 2</label>
                  <md-textarea v-model="texto2"></md-textarea>
                </md-field>
              </div>
            </div>
          </div>
          <div class="md-layout-item md-size-100 md-medium-size-100">
                <div class="md-layout">
                  <div class="md-layout-item md-size-25 md-medium-size-100">
                    <md-checkbox v-model="hipoteca"
                      >Hipotecas</md-checkbox
                    >
                  </div>
                  <div class="md-layout-item md-size-25 md-medium-size-100">
                    <md-checkbox v-model="controversias" 
                      >Controversias de Arrendamiento</md-checkbox
                    >
                  </div>
                  <div class="md-layout-item md-size-25 md-medium-size-100">
                    <md-checkbox v-model="procesosP"
                      >Procesos Penales</md-checkbox
                    >
                  </div>
                  <div class="md-layout-item md-size-25 md-medium-size-100">
                    <md-checkbox v-model="alertas"
                      >Alertas en Medios
                    </md-checkbox>
                  </div>
                </div>
              </div>
              <div class="md-layout-item md-size-100 md-medium-size-100">
                <div class="md-layout">
                  <div class="md-layout-item md-size-25 md-medium-size-100">
                    <md-checkbox v-model="ejecutivo"
                      >Ejecutivo Mercantil</md-checkbox
                    >
                  </div>
                </div>
              </div>
        </md-card-content>
        <md-card-actions md-alignment="right">
          <md-button class="md-primary" @click.native="postNow()">GUARDAR </md-button>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>

<script>
import store from "@/Store/index";
import servInv from "@/Servicios/Operacion/investigacion.js";
import mensaje from "@/Servicios/ManjeadorErrores/Mensajes";
import servFM from "@/Servicios/Operacion/fisicamolal.js";
export default {
  computed: {
    usuario() {
      return store.state.usuario;
    },
    general() {
      return store.state.General;
    },
    modo() {
      return store.state.Modo;
    },
  },
  data() {
    return {
      id: 0,
      texto1: "",
      texto2: "",
      hipoteca: false,
      controversias : false,
      //otros: false,
      procesosP : false,
      alertas: false,
      ejecutivo : false,
      fisicaMoralId: 0,
      dataFm : [],
    };
  },
  methods: {
    postNow() {
      let datosRi = {
        id: this.id,
        texto1: this.texto1,
        texto2: this.texto2,
        fisicaMoralId: this.fisicaMoralId,
      };
      let datosFm = {
        SolicitudId: this.general.SolicitudId,
        FisicaMoralId : this.fisicaMoralId,
        SFisicaNacionallidad: this.dataFm.sfisicaNacionallidad,
        SFisicaCondMigratoria: this.dataFm.sfisicaCondMigratoria ,
        SFisicaEstadoCivil:this.dataFm.sfisicaEstadoCivil,
        SFisicaConvenioEC: this.dataFm.sfisicaConvenioEc , 
        SFisicaDomicilio: this.dataFm.sfisicaDomicilio ,
        SFisicaColonia: this.dataFm.sfisicaColonia ,
        SFisicaDelegacionMunicipio: this.dataFm.sfisicaDelegacionMunicipio ,
        SFisicaEstado: this.dataFm.sfisicaEstado ,
        SFisicaTelefono: this.dataFm.sfisicaTelefono ,
        SFisicaCelular: this.dataFm.sfisicaCelular,
        SFisicaEmail: this.dataFm.sfisicaEmail ,
        SFisicaProfesion: this.dataFm.sfisicaProfesion,
        SFisicaIngresoMensual: this.dataFm.sfisicaIngresoMensual ,
        SFisicaTrabajo: this.dataFm.sfisicaTrabajo,
        SFisicaAntiguedad: this.dataFm.sfisicaAntiguedad,
        SFisicaPuesto: this.dataFm.sfisicaPuesto,
        SFisicaTelefonoTrabajo: this.dataFm.sfisicaTelefonoTrabajo ,
        SFisicaHorario: this.dataFm.sfisicaHorario ,
        SFisicaDomicilioTrabajo: this.dataFm.sfisicaDomicilioTrabajo ,
        SFisicaColoniaTrabajo: this.dataFm.sfisicaColoniaTrabajo ,
        SFisicaDelegMuniTrabajo: this.dataFm.sfisicaDelegMuniTrabajo ,
        SFisicaEstadoTrabajo: this.dataFm.sfisicaEstadoTrabajo ,
        SFisicaGiroTrabajo:this.dataFm.sfisicaGiroTrabajo ,
        SFisicaWebTrabajo: this.dataFm.sfisicaWebTrabajo ,
        SFisicaJefeTrabajo: this.dataFm.sfisicaJefeTrabajo ,
        SFisicaPuestoJefe: this.dataFm.sfisicaPuestoJefe ,
        SFisicaEmailJefe: this.dataFm.sfisicaEmailJefe ,
        SFisicaFactura: this.dataFm.sfisicaFactura ,
        SFisicaNombre:this.dataFm.sfisicaNombre ,
        SFisicaApePat: this.dataFm.sfisicaApePat ,
        SFisicaApeMat:this.dataFm.sfisicaApeMat ,
        SFisicaRFC:this.dataFm.sfisicaRfc ,
        //SFisicaRazonSocial:this.SFisicaRazonSocial ,
        SFisicaCodigoPostal: this.dataFm.sfisicaCodigoPostal ,
        SFisicaCodigoPostalTrabajo: this.dataFm.sfisicaCodigoPostalTrabajo ,
        TipoIdentificacion:this.dataFm.tipoIdentificacion ,
        NumeroIdentificacion:this.dataFm.numeroIdentificacion,
        controversiaarrendamiento : this.controversias,
        hipotecas :  this.hipoteca,
        ProcesosPenales :  this.procesosP,
        AlertasMedios : this.alertas,
        EjecutivoMercantil : this.ejecutivo,
      };
      if (this.id > 0) {
        servInv
          .put(this.id, datosRi)
          .then((r) => {
            this.ProcesamosResponse(r.data);
          })
          .catch((e) => {
            mensaje.Error("ReporteInvestigacion-crud-put", e);
          });
          servFM
          .put(this.fisicaMoralId, datosFm)
          .then((r) => {
            this.ProcesamosResponse(r.data);
          });
      } else {
        servInv
          .post(datosRi)
          .then((r) => {
            this.ProcesamosResponse(r.data);
          })
          .catch((e) => {
            mensaje.Error("ReporteInvestigacion-crud-post", e);
          });
          servFM
          .put(this.fisicaMoralId, datosFm)
          .then((r) => {
            this.ProcesamosResponse(r.data);
          });
      }
    },

    getDatos() {
      if (this.fisicaMoralId) {
        servInv
          .getId(this.fisicaMoralId)
          .then((r) => this.Asignamos(r.data))
          .catch((e) => console.log("Error - fisicaMoral", e));
        servFM
        .getId(this.general.SolicitudId)
        .then((r) => { 
          this.AsignamosFm(r.data)
          this.dataFm = r.data;  
        })
        .catch((e) => console.log("Error - fisicaMoral", e));  
      } else {
        console.error("El fisicaMoralId es undefined o null");
      }
    },

    Asignamos(r) {
      this.id = r.id;
      this.texto1 = r.texto1;
      this.texto2 = r.texto2;
    },
    AsignamosFm(r){
      this.controversias = r.controversiaarrendamiento;
      this.hipoteca = r.hipotecas;
      this.procesosP = r.procesosPenales;
      this.alertas = r.alertasMedios;
      this.ejecutivo =  r.ejecutivoMercantil;
    },
    notifyVue(m) {
      this.$notify({
        message: m.mensaje,
        timeout: 30000,
        icon: "add_alert",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: m.tipo,
      });
    },

    ProcesamosResponse(data) {
      data.forEach((e) => {
        let m = {
          mensaje: "",
          tipo: "",
        };
        switch (e.tipo) {
          case 1: //Error
            m.mensaje = e.mensaje;
            m.tipo = "danger";
            this.notifyVue(m);
            break;
          case 2: //Atributos por completar
            // se agrega a la clase el error "md-valid" ó "md-error"
            this[e.mensaje];
            m.mensaje = e.mensaje;
            m.tipo = "danger";
            this.notifyVue(m);
            break;
          case 3: //success
            this.getDatos();
            m.mensaje = e.mensaje;
            m.tipo = "success";
            this.notifyVue(m);
            break;
          case 4: //Information
            m.mensaje = e.mensaje;
            m.tipo = "info";
            this.notifyVue(m);
            break;
        }
      });
    },
  },

  created() {
    console.log("Entro Reporte Investigacion");
    this.fisicaMoralId = this.general.FisicaMoralId;
    console.log("fisicaMoralId", this.fisicaMoralId);
    this.getDatos();
  },
};
</script>

<style lang="scss" scoped>
</style>
