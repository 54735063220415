<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-header class="md-card-header-text md-card-header-primary">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">Listado de Procesos sobre la Solución</h4>
        </md-card-header>
        <md-card-content>
          <md-table
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover"
          >
            <md-table-toolbar>
              

              <md-field>
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style="width: 200px"
                  placeholder="Buscar registro"
                  v-model="searchQuery"
                ></md-input>
              </md-field>
            </md-table-toolbar>

            <!-- FORMATO DE LA TABLA Y PARA LLAMAR INFORMACIÓN -->
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell
                md-label="Usuario"
                md-sort-by="usuarioNomCompleto"
              >{{ item.usuarioNomCompleto }}</md-table-cell>

              <md-table-cell
                md-label="Representación"
                md-sort-by="representacionNombre"
              >{{ item.representacionNombre }}</md-table-cell>

              <md-table-cell md-label="Estatus" md-sort-by="descripcion">{{ item.descripcion }}</md-table-cell>

              <md-table-cell md-label="Fecha" md-sort-by="fecha">{{ item.fecha }}</md-table-cell>

              <md-table-cell
                md-label="Observaciones"
                md-sort-by="observacion"
              >{{ item.observacion }}</md-table-cell>
            </md-table-row>
            <!-- FIN FORMATO DE LA TABLA Y PARA LLAMAR INFORMACIÓN -->
          </md-table>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <slot name="footer">

            <div>
              <md-button
                class="btn-next md-success"
                @click.native="ModalPersonalizado(1)"
              >CAMBIAR ESTATUS</md-button>
            </div>
          </slot>
        </md-card-actions>
      </md-card>
      <modal v-if="classicModal" @close="classicModalHide">
        <template slot="header">
          <h4 class="modal-title">{{ datosModal.titulo }}</h4>
          <md-button
            class="md-simple md-just-icon md-round modal-default-button"
            @click="classicModalHide"
          >
            <md-icon>clear</md-icon>
          </md-button>
        </template>

        <template slot="body">
          <md-field v-if="datosModal.evento == 1">
            <label for="lista">(Seleccionar estatus)</label>
            <md-select v-model="procesoSolucionesId">
              <md-option
                v-for="e in lista"
                :key="e.procesoSolucionesId"
                :value="e.procesoSolucionesId"
              >{{ e.descripcion }}</md-option>
            </md-select>
          </md-field>
          <md-field>
            <label>Motivo del cambio estatus</label>
            <md-input v-model="observacion"></md-input>
          </md-field>
        </template>
        <!-- //BOTÓN DENTRO CUERPO CAMBIAR ESTATU -->
        <template slot="footer">
          <md-button class="md-danger md-simple" @click="classicModalHide">CERRAR</md-button>
          <md-button
            class="md-primary"
            @click.native="postNow(datosModal.evento)"
            :disabled="isActivo"
          >{{ datosModal.boton }}</md-button>
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
import { Pagination, Modal } from "@/components";
import Fuse from "fuse.js";
import Swal from "sweetalert2";
import mensaje from "@/Servicios/ManjeadorErrores/Mensajes";
import store from "@/Store/index";
import servestatus from "@/Servicios/Soluciones/estatus";

export default {
  components: {
    Modal,
  },
  computed: {
    usuario() {
      return store.state.usuario; //COMO SE DECLARE AQUI SE LLAMA EN EL POSTNOW
    },

    SolucionId() {
      return store.state.General.SolucionId;
    },
    general() {
      return store.state.General;
    },
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
  
  },
  data() {
    return {
      currentSort: "solucionesId",
      currentSortOrder: "asc",
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      classicModal: false,
      lista: [],
      fecha: null,
      usuarioNomCompleto: null,
      solucionesId: 0,
      usuariosId: null,
      observacion: "",
      procesoSolucionesId: 0,
      datosModal: {
        titulo: "",
        boton: "",
        evento: 0,
        accion: false,
      },
      Mensajes: {
        tipo: null,
        mensaje: null,
      },
      isActivo: false,
    };
  },

  methods: {
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },

    GetEstatus() {
      servestatus.estatuslista().then((data) => {
        this.lista = data.data;
      });
    },
    postNow(exp) {
      let today = new Date();
      var data = {
        UsuariosSolulucionesId: 0,
        usuariosId: this.usuario.usuariosId,
        solucionesId: this.SolucionId,
        fecha: today,
        observacion: this.observacion,
        ProcesoSolucionesId: this.procesoSolucionesId,
      };
      switch (exp) {
        case 1:
          servestatus
            .post(data)
            .then((r) => {
              this.Procesamos(r.data); //LLAMADA MENSAJES DE CONFIRMACION
            })
            .catch(function (e) {
              mensaje.Error("Soluciones-crud-post", e);
            });
          break;
      }
    },
    getDatos() {
      servestatus
        .getId(this.SolucionId)
        .then((a) => {
          this.tableData = a.data;
          this.fuseSearch = new Fuse(this.tableData, {
            keys: [
              "solucionesId",
              "usuariosId",
              "usuarioNomCompleto",
              "fecha",
              "procesoSolucionesId",
              "descripcion",
              "representacionId",
              "observacion",
            ],
            threshold: 0.3,
          });
        })
        .catch((e) => {
          mensaje.Error("datos Soluciones--all", e);
        });
    },
    classicModalHide() {
      this.Clear();
      this.classicModal = false;
      this.isActivo = false;
    },
    ModalPersonalizado(exp) {
      switch (exp) {
        case 1: //Insertar nuevo Estatus
          this.GetEstatus();
          this.classicModal = true;
          this.datosModal.titulo ="¿Estás seguro de cambiar el estatus de la Solución?";
          this.datosModal.boton = "Guardar";
          this.datosModal.evento = 1;
          break;
      }
    },
    
    Procesamos(data) { //REALIZA EL PROCESO DE LOS MENSAJES DESDE EL CONTROLLER 
      data.forEach((e) => {
        let m = {
          mensaje: "",
          tipo: "",
        };
        switch (e.tipo) {
          case 1: //Error
            m.mensaje = e.mensaje;
            m.tipo = "danger";
            this.notifyVue(m);
            break;
          case 2: //Atributos por completar
            // se agrega a la clase el error "md-valid" ó "md-error"
            this[e.mensaje] = "md-error";
            break;
          case 3: //success
            this.classicModalHide();
            m.mensaje = e.mensaje;
            m.tipo = "success";
            this.notifyVue(m);
            this.getDatos();
            break;
          case 4: //Information
            m.mensaje = e.mensaje;
            m.tipo = "info";
            this.notifyVue(m);
            break;
        }
      });
    },

    notifyVue(Mensajes) {
      this.$notify({
        message: Mensajes.mensaje,
        timeout: 30000,
        icon: "add_alert",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: Mensajes.tipo,
      });
    },
    Clear() {
      this.solucionesId = 0;
      this.usuariosId = null;
      this.usuarioNomCompleto = null;
      this.fecha = null;
      this.descripcion = null;
    },
  },
  created() {
    this.getDatos();
    this.GetEstatus();
  },
  mounted() {},
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      //console.log("this.tableData", this.tableData);
      if (value !== "") {
     
        result = this.fuseSearch.search(this.searchQuery);
        console.log(this.fuseSearch); 
      }
      this.searchedData = result.map((i) => {
        return i.item;
      });
    },
  },
};
</script>

<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 40px;
}
.full-control > .md-list {
  width: 320px;
  max-width: 100%;
  height: 400px;
  display: inline-block;
  overflow: auto;
  border: 1px solid rgba(#000, 0.12);
  vertical-align: top;
}
</style> 