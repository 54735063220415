import { http } from "@/http-commons";

class Estatus {
  getId(id) {
    return http.get(`/UsuariosSolicitud/${id}`);
  }

  post(data) {
    return http.post(`/UsuariosSolicitud`,data);
  }

  put(id,data){
    return http.put(`/UsuariosSolicitud/${id}`,data);
  }
  
  delete(id){
    return http.delete(`/UsuariosSolicitud/${id}`);
  }
  postEstatusSiguiente(id, usuarioId) {
    return http.post(`/UsuariosSolicitud/EstatusSiguiente/${id}/${usuarioId}`);
  }
}
export default new Estatus();