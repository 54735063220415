import { http } from "@/http-commons"; 

class Calendario {
    post(cal) {
        return http.post('/Calendario', cal);
      }
    getUsuarios() {
      return http.get('/Calendario');
    }
    delete(id){
       return http.delete(`/Calendario/${id}`);
    }
    getFiltros(data)
    {
      return http.post(`/Calendario/filtros`,data);
    }
}
export default new Calendario();
