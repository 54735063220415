import { http } from "@/http-commons";

class investigacion {
  all() {
    return http.get('/ReporteInvestigacion');
  }
  getId(id) {
    return http.get(`/ReporteInvestigacion/${id}`);
  }
  delete(id) {
    return http.delete(`/ReporteInvestigacion/${id}`);
  }
  post(data) {
    return http.post('/ReporteInvestigacion', data);
  }
  put(id, data) {
    return http.put(`/ReporteInvestigacion/${id}`, data);
  }
}

export default new investigacion();
