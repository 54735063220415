import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({  
  
  state: {
    token: null,
    General: {
      PolizaId: 0,
      Solicitud: null,
      SolucionId: 0,
      SolicitudId: 0,
      FisicaMoralId: 0,
    },
    Modo: 0,//1.- Nuevo, 2.- Edit, 3.- Update, 4.- Delete, 5.- Visualizar
    usuario: {
      activo: null,
      areaId: null,
      contraEncrypt: null,
      isResponsanle: null,
      representacionId: null,
      usuarioApellidoMaterno: null,
      usuarioApellidoPaterno: null,
      usuarioCelular: null,
      usuarioInmobiliaria: null,
      usuarioNomCompleto: null,
      usuarioNombre: null,
      usuarioPadreId: null,
      usuarioTelefono: null,
      usuariosId: null,
      usurioEmail: null,
      imagen: null,
      dashboard: null
    },
    cantidad: {
      soluciones: 0,
      polizas: 0,
      solicitudes: 0
    },
    Movimientos: {
      DetallePolizas: [],
      Transferencias: [],
      CuentasXPagar: [],
      CuentasXCobrar: []
    },
    Cuenta: {
      Id: 0,
      Nombre: null,
      Saldo: 0,
      entrada: 0,
      salida: 0,
      total: 0,
    },
  }
});

export default store;