import { http } from "@/http-commons";

class estatus {
  getId(id) {
    return http.get(`/usuarioSolucion/Estatus/${id}`);
  }
  estatuslista() {
    return http.get('/Solucion/ListaEstatus');
  }
  post(data) {
    return http.post('/UsuarioSolucion/', data);
  }
  delete(id) {
    return http.delete(`/UsuarioSolucion/${id}`);
  }

}

export default new estatus();