<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">Correos</h4>
        </md-card-header>
        <md-card-content>
          <md-table :value="queriedData" :md-sort.sync="currentSort" :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort" class="paginated-table table-striped table-hover">
            <md-table-toolbar>
              <md-field>
                <label for="pages">Por página</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>

              <md-field>
                <md-input type="search" class="mb-3" clearable style="width: 200px" placeholder="Buscar registro"
                  v-model="searchQuery">
                </md-input>
              </md-field>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }" @click.native="selectEmail(item)">
              <md-table-cell md-label="Remitente" md-sort-by="fromName">{{ item.fromName }}</md-table-cell>
              <md-table-cell md-label="Correo" md-sort-by="fromAddress">{{ item.fromAddress }}</md-table-cell>
              <md-table-cell md-label="Asunto" md-sort-by="subject">{{ item.subject }}</md-table-cell>
              <md-table-cell md-label="Fecha" md-sort-by="date">{{ item.date }}</md-table-cell>
              <md-table-cell md-label="Archivos" md-sort-by="attachments">{{ item.attachments.length }}</md-table-cell>
            </md-table-row>
          </md-table>
          <div class="footer-table md-table">
            <table>
              <caption>Descripción de los datos en el pie de la tabla</caption>
              <tfoot>
                <tr>
                  <th v-for="item in footerTable" :key="item" class="md-table-head">
                    <div class="md-table-head-container md-ripple md-disabled">
                      <div class="md-table-head-label">
                        {{ item }}
                      </div>
                    </div>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <pagination class="pagination-no-border pagination-success" v-model="pagination.currentPage"
            :per-page="pagination.perPage" :total="total">
          </pagination>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>

<script>
import { Pagination, Modal } from "@/components";
import Fuse from "fuse.js";
import mensaje from "@/Servicios/ManjeadorErrores/Mensajes";
import servarea from "@/Servicios/Area/area";

export default {
  props: {
    tableData: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  components: {
    Pagination,
    Modal,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      currentSort: "areaId",
      currentSortOrder: "asc",
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      footerTable: ["Correos"],
      searchQuery: "",
      propsToSearch: ["areaId", "areaDescripcion", "dashboard"],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      classicModal: false,
      areaId: 0,
      areaDescripcion: null,
      dashboard: null,
      datosModal: {
        titulo: "Crear una área",
        boton: "Confirmar",
        evento: 0,
      },
    };
  },
  methods: {
    selectEmail(email) {
      this.$emit('emailSelected', email);
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    getDatos() {
      servarea
        .all()
        .then((a) => {
          this.tableData = a.data;
          // console.log(this.tableData);
          // Fuse search initialization.
          this.fuseSearch = new Fuse(this.tableData, {
            keys: ["areaId"],
            threshold: 0.3,
          });
        })
        .catch((e) => {
          mensaje.Error("usuario-area-all", e);
        });
    },
  },
  created() {
    this.getDatos();
  },
  mounted() { },
  watch: {
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result.map(r => r.item);
    }
  },
};
</script>

<style lang="css" scoped></style>