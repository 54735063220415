<template>
  <div class="md-layout-item md-large-size-100">
    <md-card>
      <md-card-header class="md-card-header-text md-card-header-primary">
        <div class="card-text">
          <h4 class="title">Detalle Solución</h4>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout-item md-size-100 md-medium-size-100">
          <div class="md-layout-item md-size-100 md-medium-size-100">
            <h4>Encabezado</h4>
            <div class="md-layout">
              <div class="md-layout-item md-size-50">
                <md-field>
                  <label>*Poliza Id</label>
                  <md-input v-model="polizaId" type="number" disabled></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-size-50">
                <md-field>
                  <label>*Fecha de Creación</label>
                  <md-input v-model="fechaCreacion" type="text" disabled></md-input>
                </md-field>
              </div>
            </div>
          </div>
          <div class="md-layout-item md-size-100 md-medium-size-100">
            <div class="md-layout">
              <div class="md-layout-item md-size-33">
                <md-field>
                  <label>*Fecha de inicio de la póliza</label>
                  <md-input v-model="fechaContratoI" type="text" disabled></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-size-33">
                <md-field>
                  <label>*Fecha de fin de póliza</label>
                  <md-input v-model="fechaContratoF" type="text" disabled></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-size-33">
                <md-field>
                  <label>*Tipo de Póliza:</label>
                  <md-input v-model="tipoPoliza" type="text" disabled></md-input>
                </md-field>
              </div>
            </div>
          </div>
          <div class="md-layout-item md-size-100 md-medium-size-100">
            <h4>Arrendador</h4>
            <div class="md-layout">
              <div class="md-layout-item md-size-30">
                <md-field>
                  <label>*Nombre (s)</label>
                  <md-input v-model="nombrePro" type="text" disabled></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-size-25">
                <md-field>
                  <label>*Apellido Paterno</label>
                  <md-input v-model="apellidoPatPro" type="text" disabled></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-size-25">
                <md-field>
                  <label>*Apellido Materno</label>
                  <md-input v-model="apellidoMatPro" type="text" :disabled="modo == 1 ? true : false"></md-input>
                </md-field>
              </div>
            </div>
          </div>
          <div class="md-layout-item md-size-100 md-medium-size-100">
            <div class="md-layout">
              <div class="md-layout-item md-size-33">
                <md-field>
                  <label>*Celular</label>
                  <md-input v-model="celularProp" type="text" disabled></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-size-33">
                <md-field>
                  <label>*Email</label>
                  <md-input v-model="emailProp" type="text" disabled></md-input>
                </md-field>
              </div>
            </div>
          </div>
          <div class="md-layout-item md-size-100 md-medium-size-100">
            <h4>Dirección del inmueble</h4>
            <div class="md-layout">
              <div class="md-layout-item md-size-70">
                <md-field>
                  <label>*Calle o Avenida</label>
                  <md-input v-model="direccionInmueble" type="text" disabled></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-size-30">
                <md-field>
                  <label>*Colonia</label>
                  <md-input v-model="coloniaInmueble" type="text" disabled></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-size-30">
                <md-field>
                  <label>*Alcaldia o Municipio</label>
                  <md-input v-model="alcaldiaMunicipioInmueble" type="text" disabled></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-size-30">
                <md-field>
                  <label>*Código Postal</label>
                  <md-input v-model="cpinmueble" type="text" disabled></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-size-30">
                <md-field>
                  <label>*Estado</label>
                  <md-input v-model="estadoId" type="text" disabled></md-input>
                </md-field>
              </div>
            </div>
          </div>
          <div class="md-layout-item md-size-100 md-medium-size-100">
            <h4>Datos del Arrendatario</h4>
            <div class="md-layout">
              <div class="md-layout-item md-size-30">
                <md-field>
                  <label>+Nombre (s)</label>
                  <md-input v-model="nombreArren" type="text" disabled></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-size-25">
                <md-field>
                  <label>*Apellido Paterno</label>
                  <md-input v-model="apellidoPatArren" type="text" disabled></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-size-25">
                <md-field>
                  <label>*Apellido Materno</label>
                  <md-input v-model="apellidoMatArren" type="text" disabled></md-input>
                </md-field>
              </div>
            </div>
          </div>
          <div class="md-layout-item md-size-100 md-medium-size-100">
            <div class="md-layout">
              <div class="md-layout-item md-size-33">
                <md-field>
                  <label>*Celular</label>
                  <md-input v-model="celularArrem" type="text" disabled></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-size-33">
                <md-field>
                  <label>*Email</label>
                  <md-input v-model="emailArren" type="text" disabled></md-input>
                </md-field>
              </div>
            </div>
          </div>
          <div class="md-layout-item md-size-100 md-medium-size-100">
            <h4>Datos del Fiador</h4>
            <div class="md-layout">
              <div class="md-layout-item md-size-30">
                <md-field>
                  <label>Nombre (s)</label>
                  <md-input v-model="nombreFiador" type="text" disabled></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-size-25">
                <md-field>
                  <label>Apellido Paterno</label>
                  <md-input v-model="apellidoPatFiador" type="text" disabled></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-size-25">
                <md-field>
                  <label>Apellido Materno</label>
                  <md-input v-model="apellidoMatFiador" type="text" disabled></md-input>
                </md-field>
              </div>
            </div>
          </div>
          <div class="md-layout-item md-size-100 md-medium-size-100">
            <div class="md-layout">
              <div class="md-layout-item md-size-33">
                <md-field>
                  <label>Celular</label>
                  <md-input v-model="celularFiador" type="text" disabled></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-size-33">
                <md-field>
                  <label>Email</label>
                  <md-input v-model="emailFiador" type="text" disabled></md-input>
                </md-field>
              </div>
            </div>
          </div>

          <div class="md-layout-item md-size-100 md-medium-size-100">
            <h4>Datos de la solución</h4>
          </div>
          <div class="md-layout-item md-size-100 md-medium-size-100">
            <div class="md-layout">
              <div class="md-layout-item md-size-100">
                <md-field>
                  <label>*Origen del Problema</label>
                  <md-input v-model="descripcionProblema" type="text" :disabled="modo == 1 ? true : false" required></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-size-33">
                <md-field>
                  <label>*Fecha de la creación Solución</label>
                  <md-input v-model="fechaArrendatario" type="date" :disabled="modo == 1 ? true : false" required></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-size-100">
                <md-field :class="messageClass">
                  <label>*Argumento</label>
                  <md-input v-model="argumenta" type="text" :disabled="modo == 1 ? true : false" required />
                  <span class="md-error">El campo es requerido</span>
                </md-field>
              </div>
            </div>
          </div>
        </div>
      </md-card-content>
      <md-card-actions md-alignment="space-between">
        <slot name="footer">
          <md-button class="md-default" @click="Regresamos()">Cancelar</md-button>
          <md-button class="md-primary" @click="Guardamos()" :disabled="modo == 1 ? true : false">Generar</md-button>
        </slot>
      </md-card-actions>
    </md-card>
  </div>
</template>

<script>
import store from "@/Store/index";
import servSolucion from "@/Servicios/Soluciones/soluciones";
import mensaje from "@/Servicios/ManjeadorErrores/Mensajes";
export default {
  components: {
  },
  computed: {
    SolucionId() {
      return store.state.General.SolucionId;
    },
    GetPolizaId() {
      return store.state.General.PolizaId;
    },
    UsuarioId() {
      return store.state.usuario.usuariosId;
    },
    general() {
      return store.state.General;
    },
    modoPantalla() {
      return store.state.Modo;
    },
  },
  data() {
    return {
      messageClass: "",
      isDisabled: false,
      modo: 0,
      hasMessages: false,
      polizaId: 0,
      fechaCreacion: "",
      apellidoMatFiador: "",
      nombreArren: "",
      nombrePro: "",
      fechaContratoI: "",
      fechaContratoF: "",
      tipoPoliza: 0,
      apellidoPatPro: "",
      apellidoMatPro: "",
      celularProp: "",
      emailProp: "",
      direccionInmueble: "",
      coloniaInmueble: "",
      alcaldiaMunicipioInmueble: "",
      cpinmueble: "",
      estadoId: 0,
      apellidoPatArren: "",
      apellidoMatArren: "",
      celularArrem: "",
      emailArren: "",
      nombreFiador: "",
      apellidoPatFiador: "",
      celularFiador: "",
      emailFiador: "",
      descripcionProblema: "",
      fechaArrendatario: "",
      argumenta: "",
      classicModal: false,
      datosModal: {
        titulo: "",
        boton: "",
        evento: 0,
      },
      blockButton: false,
    };

  },
  methods: {
    Regresamos() {
      this.$router.push({ name: "soluciones" });
    },
    Guardamos() {
      this.isDisabled = true;
      var data = {
        SolucionesId: this.solucionId,
        apellidoMatFiador: this.apellidoMatFiador,
        nombreArren: this.nombreArren,
        nombrePro: this.nombrePro,
        fechaContratoI: this.fechaContratoI,
        fechaContratoF: this.fechaContratoF,
        tipoPoliza: this.tipoPoliza,
        apellidoPatPro: this.apellidoPatPro,
        apellidoMatPro: this.apellidoMatPro,
        celularProp: this.celularProp,
        emailProp: this.emailProp,
        direccionInmueble: this.direccionInmueble,
        coloniaInmueble: this.coloniaInmueble,
        alcaldiaMunicipioInmueble: this.alcaldiaMunicipioInmueble,
        cpinmueble: this.cpinmueble,
        estadoId: this.estadoId,
        apellidoPatArren: this.apellidoPatArren,
        apellidoMatArren: this.apellidoMatArren,
        celularArrem: this.celularArrem,
        emailArren: this.emailArren,
        nombreFiador: this.nombreFiador,
        apellidoPatFiador: this.apellidoPatFiador,
        apellidoMatFiador: this.apellidoMatFiador,
        celularFiador: this.celularFiador,
        emailFiador: this.emailFiador,
        descripcionProblema: this.descripcionProblema,
        fechaArrendatario: this.fechaArrendatario,
        argumenta: this.argumenta,
        polizaId: this.polizaId,
        usuarioId: this.UsuarioId,
      };
      servSolucion
        .post(data)
        .then((r) => {
          this.procesamosResponse(r.data);
          this.isDisabled = false;
        })
        .catch(function (e) {
          mensaje.Error("Actualizar-crud-put", e);
          this.isDisabled = false;
        });
    },
    procesamosResponse(data) {
      data.forEach((e) => {
        let m = {
          mensaje: "",
          tipo: "",
        };
        switch (e.tipo) {
          case 5: //Atributos por completar
            // se agrega a la clase el error "md-valid" ó "md-error"
            console.log("Error", e.mensaje);
            store.state.General.SolucionId = parseInt(e.mensaje);
            store.state.General.PolizaId = this.polizaId;
            store.state.Modo = 1;
            this.$router.push({ name: "tabssoluciones" });
            break;
          case 1: //Error
            m.mensaje = e.mensaje;
            m.tipo = "danger";
            this.notifyVue(m);
            break;
          case 3: //success
            m.mensaje = e.mensaje;
            m.tipo = "success";
            this.notifyVue(m);
            break;
          case 4: //Information
            m.mensaje = e.mensaje;
            m.tipo = "info";
            this.notifyVue(m);
            break;
        }
      });
    },
    getData() {
      if (this.SolucionId > 0) {
        servSolucion
          .getId(this.SolucionId)
          .then((r) =>
            this.dataSolucione(r.data),
            this.modo = this.modoPantalla,
          )
          .catch((e) => console.log("Error get data Solucion", e));
      }
      else if (this.GetPolizaId > 0) {
        servSolucion
          .getPolizaId(this.GetPolizaId)
          .then((r) =>
            this.dataSolucione(r.data),
            this.modo = this.modoPantalla,
          )
          .catch((e) => console.log("Error get data Solucion", e));
      }
    },
    dataSolucione(r) {
      this.polizaId = this.GetPolizaId == 0 ? r.polizaId : this.GetPolizaId;
      this.fechaCreacion = r.fechaCreacion;
      this.apellidoMatFiador = r.apellidoMatFiador;
      this.nombreArren = r.nombreArren;
      this.nombrePro = r.nombrePro;
      this.fechaContratoI = r.fechaContratoI;
      this.fechaContratoF = r.fechaContratoF;
      this.tipoPoliza = r.tipoPoliza;
      this.apellidoPatPro = r.apellidoPatPro;
      this.apellidoMatPro = r.apellidoMatPro;
      this.celularProp = r.celularProp;
      this.emailProp = r.emailProp;
      this.direccionInmueble = r.direccionInmueble;
      this.coloniaInmueble = r.coloniaInmueble;
      this.alcaldiaMunicipioInmueble = r.alcaldiaMunicipioInmueble;
      this.cpinmueble = r.cpinmueble;
      this.estadoId = r.estadoId;
      this.apellidoPatArren = r.apellidoPatArren;
      this.apellidoMatArren = r.apellidoMatArren;
      this.celularArrem = r.celularArrem;
      this.emailArren = r.emailArren;
      this.nombreFiador = r.nombreFiador;
      this.apellidoPatFiador = r.apellidoPatFiador;
      this.apellidoMatFiador = r.apellidoMatFiador;
      this.celularFiador = r.celularFiador;
      this.emailFiador = r.emailFiador;
      this.descripcionProblema = r.descripcionProblema;
      this.fechaArrendatario = r.fechaArrendatario;
      this.argumenta = r.argumenta;
    },
    notifyVue(Mensajes) {
      this.$notify({
        message: Mensajes.mensaje,
        timeout: 30000,
        icon: "add_alert",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: Mensajes.tipo,
      });
    },

  },
  created() {
    this.getData();
    console.log("ID:", this.general.SolucionId);
  },
};
</script>

<style></style>