import { http } from "@/http-commons";

class TipoParentesco {
  all() {
    return http.get('/TipoParentesco');
  }
  get(id) {
    return http.get(`/TipoParentesco/${id}`);
  }
  post(data) {
    return http.post('/TipoParentesco', data);
  }
  put(id, data) {
    return http.put(`/TipoParentesco/${id}`, data);
  }
  delete(id) {
    return http.delete(`/TipoParentesco/${id}`);
  }
}

export default new TipoParentesco();