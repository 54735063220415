import { http } from "@/http-commons";

class tipodocumento {
  all() {
    return http.get('/tipodocumento');
  }
  get(id) {
    return http.get(`/tipodocumento/${id}`);
  }
  post(tipodocumento) {
    return http.post('/tipodocumento', tipodocumento);
  }
  put(id, tipodocumento) {
    return http.put(`/tipodocumento/${id}`, tipodocumento);
  }
  delete(id) {
    return http.delete(`/tipodocumento/${id}`);
  }
}

export default new tipodocumento();