<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-primary">
          <div class="card-icon">
            <md-icon>person</md-icon>
          </div>
          <h4 class="title">Arrendatarios</h4>
        </md-card-header>
        <md-card-content>
          <md-table :value="queriedData" :md-sort.sync="currentSort" :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort" class="paginated-table table-striped table-hover">
            <md-table-toolbar>
              <md-field>
                <label for="pages">Por página</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>

              <md-field>
                <md-input type="search" class="mb-3" clearable style="width: 200px" placeholder="Buscar registro"
                  v-model="searchQuery">
                </md-input>
              </md-field>
              <md-field>
                <md-button class="md-primary" @click="ModalPersonalizado(1)" >Nuevo</md-button>
              </md-field>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Id" md-sort-by="arrendatarioId">{{
                item.arrendatarioId
              }}</md-table-cell>
              <md-table-cell md-label="Nombre" md-sort-by="nombre">{{
                item.nombre
              }}</md-table-cell>
              <md-table-cell md-label="Apellido Paterno" md-sort-by="apePaterno">{{ item.apePaterno }}</md-table-cell>
              <md-table-cell md-label="Apellido Materno" md-sort-by="apeMaterno">{{
                item.apeMaterno
              }}</md-table-cell>
              <md-table-cell md-label="R.F.C." md-sort-by="rfc">{{
                item.rfc
              }}</md-table-cell>
              <md-table-cell md-label="Régimen Fiscal" md-sort-by="tipoIdentificacion"
              v-if="item.tipoRegimenFiscal == 1">Persona Fisica</md-table-cell>
              <md-table-cell md-label="Régimen Fiscal" md-sort-by="tipoIdentificacion"
              v-if="item.tipoRegimenFiscal == 2">Persona Moral</md-table-cell>
              <md-table-cell md-label="Acciones" v-if="modo != 5">
                <md-button class="md-just-icon md-warning md-simple"  @click.native="ModalPersonalizado(2, item)" >
                  <md-icon>edit</md-icon>
                </md-button>
                <md-button class="md-just-icon md-danger md-simple"  @click.native="ModalPersonalizado(3, item)">
                  <md-icon>close</md-icon>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <pagination class="pagination-no-border pagination-success" v-model="pagination.currentPage"
            :per-page="pagination.perPage" :total="total">
          </pagination>
        </md-card-actions>
      </md-card>
      <modal v-if="classicModal" @close="classicModalHide">


        <template slot="header">
          <md-field>
                <tabs
                  :tab-name="[
                    'Datos Personales',
                    'Datos Extras',
                  ]"
                  color-button="primary"
                  flex-row
                >
                  <h4 class="title" slot="header-title">Crear Arrendatario:</h4>
                  <template slot="tab-pane-1">
                <div class="md-layout-item md-size-100 md-medium-size-100">
                  <div class="md-layout">
                    <div class="md-layout-item md-size-50 md-medium-size-100">
                     
                     <md-field>
                       <label >Tipo de Regimen</label>
                       <md-select v-model="tipoRegimen" name="regimen" >
                       <md-option value="1">Fisica</md-option>
                       <md-option value="2">Moral</md-option>
                   </md-select>
                     </md-field>                 
                   </div>
                  </div>
                </div>
                <div class="md-layout-item md-size-100 md-medium-size-100" v-if="tipoRegimen == 1">
                  <div class="md-layout">
                    <div class="md-layout-item md-size-25 md-medium-size-100">
                      <md-field :class="messageClass">
                        <label>*Nombre(s)</label>
                        <md-input v-model="nombre" ></md-input>
                        <span class="md-error">Campo Requerido</span>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-25 md-medium-size-100">
                      <md-field :class="messageClass">
                        <label>*Apellido Paterno</label>
                        <md-input v-model="apePaterno" ></md-input>
                        <span class="md-error">Campo Requerido</span>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-25 md-medium-size-100">
                      <md-field :class="messageClass">
                        <label>*Apellido Materno</label>
                        <md-input v-model="apeMaterno" ></md-input>
                        <span class="md-error">Campo Requerido</span>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-25 md-medium-size-100">
                      <md-field>
                        <label>*RFC</label>
                        <md-input v-model="rfc" ></md-input>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-25 md-medium-size-100">
                      <md-field>
                        <label>Nacionalidad</label>
                        <md-input v-model="nacionalidad" ></md-input>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-25 md-medium-size-100">
                      <md-field>
                        <label>Condición Migratoria</label>
                        <md-input v-model="condMigratoria" ></md-input>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-25 md-medium-size-100">
                      <md-field>
                        <label>Estado Civil</label>
                        <md-input v-model="estadoCivil" ></md-input>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-25 md-medium-size-100">
                      <md-field>
                        <label>Convenio</label>
                        <md-input v-model="convenioEc" ></md-input>
                      </md-field>
                    </div>
                  </div>
              </div>
                    <div class="md-layout-item md-size-100 md-medium-size-100" v-if="tipoRegimen == 2">
                     <div class="md-layout">
                    <div class="md-layout-item md-size-25 md-medium-size-100">
                      <md-field :class="messageClass">
                        <label>*Razon Social</label>
                        <md-input v-model="razon" ></md-input>
                        <span class="md-error">Campo Requerido</span>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-25 md-medium-size-100">
                      <md-field :class="messageClass">
                        <label>*RFC</label>
                        <md-input v-model="rfc" ></md-input>
                        <span class="md-error">Campo Requerido</span>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-25 md-medium-size-100">
                      <md-field :class="messageClass">
                        <label>*Nombres(s) Representante legal</label>
                        <md-input v-model="nombre" ></md-input>
                        <span class="md-error">Campo Requerido</span>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-25 md-medium-size-100">
                      <md-field :class="messageClass">
                        <label>*Apellido Materno Representante Legal</label>
                        <md-input v-model="apeMaterno" ></md-input>
                        <span class="md-error">Campo Requerido</span>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-25 md-medium-size-100">
                      <md-field :class="messageClass">
                        <label>*Apellido Paterno Representante Legal</label>
                        <md-input v-model="apePaterno" ></md-input>
                        <span class="md-error">Campo Requerido</span>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-33 md-medium-size-100">
                      <md-field :class="messageClass">
                        <label>Telefono Empresa</label>
                        <md-input v-model="telefonoTrabajo" ></md-input>
                        <span class="md-error">Campo Requerido</span>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-33 md-medium-size-100">
                      <md-field >
                        <label>Giro</label>
                        <md-input v-model="giroTrabajo" ></md-input>
                      </md-field>
                    </div>
                  </div>
                </div>
                <div class="md-layout-item md-size-100 md-medium-size-100" >
                  <div class="md-layout">
                    <div class="md-layout-item md-size-50 md-medium-size-100">
                      <md-field>
                        <label>Tipo de Identificación</label>
                        <md-input v-model="tipoIdentificacion" ></md-input>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-50 md-medium-size-100">
                      <md-field>
                        <label>Número de Identificación</label>
                        <md-input v-model="numeroIdentificacion" ></md-input>
                      </md-field>
                    </div>
                  </div>
                </div>
                <div class="md-layout-item md-size-100 md-medium-size-100" v-if="tipoRegimen == 1">
                  <div class="md-layout">
                    <div class="md-layout-item md-size-50 md-medium-size-100">
                      <md-field :class="messageClass">
                        <label>*Domicilio</label>
                        <md-input v-model="domicilio" ></md-input>
                        <span class="md-error">Campo Requerido</span>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-50 md-medium-size-100">
                      <md-field :class="messageClass">
                        <label>*Colonia</label>
                        <md-input v-model="colonia" ></md-input>
                        <span class="md-error">Campo Requerido</span>
                      </md-field>
                    </div>
                  </div>
                </div>
                <div class="md-layout-item md-size-100 md-medium-size-100" v-if="tipoRegimen == 1">
                  <div class="md-layout">
                    <div class="md-layout-item md-size-33 md-medium-size-100">
                      <md-field :class="messageClass">
                        <label>*Alcaldia/Municipio</label>
                        <md-input v-model="delegacionMunicipio" ></md-input>
                        <span class="md-error">Campo Requerido</span>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-33 md-medium-size-100">
                      <md-field :class="messageClass">
                        <label>*Estado</label>
                        <md-input v-model="estado" ></md-input>
                        <span class="md-error">Campo Requerido</span>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-33 md-medium-size-100">
                      <md-field :class="messageClass">
                        <label>*Codigo Postal</label>
                        <md-input v-model="codigoPostal" ></md-input>
                        <span class="md-error">Campo Requerido</span>
                      </md-field>
                    </div>
                  </div>
                </div>
                <div class="md-layout-item md-size-100 md-medium-size-100" v-if="tipoRegimen == 2">
                  <div class="md-layout">
                    <div class="md-layout-item md-size-50 md-medium-size-100">
                      <md-field :class="messageClass">
                        <label>*Domicilio Empresa</label>
                        <md-input v-model="domicilioTrabajo" ></md-input>
                        <span class="md-error">Campo Requerido</span>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-50 md-medium-size-100">
                      <md-field :class="messageClass">
                        <label>*Colonia Empresa</label>
                        <md-input v-model="coloniaTrabajo" ></md-input>
                        <span class="md-error">Campo Requerido</span>
                      </md-field>
                    </div>
                  </div>
                </div>
                <div class="md-layout-item md-size-100 md-medium-size-100" v-if="tipoRegimen == 2">
                  <div class="md-layout">
                    <div class="md-layout-item md-size-33 md-medium-size-100">
                      <md-field :class="messageClass">
                        <label>*Alcaldía / Municipio</label>
                        <md-input v-model="delegMuniTrabajo" ></md-input>
                        <span class="md-error">Campo Requerido</span>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-33 md-medium-size-100">
                      <md-field :class="messageClass">
                        <label>*Código Postal</label>
                        <md-input v-model="codigoPostalTrabajo" ></md-input>
                        <span class="md-error">Campo Requerido</span>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-33 md-medium-size-100">
                      <md-field :class="messageClass">
                        <label>*Estado</label>
                        <md-input v-model="estadoTrabajo" ></md-input>
                        <span class="md-error">Campo Requerido</span>
                      </md-field>
                    </div>
                  </div>
                </div>
                <div class="md-layout-item md-size-100 md-medium-size-100" v-if="tipoRegimen == 1">
                  <div class="md-layout">
                    <div class="md-layout-item md-size-25 md-medium-size-100">
                      <md-field :class="messageClass">
                        <label>*Teléfono</label>
                        <md-input v-model="telefono" ></md-input>
                        <span class="md-error">Campo Requerido</span>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-25 md-medium-size-100">
                      <md-field :class="messageClass">
                        <label>*Celular</label>
                        <md-input v-model="celular" ></md-input>
                        <span class="md-error">Campo Requerido</span>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-25 md-medium-size-100">
                      <md-field :class="messageClass">
                        <label>*Email</label>
                        <md-input v-model="email"  type="email"></md-input>
                        <span class="md-error">Campo Requerido</span>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-25 md-medium-size-100">
                      <md-field>
                        <label>Profesión</label>
                        <md-input v-model="profesion" ></md-input>
                      </md-field>
                    </div>
                  </div>
                </div>
                <div class="md-layout-item md-size-100 md-medium-size-100" v-if="tipoRegimen == 2">
                 <div class="md-layout">
                   <div class="md-layout-item md-size-25 md-medium-size-100">
                      <md-field>
                        <label>Nacionalidad</label>
                        <md-input v-model="nacionalidad" ></md-input>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-25 md-medium-size-100">
                      <md-field>
                        <label>Condición Migratoria</label>
                        <md-input v-model="condMigratoria" ></md-input>
                      </md-field>
                    </div>
                </div>
              </div>
              </template>
              <template slot="tab-pane-2">
                <div class="md-layout-item md-size-100 md-medium-size-100" v-if="tipoRegimen == 2">
                  <div class="md-layout">
                    <div class="md-layout-item md-size-25 md-medium-size-100">
                      <md-field :class="messageClass">
                        <label>*Domicilio del Rep.Legal</label>
                        <md-input v-model="domicilioRep" ></md-input>
                        <span class="md-error">Campo Requerido</span>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-25 md-medium-size-100">
                      <md-field :class="messageClass">
                        <label>*Colonia Rep.Legal</label>
                        <md-input v-model="coloniaRep" ></md-input>
                        <span class="md-error">Campo Requerido</span>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-25 md-medium-size-100">
                      <md-field :class="messageClass">
                        <label>*Alcaldía/Municipio</label>
                        <md-input v-model="deleMuni" 
                        ></md-input>
                          <span class="md-error">Campo Requerido</span>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-25 md-medium-size-100">
                      <md-field :class="messageClass">
                        <label>*Estado</label>
                        <md-input v-model="estadoRep"></md-input>
                        <span class="md-error">Campo Requerido</span>
                      </md-field>
                    </div>
                  </div>
                </div>
                <div class="md-layout-item md-size-100 md-medium-size-100" v-if="tipoRegimen == 1">
                  <div class="md-layout">
                    <div class="md-layout-item md-size-25 md-medium-size-100">
                      <md-field>
                        <label>Ingreso Mensual</label>
                        <md-input v-model="ingresoMensual"></md-input>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-25 md-medium-size-100">
                      <md-field>
                        <label>Empresa donde trabaja</label>
                        <md-input v-model="trabajo" ></md-input>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-25 md-medium-size-100">
                      <md-field>
                        <label>Antiguedad</label>
                        <md-input v-model="antiguedad"  type="email"></md-input>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-25 md-medium-size-100">
                      <md-field>
                        <label>Puesto</label>
                        <md-input v-model="puesto" ></md-input>
                      </md-field>
                    </div>
                  </div>
                </div>
                <div class="md-layout-item md-size-100 md-medium-size-100" v-if="tipoRegimen == 1">
                  <div class="md-layout">
                    <div class="md-layout-item md-size-25 md-medium-size-100">
                      <md-field>
                        <label>Teléfono</label>
                        <md-input v-model="telefonoTrabajo" ></md-input>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-25 md-medium-size-100">
                      <md-field>
                        <label>Horario</label>
                        <md-input v-model="horario" ></md-input>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-25 md-medium-size-100">
                      <md-field>
                        <label>Giro</label>
                        <md-input v-model="giroTrabajo"  type="email"></md-input>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-25 md-medium-size-100">
                      <md-field>
                        <label>Sitio Web</label>
                        <md-input v-model="webTrabajo" ></md-input>
                      </md-field>
                    </div>
                  </div>
                </div>
                <div class="md-layout-item md-size-100 md-medium-size-100" v-if="tipoRegimen == 1">
                  <div class="md-layout">
                    <div class="md-layout-item md-size-25 md-medium-size-100">
                      <md-field>
                        <label>Domicilio del Trabajo</label>
                        <md-input v-model="domicilioTrabajo" ></md-input>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-25 md-medium-size-100">
                      <md-field>
                        <label>Colonia</label>
                        <md-input v-model="coloniaTrabajo" ></md-input>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-25 md-medium-size-100">
                      <md-field>
                        <label>Alcaldía/Municipio</label>
                        <md-input v-model="delegMuniTrabajo" 
                          type="email"></md-input>
                      </md-field>
                    </div>
                    
                    <div class="md-layout-item md-size-25 md-medium-size-100">
                      <md-field>
                        <label>Estado</label>
                        <md-input v-model="estadoTrabajo" ></md-input>
                      </md-field>
                    </div>
                  </div>
                </div>
                <div class="md-layout-item md-size-100 md-medium-size-100" v-if="tipoRegimen == 2">
                  <div class="md-layout">
                    <div class="md-layout-item md-size-25 md-medium-size-100">
                      <md-field :class="messageClass">
                        <label>*Código Postal</label>
                        <md-input v-model="codigoPostal"></md-input>
                        <span class="md-error">Campo Requerido</span>
                      </md-field>
                    </div>
                   <div class="md-layout-item md-size-25 md-medium-size-100">
                      <md-field :class="messageClass">
                        <label>*Celular</label>
                        <md-input v-model="telRep" ></md-input>
                        <span class="md-error">Campo Requerido</span>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-50 md-medium-size-100">
                      <md-field :class="messageClass">
                        <label>*Email Rep.</label>
                        <md-input v-model="emailRep" type="email"></md-input>
                        <span class="md-error">Campo Requerido</span>
                      </md-field>
                    </div>
                  </div>
                </div>
                <div class="md-layout-item md-size-100 md-medium-size-100" v-if="tipoRegimen == 2">
                  <div class="md-layout">
                    <div class="md-layout-item md-size-25 md-medium-size-100">
                      <md-field>
                        <label>Jefe</label>
                        <md-input v-model="jefeTrabajo" ></md-input>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-25 md-medium-size-100">
                      <md-field>
                        <label>Puesto</label>
                        <md-input v-model="puestoJefe" ></md-input>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-25 md-medium-size-100">
                      <md-field>
                        <label>Email Jefe</label>
                        <md-input v-model="emailJefe" ></md-input>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-25 md-medium-size-100">
                      <md-field>
                        <label>Celular</label>
                        <md-input v-model="celular" ></md-input>
                      </md-field>
                    </div>
                  </div>
                </div>
                <div class="md-layout-item md-size-100 md-medium-size-100" v-if="tipoRegimen == 1">
                  <div class="md-layout">
                    <div class="md-layout-item md-size-33 md-medium-size-100">
                        <md-field >
                          <label>Jefe</label>
                          <md-input v-model="jefetrabajo" ></md-input>
                        </md-field>
                      </div>
                      <div class="md-layout-item md-size-33 md-medium-size-100">
                        <md-field >
                          <label>Puesto</label>
                          <md-input v-model="puestoJefe"  ></md-input>
                        </md-field>
                      </div>
                      <div class="md-layout-item md-size-33 md-medium-size-100">
                        <md-field>
                          <label>Email Jefe</label>
                          <md-input v-model="emailJefe" ></md-input>
                        </md-field>
                      </div>
                                        
                  </div>
                </div>
                <div class="md-layout-item md-size-100 md-medium-size-100" v-if="tipoRegimen == 1">
                  <div class="md-layout">
                      <div class="md-layout-item md-size-50 md-medium-size-100">
                        <md-checkbox v-model="factura" >¿Requiere de comprobante
                          fiscal para el
                          arrendamiento?</md-checkbox>
                      </div>                   
                  </div>
                </div>
                <div class="md-layout-item md-size-100 md-medium-size-100" v-if="tipoRegimen == 2">
                  <div class="md-layout">
                    <div class="md-layout-item md-size-25 md-medium-size-100">
                      <md-field>
                        <label>Horario de la Empresa</label>
                        <md-input v-model="horario" ></md-input>
                      </md-field>
                    </div>
                      <div class="md-layout-item md-size-25 md-medium-size-100">
                        <md-field >
                          <label>Pagina web Empresa</label>
                          <md-input v-model="webTrabajo"  type="email"></md-input>
                        </md-field>
                      </div>
                      <div class="md-layout-item md-size-25 md-medium-size-100">
                        <md-field >
                          <label>Ingreso Mensual de la Empresa</label>
                          <md-input v-model="ingresoMensual"  type="email"></md-input>
                        </md-field>
                      </div>
                      <div class="md-layout-item md-size-25 md-medium-size-100">
                        <md-field >
                          <label>Sindicato Afiliado</label>
                          <md-input v-model="sindicato"  type="email"></md-input>
                        </md-field>
                      </div>
                   </div>
                </div>
                <div class="md-layout-item md-size-100 md-medium-size-100" v-if="tipoRegimen == 2">
                  <div class="md-layout">
                      <div class="md-layout-item md-size-50 md-medium-size-100">
                        <md-field :class="messageClass">
                          <label>*Numero RPP Constitutiva</label>
                          <md-input v-model="actaConstitutiva"  type="email"></md-input>
                          <span class="md-error">Campo Requerido</span>
                        </md-field>
                      </div>
                     
                      <div class="md-layout-item md-size-50 md-medium-size-100">
                        <md-field :class="messageClass">
                          <label>*Numero de Escritura del Poder</label>
                          <md-input v-model="numeroPoder"  type="email"></md-input>
                          <span class="md-error">Campo Requerido</span>
                        </md-field>
                      </div>
                   </div>
                </div>
              </template>
              </tabs>
          </md-field>
        </template>
        <template slot="footer">
          <md-button class="md-danger md-simple" @click="classicModalHide">Cerrar</md-button>
          <md-button class="md-primary" @click.native="postNow(datosModal.evento)">{{ datosModal.boton }}</md-button>
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
import { Pagination, Modal, Tabs, Collapse } from "@/components";
import Fuse from "fuse.js";
import Swal from "sweetalert2";
import mensaje from "../../Servicios/ManjeadorErrores/Mensajes";
import servArrendatarios from "../../Servicios/Operacion/arrendatarios.js";
import store from "../../Store/index";

export default {
  components: {
    Pagination,
    Tabs,
    Modal,
  },
  computed: {
    usuario() {
      return store.state.usuario;
    },
    general() {
      return store.state.General;
    },
    modo() {
      return store.state.Modo;
    },
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
    messageClass() {
        return {
          'md-invalid': this.isBandera
        }
      },
  },
  data() {
    return {
      currentSort: "arrendatarioId",
      currentSortOrder: "asc",
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      footerTable: ["Id", "Descripción", "Dashboard", "Acciones"],
      searchQuery: "",
      propsToSearch: ["arrendatarioId", "nombre", "apePaterno", "apeMaterno", "rfc",
        "nacionalidad", "condMigratoria", "estadoCivil", "convenioEc", "tipoIdentificacion",
        "numeroIdentificacion", "domicilio", "colonia", "delegacionMunicipio", "codigoPostal",
        "estado", "telefono", "celular", "email", "profesion", "ingresoMensual", "trabajo",
        "antiguedad", "puesto", "telefonoTrabajo", "horario", "giroTrabajo", "webTrabajo",
        "domicilioTrabajo", "coloniaTrabajo", "delegMuniTrabajo", "codigoPostalTrabajo",
        "estadoTrabajo", "jefeTrabajo", "puestoJefe", "emailJefe", "factura", "fisicaMoralId"],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      classicModal: false,
      tipoRegimen:0,
      arrendatarioId: 0,
      nombre: null,
      apePaterno: null,
      apeMaterno: null,
      rfc: null,
      nacionalidad: null,
      condMigratoria: null,
      estadoCivil: null,
      convenioEc: null,
      tipoIdentificacion: null,
      numeroIdentificacion: null,
      domicilio: null,
      colonia: null,
      delegacionMunicipio: null,
      codigoPostal: null,
      estado: null,
      telefono: null,
      celular: null,
      email: null,
      profesion: null,
      ingresoMensual: 0.00,
      trabajo: null,
      antiguedad: null,
      puesto: null,
      telefonoTrabajo: null,
      horario: null,
      giroTrabajo: null,
      webTrabajo: null,
      domicilioTrabajo: null,
      coloniaTrabajo: null,
      delegMuniTrabajo: null,
      codigoPostalTrabajo: null,
      estadoTrabajo: null,
      jefeTrabajo: null,
      puestoJefe: null,
      emailJefe: null,
      factura: false,
      FisicaMoralId:0,
      //Moral
      razon:null,
      domicilioRep: null,
      coloniaRep:null,
      estadoRep:null,
      emailRep:null,
      telRep:null,
      actaConstitutiva:null,
      numeroPoder:null,
      deleMuni:null,
      sindicato:null,
      isBandera:false,
      datosModal: {
        titulo: "Crear arrendatario",
        boton: "Confirmar",
        evento: 0,
      },
    };
  },
  methods: {
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    postNow(exp) {
      var data = {
        arrendatarioId: this.arrendatarioId,
        nombre: this.nombre,
        ApePaterno: this.apePaterno,
        apeMaterno: this.apeMaterno,
        rfc: this.rfc,
        nacionalidad: this.nacionalidad,
        condMigratoria: this.condMigratoria,
        estadoCivil: this.estadoCivil,
        convenioEc: this.convenioEc,
        tipoIdentificacion: this.tipoIdentificacion,
        numeroIdentificacion: this.numeroIdentificacion,
        domicilio: this.domicilio,
        colonia: this.colonia,
        delegacionMunicipio: this.delegacionMunicipio,
        codigoPostal: this.codigoPostal,
        estado: this.estado,
        telefono: this.telefono,
        celular: this.celular,
        email: this.email,
        profesion: this.profesion,
        ingresoMensual: this.ingresoMensual,
        trabajo: this.trabajo,
        antiguedad: this.antiguedad,
        puesto: this.puesto,
        telefonoTrabajo: this.telefonoTrabajo,
        horario: this.horario,
        giroTrabajo: this.giroTrabajo,
        webTrabajo: this.webTrabajo,
        domicilioTrabajo: this.domicilioTrabajo,
        coloniaTrabajo: this.coloniaTrabajo,
        delegMuniTrabajo: this.delegMuniTrabajo,
        codigoPostalTrabajo: this.codigoPostalTrabajo,
        estadoTrabajo: this.estadoTrabajo,
        jefeTrabajo: this.jefeTrabajo,
        puestoJefe: this.puestoJefe,
        emailJefe: this.emailJefe,
        factura: this.factura,
        fisicaMoralId:this.general.FisicaMoralId,
        RazonSocial:this.razon,
        DomicilioRepresentanteLegal:this.domicilioRep,
        ColoniaRL:this.coloniaRep,
        EstadoRL:this.estadoRep,
        EmailRL:this.emailRep,
        TelefonoRL:this.telRep,
        ActaConstitutiva:this.actaConstitutiva,
        PoderRepresentanteNo:this.numeroPoder,
        TipoRegimenFiscal:this.tipoRegimen,
        DeleMuni:this.deleMuni,
        SindicadoRL:this.sindicato,
      };
      switch (exp) {
        case 1:
          servArrendatarios
            .post(data)
            .then((r) => {
             this.ProcesamosResponse(r.data);
            })
            .catch(function (e) {
              this.classicModalHide();
              mensaje.Error("arrendatario-crud-post", e);
            });
          break;
        case 2:
          if (this.arrendatarioId > 0) {
            servArrendatarios
              .put(this.arrendatarioId, data)
              .then((r) => {
               this.ProcesamosResponse(r.data);
              })
              .catch(function (e) {
                this.classicModalHide();
                mensaje.Error("arrendatarios-crud-put", e);
              });
          }
          break;
      }
    },
    ProcesamosResponse(data) {
      data.forEach((e) => {
        let m = {
          mensaje: "",
          tipo: "",
        };
        switch (e.tipo) {
          case 2: //Atributos por completar
            // se agrega a la clase el error "md-valid" ó "md-error"
            const m = {};
            const valor = this[e.mensaje]; 
            m.mensaje = e.mensaje;
            m.tipo = "danger";
            this.notifyVue(m);
            this.isBandera = true;
            break;
          case 1: //Error
            m.mensaje = e.mensaje;
            m.tipo = "danger";
            this.notifyVue(m);
            this.isBandera=true;
            break;
          case 3: //success
            this.getDatos();
            this.classicModalHide();
            m.mensaje = e.mensaje;
            m.tipo = "success";
            this.notifyVue(m);
            break;
          case 4: //Information
            m.mensaje = e.mensaje;
            m.tipo = "info";
            this.notifyVue(m);
            break;
        }
      });
    },
    getDatos() {
      servArrendatarios
        .getId(this.general.FisicaMoralId)
        .then((a) => {
          this.tableData = a.data;
          // Fuse search initialization.
          this.fuseSearch = new Fuse(this.tableData, {
            keys: ["arrendatarioId", "nombre", "apePaterno", "apeMaterno", "rfc",
              "nacionalidad", "condMigratoria", "estadoCivil", "convenioEc",
              "tipoIdentificacion", "numeroIdentificacion", "domicilio", "colonia",
              "delegacionMunicipio", "codigoPostal", "estado", "telefono", "celular", "email",
              "profesion", "ingresoMensual", "trabajo", "antiguedad", "puesto",
              "telefonoTrabajo", "horario", "giroTrabajo", "webTrabajo", "domicilioTrabajo",
              "coloniaTrabajo", "delegMuniTrabajo", "codigoPostalTrabajo", "estadoTrabajo",
              "jefeTrabajo", "puestoJefe", "emailJefe", "factura", "fisicaMoralId"],
            threshold: 0.3,
          });
        })
        .catch((e) => {
          mensaje.Error("arrendatarios-getId", e);
        });
    },
    classicModalHide() {
      this.Clear();
      this.classicModal = false;
    },
    ModalPersonalizado(exp, row) {
      switch (exp) {
        case 1: //Insertar
          this.classicModal = true;
          this.datosModal.titulo = " Nuevo Arrendatario";
          this.datosModal.boton = "Confirmar";
          this.datosModal.evento = 1;
          break;
        case 2: //Actualizar
          this.arrendatarioId = row.arrendatarioId;
          this.nombre = row.nombre;
          this.apePaterno = row.apePaterno;
          this.apeMaterno = row.apeMaterno;
          this.rfc = row.rfc;
          this.nacionalidad = row.nacionalidad;
          this.condMigratoria = row.condMigratoria;
          this.estadoCivil = row.estadoCivil;
          this.convenioEc = row.convenioEc;
          this.tipoIdentificacion = row.tipoIdentificacion;
          this.numeroIdentificacion = row.tipoIdentificacion;
          this.domicilio = row.domicilio;
          this.colonia = row.colonia;
          this.delegacionMunicipio = row.delegacionMunicipio;
          this.codigoPostal = row.codigoPostal;
          this.estado = row.estado;
          this.telefono = row.telefono;
          this.celular = row.celular;
          this.email = row.email;
          this.profesion = row.profesion;
          this.ingresoMensual = row.ingresoMensual;
          this.trabajo = row.trabajo;
          this.antiguedad = row.antiguedad;
          this.puesto = row.puesto;
          this.telefonoTrabajo = row.telefonoTrabajo;
          this.horario = row.horario;
          this.giroTrabajo = row.giroTrabajo;
          this.webTrabajo = row.webTrabajo;
          this.domicilioTrabajo = row.domicilioTrabajo;
          this.coloniaTrabajo = row.coloniaTrabajo;
          this.delegMuniTrabajo = row.delegMuniTrabajo;
          this.codigoPostalTrabajo = row.codigoPostalTrabajo;
          this.estadoTrabajo = row.estadoTrabajo;
          this.jefeTrabajo = row.jefeTrabajo;
          this.puestoJefe = row.puestoJefe;
          this.emailJefe = row.emailJefe;
          this.factura = row.factura;
          this.fisicaMoralId = row.fisicaMoralId;
          this.actaConstitutiva=row.actaConstitutiva;
          this.coloniaRep=row.coloniaRl;
          this.domicilioRep=row.domicilioRepresentanteLegal;
          this.emailRep=row.emailRl;
          this.estadoRep=row.estadoRl
          this.numeroPoder=row.poderRepresentanteNo;
          this.razon=row.razonSocial;
          this.telRep=row.telefonoRl;
          this.tipoRegimen = row.tipoRegimenFiscal;
          this.deleMuni =row.deleMuni;
          this.sindicato=row.sindicadoRl;
          this.classicModal = true;
          this.datosModal.titulo = "Actualizar Arrendatario";
          this.datosModal.boton = "Actualizar";
          this.datosModal.evento = 2;
          break;

        case 3: //Eliminar
          this.arrendatarioId = row.arrendatarioId;
          this.deleteRow(this.arrendatarioId);
          break;
      }
    },
    deleteRow(id) {
      if (this.arrendatarioId > 0) {
        Swal.fire({
          title: "!Eliminar!",
          text: `¿Deseas eliminar el siguiente registro: ${this.arrendatarioId} ?`,
          type: "success",
          showCancelButton: true,
          confirmButtonClass: "md-button md-success",
          cancelButtonClass: "md-button md-danger",
          confirmButtonText: "Si, eliminar!",
          cancelButtonText: "Cancelar",
          buttonsStyling: false,
        })
          .then((r) => {
            if (r.value) {
              servArrendatarios
                .delete(this.arrendatarioId)
                .then((response) => {
                  this.getDatos();
                  Swal.fire({
                    title: "Eliminado!",
                    text: `Se ha eliminado el registro ${this.arrendatarioId}`,
                    type: "success",
                    confirmButtonClass: "md-button md-success",
                    cancelButtonText: "Cancelar",
                    buttonsStyling: false,
                  });
                })
                .catch(function (e) {
                  mensaje.Error("usuario-crud-sercategorias-delete", e);
                });
            }
            if (r.dismiss == "cancel") {
              this.Clear();
            }
          })
          .catch(function (e) {
            mensaje.Error("usuario-crud-sercategorias-delete", e);
          });
      }
    },
    notifyVue(m) {
      this.$notify({
        message: m.mensaje,
        timeout: 30000,
        icon: "add_alert",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: m.tipo,
      });
    },
    Clear() {
      this.arrendatarioId = 0;
      this.nombre = null;
      this.apePaterno = null;
      this.apeMaterno = null;
      this.rfc = null;
      this.nacionalidad = null;
      this.condMigratoria = null;
      this.estadoCivil = null;
      this.convenioEc = null;
      this.tipoIdentificacion = null;
      this.numeroIdentificacion = null;
      this.domicilio = null;
      this.colonia = null;
      this.delegacionMunicipio = null;
      this.codigoPostal = null;
      this.estado = null;
      this.telefono = null;
      this.celular = null;
      this.email = null;
      this.profesion = null;
      this.ingresoMensual = 0.00;
      this.trabajo = null;
      this.antiguedad = null;
      this.puesto = null;
      this.telefonoTrabajo = null;
      this.horario = null;
      this.giroTrabajo = null;
      this.webTrabajo = null;
      this.domicilioTrabajo = null;
      this.coloniaTrabajo = null;
      this.delegMuniTrabajo = null;
      this.codigoPostalTrabajo = null;
      this.estadoTrabajo = null;
      this.jefeTrabajo = null;
      this.puestoJefe = null;
      this.emailJefe = null;
      this.factura = false;
      this.tipoRegimen = 0;
      this.actaConstitutiva=null;
      this.coloniaRep=null;
      this.domicilioRep=null;
      this.emailRep=null;
      this.estadoRep=null;
      this.numeroPoder=null;
      this.razon=null;
      this.telRep=null;
      this.deleMuni=null;
      this.sindicato=null;
      this.isBandera=false;
    },
  },
  created() {

    if(this.general.SolicitudId > 0)
    {
    
    this.getDatos();
    this.fisicaMoralId=this.general.FisicaMoralId;

    }

  },
  mounted() {

  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result.map((i) => {
        return i.item;
      });
    },
  },
};
</script>

<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.full-control>.md-list {
  width: 320px;
  max-width: 100%;
  height: 400px;
  display: inline-block;
  overflow: auto;
  border: 1px solid rgba(#000, 0.12);
  vertical-align: top;
}

.modal-personalizado {
  max-width: 90%;
}
</style>
