<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">Seguimiento</h4>
        </md-card-header>
        <md-card-content>
          <md-table :value="queriedData" :md-sort.sync="currentSort" :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort" class="paginated-table table-striped table-hover">
            <md-table-toolbar>
              <md-field>
                <label for="pages">Por página</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">{{ item
                  }}</md-option>
                </md-select>
              </md-field>

              <md-field>
                <md-input type="search" class="mb-3" clearable style="width: 200px" placeholder="Buscar registro"
                  v-model="searchQuery"></md-input>
              </md-field>
              <md-field>
                <md-button class="md-primary" @click="ModalPersonalizado(1)">Nuevo</md-button>
              </md-field>
            </md-table-toolbar>

            <!-- FORMATO DE LA TABLA Y PARA LLAMAR INFORMACIÓN -->
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Solución Id" md-sort-by="solucionDetalleId">
                {{
                  item.solucionDetalleId
                }}
              </md-table-cell>
              <md-table-cell md-label="Fecha de Creación" md-sort-by="fechaCreacion">{{ item.fechaCreacion
              }}</md-table-cell>
              <md-table-cell md-label="Obervaciones" md-sort-by="observaciones">
                {{
                  item.observaciones
                }}
              </md-table-cell>

              <md-table-cell md-label="Usuario" md-sort-by="usuarioId">
                {{
                  item.usuarioId
                }}
              </md-table-cell>

              <md-table-cell md-label="Documento" md-sort-by="documentoDesc">
                {{
                  item.documentoDesc
                }}
              </md-table-cell>

              <md-table-cell md-label="Acciones">
                <md-button class="md-just-icon md-warning md-simple" @click.native="ModalPersonalizado(2, item)">
                  <md-icon>edit</md-icon>
                </md-button>
                <md-button class="md-just-icon md-danger md-simple" @click.native="ModalPersonalizado(3, item)">
                  <md-icon>close</md-icon>
                </md-button>
              </md-table-cell>
            </md-table-row>
            <!-- FIN FORMATO DE LA TABLA Y PARA LLAMAR INFORMACIÓN -->
          </md-table>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class>
            <p class="card-category">Showing {{ from + 1 }} to {{ to }} of {{ total }} entries</p>
          </div>
          <pagination class="pagination-no-border pagination-success" v-model="pagination.currentPage"
            :per-page="pagination.perPage" :total="total"></pagination>
        </md-card-actions>
      </md-card>
      <modal v-if="classicModal" @close="classicModalHide">
        <template slot="header">
          <h4 class="modal-title">{{ datosModal.titulo }}</h4>
          <md-button class="md-simple md-just-icon md-round modal-default-button" @click="classicModalHide">
            <md-icon>clear</md-icon>
          </md-button>
        </template>
        <!-- AGREGAR INFORMACIÓN BOTÓN NUEVO -->
        <template slot="body">
          <md-field>
            <label>Solución Id</label>
            <md-input v-model="solucionDetalleId" disabled></md-input>
            <span class="md-helper-text">Solución Id</span>
          </md-field>
          <md-field>
            <label>Observaciones</label>
            <md-input v-model="observaciones"></md-input>
            <span class="md-helper-text">Observaciones</span>
          </md-field>
          <md-field class="align-content-center">
            <div class="file-input">
              <div v-if="!imageRegular">
                <div class="image-container">
                  <img :src="datosModal.evento == 2 ? documentosImagen : imageRegular" :alt="datosModal.evento == 2 ? 'Imagen de documentos' : 'Imagen regular'" title="" />
                </div>
              </div>
              <div class="image-container" v-else>
                <img :src="datosModal.evento == 2 ? documentosImagen : imageRegular" :alt="datosModal.evento == 2 ? 'Imagen de documentos' : 'Imagen regular'" />
              </div>
              <div class="button-container">
                <md-button class="md-danger md-round" @click="removeImage" v-if="imageRegular"><i
                    class="fa fa-times"></i>Eliminar</md-button>
                <md-button class="md-success md-round md-fileinput">
                  <template v-if="!imageRegular">Select image</template>
                  <template v-else>Cambiar</template>
                  <input type="file" @change="onFileChange" />
                </md-button>
              </div>
            </div>
          </md-field>
        </template>
        <template slot="footer">
          <md-button class="md-danger md-simple" @click="classicModalHide">Cerrar</md-button>
          <md-button class="md-primary" @click.native="postNow(datosModal.evento)">{{ datosModal.boton }}</md-button>
        </template>
        <!-- AQUI TERMINA LA FUNCIÓN DE DATOS BOTÓN -->
      </modal>
    </div>
  </div>
</template>

<script>
import { Pagination, Modal } from "@/components";
import Fuse from "fuse.js";
import Swal from "sweetalert2";
import mensaje from "@/Servicios/ManjeadorErrores/Mensajes";
import store from "@/Store/index";
import servSeguimiento from "@/Servicios/Soluciones/detallesolucion";
import servicioStorage from "@/Utilerias/serviceStorage";

export default {

  components: {
    Pagination,
    Modal,
  },
  computed: {
    usuarioId() {
      return store.state.usuario.usuariosId;
    },
    SolucionId() {
      return store.state.General.SolucionId;
    },
    SolicitudId() {
      return store.state.General.SolicitudId;
    },
    modo() {
      return store.state.Modo;
    },
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      currentSort: "solucionDetalleId",
      currentSortOrder: "asc",
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      classicModal: false,
      solucionDetalleId: 0,
      fechaCreacion: null,
      observaciones: "",
      documentosImagen: "",
      documentoDesc: "",
      datosModal: {
        titulo: "",
        boton: "",
        evento: 0,
      },
      imageRegular: "",
      file: null,
    };
  },

  methods: {
    async onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.file = files[0];
      if (e.target.name) {
        this.createImage(files[0], "circle");
      } else {
        this.createImage(files[0]);
      }
    },
    async uploadFileS3() {
      if (this.file != null) {
        //Eliminamos espacios en blanco en el nombre del archivo
        let nameFile = this.file.name.replace(/\s/g, "");
        let pathS3 = this.SolicitudId + "/" + "Solucion/" + nameFile;
        return await servicioStorage
          .upload(pathS3, this.file, this.file.type)
          .then((r) => {
            return r.Location;
          })
          .catch(function (e) {
            mensaje.Error("Solucion-crud-post-servStorage", e);
            return null;
          });
      } else {
        let mensaje = {
          mensaje: "No se ha seleccionado una imagen",
          tipo: "warning",
        };
        this.notifyVue(mensaje);
      }
    },
    createImage(file, type) {
      let reader = new FileReader();
      let vm = this;

      reader.onload = (e) => {
        if (type === "circle") {
          vm.imageCircle = e.target.result;
        } else {
          vm.imageRegular = e.target.result;
        }
        this.imagen = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    removeImage(type) {
      if (type === "circle") {
        this.imageCircle = "";
      } else {
        this.imageRegular = "";
      }
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    async postNow(exp) {
      if (this.SolucionId == 0) {
        let mensaje = {
          mensaje: "No se ha seleccionado una solución",
          tipo: "danger",
        };
        this.notifyVue(mensaje);
        return;
      }
      let date = new Date();
      var dateString = date.toISOString();
      let pathS3 = await this.uploadFileS3();
      let nameFile = this.file.name.replace(/\s/g, "");
      var data = {
        solucionDetalleId: this.solucionDetalleId,
        fechaCreacion: dateString,
        observaciones: this.observaciones,
        solucionesId: this.SolucionId,
        usuarioId: this.usuarioId,
        documentoDesc: nameFile,
        documentosImagen: pathS3
      };
      console.log("data", data);
      switch (exp) {
        case 1:
          servSeguimiento
            .post(data)
            .then((r) => {
              //hay que cerrar el model
              console.log("r", r);
              this.classicModal = false;
              this.procesamosResponse(r.data)
            })
            .catch(function (e) {
              mensaje.Error("Solucion-crud-post", e);
            });
          break;

        case 2:
          if (this.solucionDetalleId > 0) {
            servSeguimiento
              .put(this.solucionDetalleId, data)
              .then((r) => {
                //hay que cerrar el model
                this.classicModal = false;
                this.procesamosResponse(r.data)
              })
              .catch(function (e) {
                mensaje.Error("SolucionD-crud-put", e);
              });
          }
          break;
      }
    },
    getDatos() {
      servSeguimiento
        .getId(this.SolucionId)
        .then((a) => {
          this.tableData = a.data;
          this.fuseSearch = new Fuse(this.tableData, {
            keys: ["solucionDetalleId", "fechaCreacion", "obervaciones", "usuarioId", "documentoDesc"],
            threshold: 0.3,
          });
        })
        .catch((e) => {
          mensaje.Error("datosSolucion--all", e);
        });
    },
    classicModalHide() {
      this.Clear();
      this.classicModal = false;
    },
    ModalPersonalizado(exp, row) {
      switch (exp) {
        case 1: //Insertar
          this.classicModal = true;
          this.datosModal.titulo = "Subir Documentación";
          this.datosModal.boton = "Guardar";
          this.datosModal.evento = 1;
          break;
        case 2: //Actualizar
          this.observaciones = row.observaciones;
          this.documentosImagen = row.documentosImagen;
          this.solucionDetalleId = row.solucionDetalleId;
          this.classicModal = true;
          this.datosModal.titulo = "Editar Solución";
          this.datosModal.boton = "Guardar Cambios";
          this.datosModal.evento = 2;
          break;

        case 3: //Eliminar
          this.solucionDetalleId = row.solucionDetalleId;
          this.deleteRow(this.solucionDetalleId);
          break;
      }
    },
    deleteRow(id) {
      if (this.solucionDetalleId > 0) {
        Swal.fire({
          title: "!Eliminar!",
          text: `¿Deseas eliminar el siguiente registro: ${this.solucionDetalleId} ?`,
          type: "success",
          showCancelButton: true,
          confirmButtonClass: "md-button md-success",
          cancelButtonClass: "md-button md-danger",
          confirmButtonText: "Si, eliminar!",
          cancelButtonText: "Cancelar",
          buttonsStyling: false,
        })
          .then((r) => {
            if (r.value) {
              servSeguimiento
                .delete(id)
                .then((response) => {
                  Swal.fire({
                    title: "Eliminado!",
                    text: `Se ha eliminado el registro ${this.solucionDetalleId}`,
                    type: "success",
                    confirmButtonClass: "md-button md-success",
                    cancelButtonText: "Cancelar",
                    buttonsStyling: false,
                  });
                  let mens = {};
                  mens = mensaje.generico(
                    response.config.method,
                    response.status
                  );
                  if (mens.accion == true) {
                    this.getDatos();
                  }
                  this.notifyVue(mens);
                })
                .catch(function (e) {
                  mensaje.Error("Solucion-crud-delete", e);
                });
            }
            if (r.dismiss == "cancel") {
              this.Clear();
            }
          })
          .catch(function (e) {
            mensaje.Error("Solucion-delete", e);
          });
      }
    },
    procesamosResponse(data){
      data.forEach((e) => {
        let m = {
          mensaje: "",
          tipo: "",
        };
        switch (e.tipo) {
          case 2: //Atributos por completar
            // se agrega a la clase el error "md-valid" ó "md-error"

            const valor =this[e.mensaje];
            const m = {};
            m.mensaje = e.mensaje;
            m.tipo = "danger";
            this.notifyVue(m);
            break;
          case 1: //Error
            m.mensaje = e.mensaje;         
            m.tipo = "danger";
            this.notifyVue(m);
            break;
          case 3: //success
            m.mensaje = e.mensaje;
            m.tipo = "success";
            this.notifyVue(m);  
            this.getDatos();        
            break;
          case 4: //Information
            m.mensaje = e.mensaje;
            m.tipo = "info";
            this.notifyVue(m);
            break;
        }
      });
    },
    notifyVue(Mensajes) {
      this.$notify({
        message: Mensajes.mensaje,
        timeout: 30000,
        icon: "add_alert",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: Mensajes.tipo,
      });
    },
    Clear() {
      this.solucionDetalleId = 0;
      this.fechaCreacion = null;
      this.observaciones = null;
      this.usuarioId = null;
      this.documentoDesc = null;
      this.documentosImagen = null;
    },
  },
  created() {
    //if (this.general.SolucionId > 0) {
    this.getDatos();
    //}
  },
  mounted() { },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      //console.log("this.tableData", this.tableData);
      if (value !== "") {
        // console.log(value);
        // console.log(this.searchQuery);
        //console.log(this.fuseSearch);
        // result = this.fuseSearch.search(this.searchQuery);
        // console.log("result", result);
        result = this.fuseSearch.search(this.searchQuery);
        console.log(this.fuseSearch);  //("result", result);
      }
      this.searchedData = result.map((i) => {
        return i.item;
      });
    },
  },
};
</script>

<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.full-control>.md-list {
  width: 320px;
  max-width: 100%;
  height: 400px;
  display: inline-block;
  overflow: auto;
  border: 1px solid rgba(#000, 0.12);
  vertical-align: top;
}

.align-content-center {
  align-content: center;
}
</style>
