<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-50">
      <md-card>
        <md-card-header class="md-card-header-text md-card-header-primary">
          <div class="card-text">
            <h4 class="title">Bienvenido a Soluciones</h4>
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-size-50">
              <md-field>
                <label>Fecha Inicio</label>
                <md-input v-model="fechainicio" type="date"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-50">
              <md-field>
                <label>Fecha Fin</label>
                <md-input v-model="fechafin" type="date"></md-input>
              </md-field>
            </div>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="right">
          <md-button class="md-primary" @click.native="Priorizamos()">Filtrar</md-button>
        </md-card-actions>
      </md-card>
    </div>
    <cards :Cantidad="this.cantidadNuevas" txtHeader="Nueva Solucion" txtFooter="Nueva Solucion"></cards>
    <cards :Cantidad.sync="this.cantidadConcluida" txtHeader="Concluidas" txtFooter="Concluidas"></cards>

    <!-- <graficas :labels="this.labels" :series="this.series" Title="Solicitudes por semana"></graficas> -->
    <div class="md-layout-item md-size-50">
      <div class="content">
        <div class="md-layout">
          <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
            <md-card>
              <md-card-header class="md-card-header-icon md-card-header-primary">
                <div class="card-icon">
                  <md-icon>assignment</md-icon>
                </div>
                <h4 class="title">Soluciones por Estatus</h4>
              </md-card-header>
              <md-table v-model="estatus" table-header-color="red">
                  <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <md-table-cell md-label="Estatus">
                      {{ item.estatus}}
                    </md-table-cell>
                    <md-table-cell md-label="Cantidad">
                      {{ item.cantidad}}
                    </md-table-cell>
                  </md-table-row>
                </md-table>
            </md-card> 
          </div>
        </div>
      </div>
    </div>
  
    <div class="md-layout-item md-size-50">
      <div class="content">
        <div class="md-layout">
          <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
            <md-card>
              <md-card-header class="md-card-header-icon md-card-header-primary">
                <div class="card-icon">
                  <md-icon>assignment</md-icon>
                </div>
                <h4 class="title">Solución por tipo de Póliza</h4>
              </md-card-header>
              <md-card-content>
                <md-table v-model="tipo" table-header-color="red">
                  <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <md-table-cell md-label="Estatus">
                      {{ item.estatus }}
                    </md-table-cell>
                    <md-table-cell md-label="Cantidad">
                      {{ item.cantidad }}
                    </md-table-cell>
                      <md-table-cell md-label="Tipo">
                      {{ item.tipo }}
                    </md-table-cell>
                  </md-table-row>
                </md-table>
              </md-card-content>
            </md-card>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template> 
   <script> 
import cards from "./cards.vue";
import listtpoliza from "@/Servicios/Dashboard/tipopoliza";
import servest from "@/Servicios/Dashboard/EstatusSoluciones";
import servconcluido from "@/Servicios/Dashboard/concluidadashboard";
import listestatus from "@/Servicios/Dashboard/dashboardestatus";
import mensaje from "../../Servicios/ManjeadorErrores/Mensajes";
import store from "../../Store/index";

export default {
  computed: {
    Id() {
      return;
    },
  },
  components: {
    cards,
  },

  data() {
    return {
      fechainicio: "",
      fechafin: "",
      estatus: [],
      cantidad: 0,
      tipo:[],
      cantidadNuevas: 0,
      cantidadConcluida: 0,
    };
  },
  methods: {
    //METODO CUADRO NUEVAS//
    async getSoluciones() {
      servest
        .all()
        .then((a) => {
          console.log("Cantidad", a.data);
          this.cantidadNuevas = a.data;
        })
        .catch((e) => {
          mensaje.Error("Dashboard-Nueva ", e);
        });
    },
    //CUADRO CONCLUIDAS//
    async getConcluido() {
      servconcluido
        .all()
        .then((r) => {
          console.log("Concluido", r.data);
          this.cantidadConcluida = r.data;
        })
        .catch((x) => {
          mensaje.Error("Dashboard-Concluida ", x);
        });
    },
    //DATOS PARA LA TABLA ESTATUS.
    async getlist() {
      listestatus
        .all()
        .then((p) => {
          console.log("estatus", p.data);
          this.estatus = p.data;
        })
        .catch((z) => {
          mensaje.Error("Dashboard-listado ", z);
        });
    },
     //DATOS PARA LA TABLA TIPO POLIZA
    async gettpoliza() {
      listtpoliza
        .all()
        .then((t) => {
          console.log("tipo", t.data);
          this.tipo = t.data;
        })
        .catch((z) => {
          mensaje.Error("Dashboard-Tipo Poliza ", z);
        });
    },

    SetFechas() {
      var date = new Date(),
        y = date.getFullYear(),
        m = date.getMonth();
      var firstDay = new Date(y, m, 1);
      var lastDay = new Date(y, m + 1, 0);
      this.fechainicio = firstDay.toISOString();
      this.fechafin = lastDay.toISOString();
    },
    Priorizamos() {
      this.getSoluciones();
      this.getConcluido();
    },
  },
  mounted() {},
  created() {
    this.SetFechas();
    this.getSoluciones();
    this.getConcluido();
    this.getlist();
    this.gettpoliza();
  },
  watch: {},
};
</script>
  