import { http } from "@/http-commons";

class FiadorF {
    getId(id) {
        return http.get(`/FiadorF/${id}`);
    }
    delete(id) {
        return http.delete(`/FiadorF/${id}`);
    }
    post(data) {
        return http.post(`/FiadorF`, data);
    }
    put(id, data) {
        return http.put(`/FiadorF/${id}`, data)
    } 
}
export default new FiadorF();