<template>
  <div class="md-layout">
    <md-card>
      <md-card-header class="md-card-header-icon" :class="getClass(headerColor)">
        <div class="card-icon">
          <md-icon>perm_identity</md-icon>
        </div>
        <h4 class="title">
          Edite su perfil -
          <small>todo lo que se modifique en esta sección se vera reflejado en la
            página de internet.</small>
        </h4>
      </md-card-header>
      <md-card-content>
        <div class=" md-layout">
          <div class="md-layout-item md-medium-size-100">
            <md-field>
              <label>Id</label>
              <md-input v-model="UsuariosId" disabled></md-input>
              <span class="md-helper-text">Id</span> </md-field><md-field :class="UsuarioEmailError">
              <label>E-mail</label>
              <md-input v-model="UsuarioEmail" type="email"></md-input>
              <span class="md-helper-text">E-mail</span>
            </md-field>
            <md-field :class="UsuarioNombreError">
              <label>Nombre (s)</label>
              <md-input v-model="UsuarioNombre"></md-input>
              <span class="md-helper-text">Nombre (s)</span>
            </md-field>
            <md-field :class="UsuarioApellidoPaternoError">
              <label>Apellido Paterno</label>
              <md-input v-model="UsuarioApellidoPaterno"></md-input>
              <span class="md-helper-text">Apellido Paterno</span>
            </md-field>
            <md-field :class="UsuarioApellidoMaternoError">
              <label>Apellido Materno</label>
              <md-input v-model="UsuarioApellidoMaterno"></md-input>
              <span class="md-helper-text">Apellido Materno</span>
            </md-field>
            <md-field :class="UsuarioTelefonoError">
              <label>Teléfono</label>
              <md-input v-model="UsuarioTelefono"></md-input>
              <span class="md-helper-text">Usuario Teléfono</span>
            </md-field>
            <md-field :class="UsuarioCelularError">
              <label>Celular</label>
              <md-input v-model="UsuarioCelular"></md-input>
              <span class="md-helper-text">Celular</span>
            </md-field>
            <md-field :class="UsuarioInmobiliariaError">
              <label>Inmobiliaria</label>
              <md-input v-model="UsuarioInmobiliaria"></md-input>
              <span class="md-helper-text">Inmobiliaria</span>
            </md-field>
            <md-field :class="UsuarioFechaError">
              <label>Fecha de Cumpleanos</label>
              <md-input v-model="UsuarioFecha"></md-input>
              <span class="md-helper-text">Fecha de Cumpleanos</span>
            </md-field>
          </div>

          <div class="md-layout-item md-medium-size-100">
            <md-field :class="AreaIdError">
              <label for="AreaId">(Seleccionar área)</label>
              <md-select v-model="AreaId" disabled>
                <md-option v-for="a in Areas" :key="a.areaId" :value="a.areaId">
                  {{ a.areaDescripcion }}
                </md-option>
              </md-select>
            </md-field>
            <md-field :class="RepresentacionIdError">
              <label for="RepresentacionId">(Seleccionar representacion)</label>
              <md-select v-model="RepresentacionId" disabled>
                <md-option v-for="r in RepresentacionesData" :key="r.representacionId" :value="r.representacionId">
                  {{ r.representacionNombre }}
                </md-option>
              </md-select>
            </md-field>
            <md-field :class="IsResponsanleError">
              <md-checkbox v-model="IsResponsanle">IsResponsanle</md-checkbox>
            </md-field>
            <md-field :class="UsuarioPadreIdError">
              <label for="UsuarioPadreId">(Seleccionar usuario padre)</label>
              <md-select v-model="UsuarioPadreId">
                <md-option v-for="u in UsuariosPadreCol" :key="u.usuariosId" :value="u.usuariosId">
                  {{ u.usuarioNomCompleto }}
                </md-option>
              </md-select>
            </md-field>
            <md-field :class="ActivoError">
              <md-checkbox v-model="Activo" disabled>Activo</md-checkbox>
            </md-field>
            <md-field :class="UsuarioInmobiliariaError">
              <label>Titulo</label>
              <md-input v-model="titulo" disabled></md-input>
              <span class="md-helper-text">*Titulo</span>
            </md-field>
            <md-field :class="UsuarioContraseniaError">
              <label>Contraseña del sistema anterior</label>
              <md-input v-model="UsuarioContrasenia" type="password"></md-input>
              <span class="md-helper-text">Contraseña del sistema anterior</span>
            </md-field>
            <md-field :class="ContraEncryptError">
              <label>Contraseña</label>
              <md-input v-model="ContraEncrypt" type="password"></md-input>
              <span class="md-helper-text">Contraseña</span>
            </md-field>
            <md-field>
              <label>Repetir Contraseña</label>
              <md-input v-model="ContraseniaValidacion" type="password"></md-input>
              <span class="md-helper-text">Repetir Contraseña</span>
            </md-field>
            <div class="md-layout-item text-right">
              <md-button class="md-raised md-primary mt-4" @click.native="update()"
              >Actualizar</md-button>
            </div>
          </div>
           
          <div class="md-layout-item md-medium-size-100">
            <md-card class="md-card-profile ">
              <div class="photo">
                <img :src="this.imagen" alt="avatar" />
              </div>

              <md-card-content>
                <h6 class="category text-gray">{{ this.titulo }}</h6>
                <h4 class="card-title">{{ this.UsuarioNomCompleto }}</h4>
                <md-button class="md-success md-round md-fileinput">
                  <template v="!imageRegular">Cambiar Imagen</template>
                  <input type="file" @change="onFileChange" />
                </md-button>
              </md-card-content>
            </md-card>
          </div> 
        </div>

        <div class="">

        </div>
      </md-card-content>
    </md-card>
    <configurar-email />
  </div>
</template>


<script>
import { Pagination, Modal } from "@/components";
import servusuario from "../../Servicios/Usuarios/usuario";
import store from "../../Store/index";
import mensaje from "../../Servicios/ManjeadorErrores/Mensajes";
import servarea from "../../Servicios/Area/area";
import servrepresentacion from "../../Servicios/Representacion/representacion";
import ConfigurarEmail from "../Catalogos/confEmailXUsuario.vue";
export default {
  //name: "edit-profile-form",
  name: 'LayoutHorizontalColumns',
  props: {
    headerColor: {
      type: String,
      default: "",
    },
  },
  components: {
    ConfigurarEmail,
    Pagination,
    Modal,
  },
  computed: {
    usuario() {
      return store.state.usuario;
    },
  },
  data() {
    return {
      classicModal: false,
      tableData: [],
      UsuariosId: 0,
      UsuarioEmail: null,
      UsuarioNombre: null,
      UsuarioApellidoPaterno: null,
      UsuarioApellidoMaterno: null,
      UsuarioTelefono: null,
      UsuarioContrasenia: null,
      UsuarioNomCompleto: null,
      UsuarioInmobiliaria: null,
      UsuarioFecha : null,
      AreaId: null,
      RepresentacionId: null,
      RepresentacionNombre: null,
      UsuarioCelular: null,
      IsResponsanle: false,
      UsuarioPadreId: null,
      Activo: false,
      ContraEncrypt: null,
      UsuarioEmailError: null,
      UsuarioNombreError: null,
      UsuarioApellidoPaternoError: null,
      UsuarioApellidoMaternoError: null,
      UsuarioTelefonoError: null,
      UsuarioContraseniaError: null,
      UsuarioNomCompletoError: null,
      UsuarioInmobiliariaError: null,
      UsuarioFechaError : null,
      AreaIdError: null,
      RepresentacionIdError: null,
      RepresentacionNombreError: null,
      UsuarioCelularError: null,
      IsResponsanleError: false,
      UsuarioPadreIdError: null,
      ActivoError: false,
      ContraEncryptError: null,
      Areas: [],
      RepresentacionesData: [],
      ContraseniaValidacion: "",
      isLoader: true,
      isActivo: false,
      titulo: null,
      FiltroAreaId: 3, //Representaciones
      imageRegular: "",
      imagen: null,
      UsuariosPadreCol: [],
    };
  },
  methods: {
    classicModalHide() {
      this.classicModal = false;
    },
    cambiarFoto() {
      return console.log("HOLA ");

    },
    update() {
      this.isActivo = true;
      var isBAndera = false;
      if (
        this.ContraEncrypt != this.ContraseniaValidacion &&
        (exp == 1 || exp == 2)
      ) {
        let mens = {
          tipo: "danger",
          mensaje:
            "Las contraseñas deben de coincidir, favor de intentarlo nuevamente",
        };
        isBAndera = true;
        this.isActivo = false;
        this.notifyVue(mens);
      }
      if (this.UsuarioEmail == null && (exp == 1 || exp == 2)) {
        isBAndera = true;
        this.isActivo = false;
        let mens = {
          tipo: "danger",
          mensaje: "El email, no puede estar vacío",
        };
        this.notifyVue(mens);
      }
      if (this.imagen == null && exp == 4) {
        let mens = {
          tipo: "danger",
          mensaje: "Favor de seleccionar una imagen.",
        };
        isBAndera = true;
        this.isActivo = false;
        this.notifyVue(mens);
      }
      if (isBAndera == false) {
        var NomCompleto =
          this.UsuarioApellidoMaterno == null
            ? this.UsuarioNombre + " " + this.UsuarioApellidoPaterno
            : this.UsuarioNombre +
            " " +
            this.UsuarioApellidoPaterno +
            " " +
            this.UsuarioApellidoMaterno;
        var data = {
          usuariosId: this.UsuariosId,
          usurioEmail: this.UsuarioEmail,
          usuarioNombre: this.UsuarioNombre,
          usuarioApellidoPaterno: this.UsuarioApellidoPaterno,
          usuarioApellidoMaterno: this.UsuarioApellidoMaterno,
          usuarioTelefono: this.UsuarioTelefono,
          usuarioInmobiliaria: this.UsuarioInmobiliaria,
          areaId: this.AreaId > 0 ? this.AreaId : 0,
          representacionId:
            this.RepresentacionId > 0 ? this.RepresentacionId : 0,
          usuarioCelular: this.UsuarioCelular,
          isResponsanle: this.IsResponsanle,
          usuarioPadreId: this.UsuarioPadreId > 0 ? this.UsuarioPadreId : null,
          activo: this.Activo,
          contraEncrypt: btoa(this.ContraEncrypt),
          usuarioNomCompleto: NomCompleto,
          titulo: this.titulo,
          imagen: this.imagen,
          usuarioContrasenia: this.UsuarioContrasenia,
          usuariofecha : this.UsuarioFecha,
        };
      }
      if (this.UsuariosId > 0 && isBAndera == false) {
        // console.log(data);
        servusuario
          .put(this.UsuariosId, data)
          .then((r) => this.ProcesamosResponse(r.data))
          .catch((e) => mensaje.Error("usuario-crud-servusuario-put", e));
      }
    },
    getClass(headerColor) {
      return "md-card-header-" + headerColor + "";
    },
    getDatosArea() {
      servarea
        .all()
        .then((a) => {
          this.Areas = a.data;
        })
        .catch((e) => {
          mensaje.Error("usuario-area-all", e);
        });
    },
    getDatosRepresentacion() {
      servrepresentacion
        .all()
        .then((r) => {
          this.RepresentacionesData = r.data;
        })
        .catch((e) => {
          mensaje.Error("usuario-representaciones-all", e);
        });
    },
    GetDatos() {
      servusuario
        .getUser(store.state.usuario.usuariosId)
        .then((r) => {
          this.Asignamos(r.data);
        });
      // .caclstch((e) => console.log("Error edit profile retrive data", e));
    },
    Asignamos(row) {
      console.log("row", row);
      this.UsuariosId = row.usuariosId;
          this.UsuarioEmail = row.usurioEmail;
          this.UsuarioNombre = row.usuarioNombre;
          this.UsuarioApellidoPaterno = row.usuarioApellidoPaterno;
          this.UsuarioApellidoMaterno = row.usuarioApellidoMaterno;
          this.UsuarioTelefono = row.usuarioTelefono;
          this.UsuarioNomCompleto = row.usuarioNomCompleto;
          this.UsuarioInmobiliaria = row.usuarioInmobiliaria;
          this.AreaId = row.areaId;
          this.RepresentacionId = row.representacionId;
          this.UsuarioCelular = row.usuarioCelular;
          this.IsResponsanle = row.isResponsanle;
          this.UsuarioPadreId = row.usuarioPadreId;
          this.Activo = row.activo;
          this.titulo = row.titulo;
          this.imagen = row.imagen;
          this.UsuarioContrasenia = row.usuarioContrasenia;
          this.ContraEncrypt = atob(row.contraEncrypt);
          this.ContraseniaValidacion = this.ContraEncrypt;
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      if (e.target.name) {
        this.createImage(files[0], "circle");
      } else {
        this.createImage(files[0]);
      }
    },
    createImage(file, type) {
      let reader = new FileReader();
      let vm = this;

      reader.onload = (e) => {
        if (type === "circle") {
          vm.imageCircle = e.target.result;
        } else {
          vm.imageRegular = e.target.result;
        }
        this.imagen = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    removeImage: function (type) {
      if (type === "circle") {
        this.imageCircle = "";
      } else {
        this.imageRegular = "";
      }

    },
    ProcesamosResponse(data) {
      data.forEach((e) => {
        let m = {
          mensaje: "",
          tipo: "",
        };
        switch (e.tipo) {
          case 2: //Atributos por completar
            // se agrega a la clase el error "md-valid" ó "md-error"
            this[e.mensaje] = "md-error";
            break;
          case 1: //Error
            this.isActivo = false;
            m.mensaje = e.mensaje;
            m.tipo = "danger";
            this.notifyVue(m);
            break;
          case 3: //success
            m.mensaje = e.mensaje;
            m.tipo = "success";
            this.notifyVue(m);
            break;
          case 4: //Information
            m.mensaje = e.mensaje;
            m.tipo = "info";
            this.notifyVue(m);
            break;
        }
      });
    },
    notifyVue(m) {
      this.$notify({
        message: m.mensaje,
        timeout: 30000,
        icon: "add_alert",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: m.tipo,
      });
    },
  },
  created() {
    
    if(store.state.usuario.usuariosId > 0 ){
      this.GetDatos();
      this.getDatosArea();
      this.getDatosRepresentacion();
    }
    
  },
};
</script>
<style lang="css" scoped></style>

