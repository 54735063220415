<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">Histórico de Emails</h4>
        </md-card-header>
        <md-card-content>
          <md-table
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover"
          >
            <md-table-toolbar>
              <md-field>
                <label for="pages">Por página</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>

              <md-field>
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style="width: 200px"
                  placeholder="Buscar registro"
                  v-model="searchQuery"
                >
                </md-input>
              </md-field>
              <md-field>
                <md-button class="md-info" @click="getDatos()"
                  >actualizar</md-button
                >
              </md-field>
              <md-field>
                <md-button class="md-info" @click="generarExcel()"
                  >Generar Excel</md-button
                >
              </md-field>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Póliza Id" md-sort-by="polizaId">{{
                item.polizaId
              }}</md-table-cell>
              <md-table-cell md-label="SolicitudId" md-sort-by="solicitudId">{{
                item.solicitudId
              }}</md-table-cell>
              <md-table-cell md-label="Fecha" md-sort-by="fecha">{{
                item.fecha
              }}</md-table-cell>
              <md-table-cell
                md-label="Tipo Correo"
                md-sort-by="tipoCorreoDesc"
                >{{ item.tipoCorreoDesc }}</md-table-cell
              >
              <!--<md-table-cell md-label="Ejecutivo" md-sort-by="ejecutivoNombre">{{
                item.ejecutivo
              }}</md-table-cell>-->
              <md-table-cell md-label="Arrendador" md-sort-by="arrendador">{{
                item.arrendador
              }}</md-table-cell>
              <md-table-cell md-label="Arrendatario" md-sort-by="arrendatario">{{
                item.arrendatario
              }}</md-table-cell>
              <md-table-cell md-label="Fiador" md-sort-by="fiador">{{
                item.Fiador
              }}</md-table-cell>
              <md-table-cell md-label="Ejecutivo" md-sort-by="ejecutivo">{{
                item.ejecutivo
              }}</md-table-cell>
              <md-table-cell md-label="asesor" md-sort-by="asesor">{{
                item.asesor
              }}</md-table-cell>
            </md-table-row>
          </md-table>
          <div class="footer-table md-table">
            <table>
              <caption>Descripción de los datos en el pie de la tabla</caption>
              <tfoot>
                <tr>
                  <th
                    v-for="item in footerTable"
                    :key="item"
                    class="md-table-head"
                  >
                    <div class="md-table-head-container md-ripple md-disabled">
                      <div class="md-table-head-label">
                        {{ item }}
                      </div>
                    </div>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          >
          </pagination>
        </md-card-actions>
      </md-card>
      
    </div>
  </div>
</template>

<script>
import { Pagination, Modal } from "@/components";
import Fuse from "fuse.js";
import Swal from "sweetalert2";
import mensaje from "../../Servicios/ManjeadorErrores/Mensajes";
import serv from "../../Servicios/Representacion/HistoricoEmail";
import store from "../../Store/index";
import excel from "../../Utilerias/GenerarExcel";

export default {
  components: {
    Pagination,
  },
  computed: {
    usuario() {
      return store.state.usuario;
    },

    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      currentSort: "solicitudId",
      currentSortOrder: "asc",
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      footerTable: [
        "Poliza Id",
        "Solicitud Id ",
        "Fecha",
        "Tipo Correo",
        "Arrendador",
        "Arrendatario",
        "Fiador",
        "Ejecutivo",
        "Asesor",
      ],
      searchQuery: "",
      propsToSearch: ["polizaId", "solicitudId",],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      classicModal: false,
      polizaId: 0,
      solicitudId: 0,
      fecha: null,
      tipoCorreoId:0,

    };
  },
  methods: {
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },

    getDatos() {
      this.tableData = []; 
      serv
        .get(this.usuario.usuariosId)
        .then((a) => {
          this.tableData = a.data;
          console.log(this.usuario.usuariosId);
          //console.log("data",a.data);
          // Fuse search initialization.
          this.fuseSearch = new Fuse(this.tableData, {
            keys: ["solicitudId"],
            threshold: 0.3,
          });
        })
        .catch((e) => {
          mensaje.Error("get-all-historicoemails", e);
        });
    },
  },
  generarExcel(){
    excel.GeneraExcel(this.tableData);
  },
  created() {
    
  },
  mounted() {
    this.getDatos();
    console.log("entro");
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result.map(r => r.item);
    },
  },
};
</script>

<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.full-control > .md-list {
  width: 320px;
  max-width: 100%;
  height: 400px;
  display: inline-block;
  overflow: auto;
  border: 1px solid rgba(#000, 0.12);
  vertical-align: top;
}
</style>
