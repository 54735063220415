import { http } from "@/http-commons";

class confemail {
  all() {
    return http.get(`/confemail/`);
  }
  get(id) {
    return http.get(`/confemail/${id}`);
  }
  post(data) {
    return http.post('/confemail', data);
  }
  put(id, data) {
    return http.put(`/confemail/${id}`, data);
  }
  delete(id) {
    return http.delete(`/confemail/${id}`);
  }
}

export default new confemail();