import { http } from "@/http-commons";

class cuentasbancarias {
  all() {
    return http.get('/CuentasBancaria');
  }
  post(data) {
    return http.post('/CuentasBancaria',data);
  }
  put(id,data) {
    return http.put(`/CuentasBancaria/${id}`,data);
  }
  delete(id) {
    return http.delete(`/CuentasBancaria/${id}`);
  }
  Usuario(id){
    return http.get(`/CuentasBancaria/Usuario/${id}`);
  }
}

export default new cuentasbancarias();