<template>
  <div class="md-layout">
    <div class="md-layout-item md-small-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-primary">
          <div class="card-icon">
            <md-icon>equalizer</md-icon>
          </div>
          <h4 class="title">Reporte de Ventas</h4>
        </md-card-header>
        <md-card-content>
          <div class="md-layout-item md-size-100 md-medium-size-100">
            <h5>Filtros</h5>
            <div class="md-layout">
              <div
                class="md-layout-item md-size-30 md-medium-size-100"
                v-if="usuario.areaId == 1"
              >
                <md-field :class="representacionIdError">
                  <label for="representacionId"
                    >(Seleccionar Representación)</label
                  >
                  <md-select v-model="representacionId" multiple>
                    <md-option
                      v-for="r in tableRepresentacion"
                      :key="r.representacionId"
                      :value="r.representacionId"
                    >
                      {{ r.representacionNombre }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>
              <div class="md-layout-item md-size-30 md-medium-size-100">
                <md-field :class="tipoProcesoPoId">
                  <label for="AreaId">(Seleccionar Estatus Póliza)</label>
                  <md-select v-model="tipoProcesoPoId">
                    <md-option md-sort-by="t.descripcion"
                      v-for="t in tableEstatusPoliza"
                      :key="t.tipoProcesoPoId"
                      :value="t.tipoProcesoPoId"
                    >
                      {{ t.descripcion }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>
              <div class="md-layout-item md-size-30 md-medium-size-100">
                <md-field :class="cuentaIdError">
                  <label for="AreaId">(Seleccionar Estatus Solicitud)</label>
                  <md-select v-model="tipoProcesoId">
                    <md-option
                      v-for="c in tableTipoProceso"
                      :key="c.tipoProcesoId"
                      :value="c.tipoProcesoId"
                    >
                      {{ c.descripcion }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>
            </div>
          </div>
        </md-card-content>
        <md-card-content>
          <div class="md-layout-item md-size-100 md-medium-size-100">
            <tabs
              :tab-name="[
                'Fecha de Solicitud',
                'Fecha de Póliza',
                'Id de Solicitud',
                'Id de Póliza',
              ]"
              flex-column
              color-button="primary"
            >
              <!-- here you can add your content for tab-content -->
              <template slot="tab-pane-1">
                <md-datepicker v-model="FechaInicioSolicitud" md-immediately>
                  <label>Fecha Inicio</label>
                </md-datepicker>
                <md-datepicker v-model="FechaFinSolicitud" md-immediately>
                  <label>Fecha Fin</label>
                </md-datepicker>
              </template>
              <template slot="tab-pane-2">
                <md-datepicker v-model="FechaInicioPoliza" md-immediately>
                  <label>Fecha Inicio</label>
                </md-datepicker>
                <md-datepicker v-model="FechaFinPoliza" md-immediately>
                  <label>Fecha Fin</label>
                </md-datepicker>
              </template>
              <template slot="tab-pane-3">
                <md-field>
                  <label>Id Solicitud Inicio</label>
                  <md-input v-model="SolicitudIdInicio"></md-input>
                </md-field>
                <md-field>
                  <label>Id Solicitud Fin</label>
                  <md-input v-model="SolicitudIdFin"></md-input>
                </md-field>
              </template>
              <template slot="tab-pane-4">
                <md-field>
                  <label>Id Póliza Inicio</label>
                  <md-input v-model="PolizaIdInicio"></md-input>
                </md-field>
                <md-field>
                  <label>Id Póliza Fin</label>
                  <md-input v-model="PolizaIdFin"></md-input>
                </md-field>
              </template>
            </tabs>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="right">
          <md-button class="md-default" @click.native="Limpiar()"
            >Limpiar Filtros</md-button
          >
          <md-button
            class="md-primary"
            @click.native="Generar()"
            :disabled="Activo"
            >Reporte de Ventas</md-button
          >
          <md-button
            class="md-primary"
            @click.native="GenerarEstadoCuenta()"
            :disabled="Activo"
            >Estado de Cuenta</md-button
          >
          <md-button
            class="md-primary"
            @click.native="HistorialPoliza()"
            :disabled="Activo"
            >Historial Póliza</md-button
          >
          <md-button
            class="md-primary"
            @click.native="Renovaciones()"
            :disabled="Activo"
            >Renovaciones</md-button
          >
        </md-card-actions>
        <md-progress-bar
          class="md-success"
          md-mode="buffer"
          v-if="isLoader == true"
        ></md-progress-bar>
      </md-card>
    </div>
  </div>
</template>

<script>
import { Tabs } from "@/components";
import excel from "@/Utilerias/GenerarExcel.js";
import serReportes from "@/Servicios/Reportes/reportes.js";
import format from "date-fns/format";
import store from "@/Store";
import servRepre from "@/Servicios/Representacion/representacion";
import servTipoProceso from "@/Servicios/Catalogos/tipoproceso";
import servTipoProcesoPo from "@/Servicios/Catalogos/tipoprocesopo";
import excelEC from "@/Utilerias/GenerarEstadoCuenta";
export default {
  name: "ReportedeVentas",
  components: {
    Tabs,
  },
  computed: {
    usuario() {
      return store.state.usuario;
    },

  },
  data() {
    return {
      dateFormat: "yyyy-MM-dd 00:00:00",
      FechaInicioSolicitud: "",
      FechaFinSolicitud: "",
      FechaInicioPoliza: "",
      FechaFinPoliza: "",
      SolicitudIdInicio: 0,
      SolicitudIdFin: 0,
      PolizaIdInicio: 0,
      PolizaIdFin: 0,
      FechaLimpia: "0001-01-01",
      Activo: false,
      isLoader: false,
      tableRepresentacion: [],
      representacionId: [],
      representacionIdError: false,
      tipoProcesoPoId: 0,
      tableEstatusPoliza: [],
      tipoProcesoId: 0,
      tableTipoProceso: [],
      tipoProcesoPoIdError: false,
      cuentaIdError: false,
    };
  },
  methods: {
    sortedEstatusPoliza() {
    return this.tableEstatusPoliza.slice().sort((a, b) => {
      return a.descripcion.localeCompare(b.descripcion);
    });
  },
    GetRepresentaciones() {
      //cambios
      this.tableRepresentacion = [];
      servRepre
        .all()
        .then((r) => {
          let todos = {
            representacionId: 0,
            representacionNombre: "Todas",
          };
          this.tableRepresentacion = r.data; //cambio
          this.tableRepresentacion.unshift(todos);
        })
        .catch((e) => console.log(e));
    },
    GetTipoProceso() {
      this.tableTipoProceso = [];
      servTipoProceso
        .all()
        .then((r) => {
          let todos = {
            tipoProcesoId: 0,
            descripcion: "Todas",
          };
          this.tableTipoProceso = r.data; //cambio
          this.tableTipoProceso.unshift(todos);
        })
        .catch((e) => console.log(e));
    },
    GetTipoProcesoPo() {
      this.tableEstatusPoliza = [];
      servTipoProcesoPo
        .all()
        .then((r) => {
          let todos = {
            tipoProcesoPoId: 0,
            descripcion: "Todas",
          };
          this.tableEstatusPoliza = r.data; //cambio
          // console.log(this.tableEstatusPoliza);
          this.tableEstatusPoliza.unshift(todos);
        })
        .catch((e) => console.log(e));
    },
    Generar() {
      let data = this.Validaciones();
      serReportes
        .getVentasGerencia(data)
        .then((r) => {
          console.log("Reporte de Ventas",r.data);
          //excelEC.GenerarReporteVentas(r.data);
          excel.GeneraExcel(r.data);
          this.Activo = false;
          this.isLoader = false;
          this.notificamosNoyDatos(r.data);
        })
        .catch((e) => {
          this.Activo = false;
          this.isLoader = false;
          console.log(e);
        });
    },
    GenerarEstadoCuenta() {
      let data = this.Validaciones();
      console.log("Entramos");
      serReportes
        .EstadoCuenta(data)
        .then((r) => {
          this.Activo = false;
          this.isLoader = false;
          console.log(r.data);        
          excelEC.GenerarEstadoCuenta(r.data);
          this.notificamosNoyDatos(r.data);

        })
        .catch((e) => {
          this.Activo = false;
          this.isLoader = false;
          console.log(e);
        });
    },
    HistorialPoliza() {
      let data = this.Validaciones();
      serReportes
        .HistorialPoliza(data)
        .then((r) => {
          excel.GeneraExcel(r.data);
          this.Activo = false;
          this.isLoader = false;
          this.notificamosNoyDatos(r.data);
        })
        .catch((e) => {
          this.Activo = false;
          this.isLoader = false;
          console.log(e);
        });
    },
    Renovaciones() {
      let data = this.Validaciones();
      serReportes
        .Renovaciones(data)
        .then((r) => {
          excel.GeneraExcel(r.data);
          this.Activo = false;
          this.isLoader = false;
          this.notificamosNoyDatos(r.data);
        })
        .catch((e) => {
          this.Activo = false;
          this.isLoader = false;
          console.log(e);
        });
    },
    Validaciones() {
      var dateFormat = this.$material.locale.dateFormat;
      this.Activo = true;
      this.isLoader = true;
      if (this.FechaInicioSolicitud != null) {
        this.FechaInicioSolicitud = format(
          this.FechaInicioSolicitud,
          dateFormat
        );
      } else {
        this.FechaInicioSolicitud = this.FechaLimpia;
      }
      if (this.FechaFinSolicitud != null) {
        this.FechaFinSolicitud = format(this.FechaFinSolicitud, dateFormat);
      } else {
        this.FechaFinSolicitud = this.FechaLimpia;
      }
      if (this.FechaInicioPoliza != null) {
        this.FechaInicioPoliza = format(this.FechaInicioPoliza, dateFormat);
      } else {
        this.FechaInicioPoliza = this.FechaLimpia;
      }
      if (this.FechaFinPoliza != null) {
        this.FechaFinPoliza = format(this.FechaFinPoliza, dateFormat);
      } else {
        this.FechaFinPoliza = this.FechaLimpia;
      }

      let data = {
        fechaInicio: this.FechaInicioSolicitud,
        fechaFin: this.FechaFinSolicitud,
        fechaInicioPoliza: this.FechaInicioPoliza,
        fechaFinPoliza: this.FechaFinPoliza,
        idSolicitudInicio: this.SolicitudIdInicio,
        idSolicitudFin: this.SolicitudIdFin,
        idPolizaInicio: this.PolizaIdInicio,
        idPolizafin: this.PolizaIdFin,
        representacionId: this.representacionId,
        tipoProcesoPoId: this.tipoProcesoPoId,
        tipoProcesoId: this.tipoProcesoId,
        usuarioId: this.usuario.usuariosId,
      };
      return data;
    },
    Limpiar() {
      this.FechaInicioSolicitud = null;
      this.FechaFinSolicitud = null;
      this.FechaInicioPoliza = null;
      this.FechaFinPoliza = null;
      this.SolicitudIdInicio = 0;
      this.SolicitudIdFin = 0;
      this.PolizaIdInicio = 0;
      this.PolizaIdFin = 0;
      this.Activo = false;
      this.isLoader = false;
      this.representacionId = [];
      this.tipoProcesoPoId = 0;
      this.tipoProcesoId = 0;
    },
    cambio() {
      console.log("Entro");
    },
    notificamosNoyDatos(data)
    {
      if(data.length == 0){
            this.$notify({
              group: "foo",
              title: "Error",
              text: "No se encontraron datos",
            });
            return;
          }
    }
  },
  created() {
    this.$material.locale.dateFormat = "yyyy-MM-dd";
    this.Limpiar();
    this.GetRepresentaciones();
    this.GetTipoProceso();
    this.GetTipoProcesoPo();
  },
};
</script>

<style>
</style>