import { http } from "@/http-commons";

class centrocostos {
  all() {
    return http.get('/CentroCostoes');
  }
  get(id) {
    return http.get(`/CentroCostoes/${id}`);
  }
  post(centrocostos) {
    return http.post('/CentroCostoes', centrocostos);
  }
  put(id, centrocostos) {
    return http.put(`/CentroCostoes/${id}`, centrocostos);
  }
  delete(id) {
    return http.delete(`/CentroCostoes/${id}`);
  }
}

export default new centrocostos();