<template>
    <div class="md-layout">
        <md-card>
            <md-card-header class="md-card-header-text md-card-header-green">
                <div class="card-text">
                    <h4 class="title">Polizas</h4>
                </div>
            </md-card-header>
            <md-card-content>
                <md-table :value="queriedData" :md-sort.sync="currentSort" :md-sort-order.sync="currentSortOrder"
                    :md-sort-fn="customSort" class="paginated-table table-striped table-hover">
                    <md-table-toolbar>
                        <md-field>
                            <label for="pages">Por página</label>
                            <md-select v-model="pagination.perPage" name="pages" style="width: 75px">
                                <md-option v-for="item in pagination.perPageOptions" :key="item" :label="item"
                                    :value="item">
                                    {{ item }}
                                </md-option>
                            </md-select>
                        </md-field>
                        <md-field>
                            <md-input type="search" class="mb-3" clearable style="width: 200px"
                                placeholder="Buscar registro" v-model="searchQuery"></md-input>
                        </md-field>
                    </md-table-toolbar>
                    <md-table-row slot="md-table-row" slot-scope="{ item }">
                        <md-table-cell md-label="Póliza Id" md-sort-by="polizaId">{{
                            item.polizaId
                        }}</md-table-cell>
                        <md-table-cell md-label="Solicitud Id" md-sort-by="solicitudId"><a @click="Redirecciono(item)">{{
                            item.solicitudId
                        }}</a></md-table-cell>
                        <md-table-cell md-label="Tipo" md-sort-by="tipopoliza">{{
                            item.tipopoliza
                        }}</md-table-cell>
                        <md-table-cell md-label="Costo">{{
                            item.costopoliza
                        }}</md-table-cell>
                        <md-table-cell md-label="Representación" md-sort-by="representacion">{{ item.representacion
                        }}</md-table-cell>
                        <md-table-cell md-label="Ejecutivo" md-sort-by="ejecutivo">{{
                            item.ejecutivo
                        }}</md-table-cell>
                        <md-table-cell md-label="Fecha" md-sort-by="fechacreacion">{{
                            item.fechacreacion
                        }}</md-table-cell>
                        <md-table-cell md-label="Estatus" md-sort-by="estatus">{{
                            item.estatus
                        }}</md-table-cell>
                        <md-table-cell md-label="Renovación" v-if="item.isrenovacion == true"><md-icon
                                class="md-primary">check_circle</md-icon></md-table-cell>
                        <md-table-cell md-label="Renovación" v-else></md-table-cell>
                    </md-table-row>
                </md-table>
                <div class="footer-table md-table">
                    <table>
                        <caption>Descripción de los datos en el pie de la tabla</caption>
                        <tfoot>
                            <tr>
                                <th v-for="item in footerTable" :key="item" class="md-table-head">
                                    <div class="md-table-head-container md-ripple md-disabled">
                                        <div class="md-table-head-label">
                                            {{ item }}
                                        </div>
                                    </div>
                                </th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </md-card-content>
            <md-card-actions md-alignment="space-between">
                <div class="">
                    <p class="card-category">
                        Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                    </p>
                </div>
                <pagination class="pagination-no-border pagination-success" v-model="pagination.currentPage"
                    :per-page="pagination.perPage" :total="total">
                </pagination>
            </md-card-actions>
        </md-card>
    </div>
</template>

<script>
import { Pagination } from "@/components";
import store from "@/Store";

export default {
    props: {
        tableData: {
            type: Array,
            required: true,
            default: () => [],
        },
    },
    components: {
        Pagination,
    },
    computed: {
        queriedData() {
            let result = this.tableData;
            if (this.searchedData.length > 0) {
                result = this.searchedData;
            }
            return result.slice(this.from, this.to);
        },
        to() {
            let highBound = this.from + this.pagination.perPage;
            if (this.total < highBound) {
                highBound = this.total;
            }
            return highBound;
        },
        from() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
            return this.searchedData.length > 0
                ? this.searchedData.length
                : this.tableData.length;
        },
    },
    data() {
        return {
            propsToSearch: ["polizaId", "solicitudId", "ejecutivo", "fechacreacion"],
            currentSort: "polizaId",
            currentSortOrder: "desc",
            searchedData: [],
            searchQuery: "",
            fuseSearch: null,
            isLoader: false,
            pagination: {
                perPage: 5,
                currentPage: 1,
                perPageOptions: [5, 10, 25, 50],
                total: 0,
            },
            footerTable: [
                "Póliza Id",
                "Solicitud Id",
                "Tipo  ",
                "Costo",
                "Representación",
                "Ejecutivo",
                "Fecha",
                "Estatus",
                "Renovación"
            ],
        }
    },
    methods: {
        Redirecciono(item) {
            store.state.Modo = 5;
            store.state.General.SolicitudId = item.solicitudId;
            store.state.General.FisicaMoralId = item.fisicaMoralId;
            this.$router.push("operacion");
        },
        customSort(value) {
            return value.sort((a, b) => {
                const sortBy = this.currentSort;
                if (this.currentSortOrder === "desc") {
                    return a[sortBy].localeCompare(b[sortBy]);
                }
                return b[sortBy].localeCompare(a[sortBy]);
            });
        },
    },
    created() {
    },
    watch: {
        searchQuery(value) {
            let result = this.tableData;
            if (value !== "") {
                result = this.fuseSearch.search(this.searchQuery);
            }
            this.searchedData = result.map(r => r.item);
        }
    }
}
</script>
<style></style>