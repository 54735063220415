import { http } from "@/http-commons";

class area {
  all() {
    return http.get('/area');
  }
  get(id) {
    return http.get(`/area/${id}`);
  }
  post(area) {
    return http.post('/area', area);
  }
  put(id, area) {
    return http.put(`/area/${id}`, area);
  }
  delete(id) {
    return http.delete(`/area/${id}`);
  }
}

export default new area();