<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-text md-card-header-primary">
          <div class="card-text">
            <h4 class="title">Filtros</h4>
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-size-50">
              <md-field>
                <label> Fecha Inicio</label>
                <md-input v-model="fechainicio" type="date"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-50">
              <md-field>
                <label>Fecha Fin</label>
                <md-input v-model="fechafin" type="date"></md-input>
              </md-field>
            </div>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="right">
          <md-button class="md-primary" @click.native="Priorizamos()"
            >Filtrar</md-button
          >
        </md-card-actions>
      </md-card>
    </div>
    <cards
      :Cantidad="this.cantidad.solicitudes"
      txtHeader="Solicitudes"
      txtFooter="Solicitudes recibidas"
    ></cards>
    <cards
      :Cantidad.sync="this.cantidad.polizas"
      txtHeader="Polizas"
      txtFooter="Pólizas generadas"
    ></cards>
    <cards
      :Cantidad.sync="this.cantidad.soluciones"
      txtHeader="Soluciones"
      txtFooter="Soluciones ingresadas"
    ></cards>
    <cards
      :Cantidad.sync="this.cantidad.ventaTotal"
      txtHeader="Cantidad Venta Actual"
      txtFooter="Total de Ingreso"
      :isMoney="true"
    ></cards>
    <cards
      :Cantidad.sync="this.cantidad.regalias"
      txtHeader="Regalias Actual"
      txtFooter="Total de Regalias"
      :isMoney="true"
      v-if="usuario.isResponsanle === true"
    />
    <cards
      :Cantidad.sync="this.cantidad.regaliasPagadasTotal"
      txtHeader="Cantidad Pagada Actual"
      txtFooter="Total de regalias Pagadas"
      :isMoney="true"
      v-if="usuario.isResponsanle === true"
    />
    <cards
      :Cantidad.sync="this.cantidad.solicitudesCanceladas"
      txtHeader="Solicitudes Canceladas"
      txtFooter="Total de solicitudes Canceladas"
    ></cards>
    <cards
      :Cantidad.sync="this.cantidad.polizasCanceladas"
      txtHeader="Pólizas Canceladas"
      txtFooter="Total de Pólizas Cancelada"
    ></cards>
    <cards
      :Cantidad.sync="this.cantidad.ventaTotalPasado"
      txtHeader="Cantidad de venta de septiembre del 2023"
      txtFooter="Total de venta de septiembre del 2023"
      :isMoney="true"
      v-if="usuario.isResponsanle === true"
    />
    <cards
      :Cantidad.sync="this.cantidad.regaliasPasado"
      txtHeader="Regalias de septiembre del 2023"
      txtFooter="Total de Regalias de septiembre del 2023"
      :isMoney="true"
      v-if="usuario.isResponsanle === true"
    />
    <cards
      :Cantidad.sync="this.cantidad.regaliasPagadasTotalPasado"
      txtHeader="Cantidad Pagada de septiembre del 2023"
      txtFooter="Total de cantidad pagada de regalias de septiembre del 2023"
      :isMoney="true"
      v-if="usuario.isResponsanle === true"
    />
    <cards
      :Cantidad.sync="this.cantidad.deudaRegaliasPagadasTotalPasado"
      txtHeader="Falta por pagar de septiembre del 2023"
      txtFooter="Falta por pagar el total de regalias de septiembre del 2023"
      :isMoney="true"
      v-if="usuario.isResponsanle === true"
    />
    <cards
      Cantidad.sync="0"
      txtHeader="Trabajando...."
      txtFooter="Trabajando....."
      :isMoney="true"
      v-else
    />
    <div class="md-layout-item md-size-50">
      <div class="content">
        <VentasPorEjecutivoTable
          v-bind:tableData="dataTableEjecutivo" v-bind:isAdministracion="false"/>
      </div>
    </div>
    <div class="md-layout-item md-size-50">
      <div class="content">
        <VentasPorRepresentacion
          v-bind:tableData="dataTableRepresentacion" v-bind:isAdministracion="false"/>
      </div>
    </div>
  </div>
</template>

<script>
import cards from "./cards.vue";
//import graficas from "./graficas.vue";
import servDash from "@/Servicios/Dashboard/dashboard";
import mensaje from "@/Servicios/ManjeadorErrores/Mensajes";
import { GlobalSalesCard, GlobalSalesTable } from "@/components";
import store from "@/Store/index";
import VentasPorEjecutivoTable from "@/components/Tables/VentasPorEjecutivoTable.vue";
import VentasPorRepresentacion from "@/components/Tables/VentasPorRepresentacion.vue";
export default {
  computed: {
    // cantidad(){
    //   return store.state.cantidad;
    // }
    usuariosId() {
      return store.state.usuario.usuariosId;
    },
    usuario() {
      return store.state.usuario;
    },    
  },
  components: {
    VentasPorEjecutivoTable,
    VentasPorRepresentacion,
    cards,
    // graficas,
  },

  data() {
    return {
      fechainicio: "",
      fechafin: "",
      dataTableEjecutivo: [],
      dataTableRepresentacion: [],
      cantidad: {},
      labels: [
        "Ja",
        "Fe",
        "Ma",
        "Ap",
        "Mai",
        "Ju",
        "Jul",
        "Au",
        "Se",
        "Oc",
        "No",
        "De",
      ],
      series: [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895],
    };
  },
  methods: {
    async getadminCantidad() {
      servDash
        .GetCantidadEstatusxUsuario(
          this.fechainicio,
          this.fechafin,
          this.usuariosId
        )
        .then((a) => {
          console.log(a.data);
          this.cantidad = a.data;
        })
        .catch((e) => {
          mensaje.Error("Dashboard-admin-adminCantidad", e);
        });
    },
    async getVentaGlobalEjecutivo() {
      servDash
        .VentaGlobalEjecutivo(this.fechainicio, this.fechafin)
        .then((a) => {
          this.dataTableEjecutivo = a.data;
        })
        .catch((e) => {
          mensaje.Error("Dashboard-admin-VentaGlobalEjecutivo", e);
        });
    },
    async getVentaGlobalRepresentacion() {
      servDash
        .VentaGlobalRepresentacion(this.fechainicio, this.fechafin)
        .then((a) => {
          this.dataTableRepresentacion = a.data;
        })
        .catch((e) => {
          mensaje.Error("Dashboard-admin-VentaGlobalRepresentacion", e);
        });
    },
    SetFechas() {
      var date = new Date(),
        y = date.getFullYear(),
        m = date.getMonth();
      var firstDay = new Date(y, m, 1);
      var lastDay = new Date(y, m + 1, 0);
      this.fechainicio = firstDay.toISOString();
      this.fechafin = lastDay.toISOString();
    },
    Priorizamos() {
      this.getadminCantidad();
      this.getVentaGlobalEjecutivo();
      this.getVentaGlobalRepresentacion();
    },
  },
  mounted() {},
  created() {
    this.SetFechas();
    this.Priorizamos();
  },
  watch: {},
};
</script>
