import { http } from "@/http-commons";

class estado {
  all() {
    return http.get('/estado');
  }
  get(id) {
    return http.get(`/estado/${id}`);
  }
  post(estado) {
    return http.post('/estado', estado);
  }
  put(id, estado) {
    return http.put(`/estado/${id}`, estado);
  }
  delete(id) {
    return http.delete(`/estado/${id}`);
  }
}

export default new estado();