import { http } from "@/http-commons";

class cuentas {
  all(id) {
    return http.get(`/CuentasBancaria`);
  }
  Usuario(id,isMatriz) {
    return http.get(`/CuentasBancaria/Usuario/${id}/${isMatriz}`);
  }
}

export default new cuentas();