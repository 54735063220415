import http from "@/getToken";

class Token {
  
   getToken() {     
    let data = {
        "username": process.env.VUE_APP_USER,
        "password": process.env.VUE_APP_PWD
    }
    return http.post('/Authenticate', data);
  }
}

export default new Token();