import { http } from "@/http-commons";

class estatuspoliza {
  all() {
    return http.get('/EstatusPoliza');
  }
  get(id) {
    return http.get(`/EstatusPoliza/${id}`);
  }
  post(data) {
    return http.post('/EstatusPoliza', data);
  }
  put(id, data) {
    return http.put(`/EstatusPoliza/${id}`, data);
  }
  delete(id) {
    return http.delete(`/EstatusPoliza/${id}`);
  }
}

export default new estatuspoliza();