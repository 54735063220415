<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">Soluciones</h4>
        </md-card-header>
        <md-card-content>
          <md-table :value="queriedData" :md-sort.sync="currentSort" :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort" class="paginated-table table-striped table-hover">
            <md-table-toolbar>
              <md-field>
                <label for="pages">Por página</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">{{ item
                  }}</md-option>
                </md-select>
              </md-field>

              <md-field>
                <md-input type="search" class="mb-3" clearable style="width: 200px" placeholder="Buscar registro"
                  v-model="searchQuery"></md-input>
              </md-field>
              <md-field>
                <label for="lista">Seleccionar Estatus</label>
                <md-select v-model="lista" name="lista">
                  <md-option v-for="r in lista" :key="r.procesoSolucionesId" :value="r.procesoSolucionesId">{{
                    r.descripcion }}</md-option>
                </md-select>
              </md-field>
              <md-field>
                <md-input type="date" class="mb-3" clearable style="width: 200px" placeholder="Dedes"
                  v-model="fechainicio"></md-input>
              </md-field>
              <md-field>
                <md-input type="date" class="mb-3" clearable style="width: 200px" placeholder="Hasta"
                  v-model="fechafin"></md-input>
              </md-field>
              <md-field> <!-- BOTÓN BUSCA POR FECHA Y ESTATUS  -->
                <md-button class="md-primary" @click="getDatos()">Buscar</md-button>
              </md-field>

              <md-field>
                <md-button class="md-primary" @click="ModalPersonalizado(1)">Nuevo</md-button>
              </md-field>
            </md-table-toolbar>

            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Id Solución" md-sort-by="solucionesId" style="width: 50px">
                {{ item.solucionesId }} </md-table-cell>
              <md-table-cell md-label="Poliza Id" md-sort-by="polizaId" style="width: 50px">
                {{ item.polizaId }} </md-table-cell>
              <md-table-cell md-label="Solicitud Id" md-sort-by="solicitudId" style="width: 50px">
                <a @click="Redirecciono(item)">
                  {{ item.solicitudId }}
                </a> </md-table-cell>
              <md-table-cell md-label="Fecha" md-sort-by="fecha">
                {{ item.fecha }} </md-table-cell>
              <md-table-cell md-label="Arrendador" md-sort-by="arrendador">
                {{ item.arrendador }} </md-table-cell>
              <md-table-cell md-label="Arrendatario" md-sort-by="arrendatario">{{ item.arrendatario }}</md-table-cell>
              <md-table-cell md-label="Fiador" md-sort-by="fiador">
                {{ item.fiador }} </md-table-cell>
              <md-table-cell md-label="Usuario" md-sort-by="usuario">
                {{ item.usuario }}</md-table-cell>
              <md-table-cell md-label="Estatus" md-sort-by="estatus">
                {{ item.estatus }} </md-table-cell>

              <md-table-cell md-label="Acciones">
                <md-button class="md-just-icon md-warning md-simple"
                  @click.native="Redireccionamiento('tabssoluciones', item)">
                  <md-icon>edit</md-icon>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
          <div class="footer-table md-table">
            <table>
             <caption>Descripción de los datos en el pie de la tabla</caption>
              <tfoot>
                <tr>
                  <th v-for="item in footerTable" :key="item" class="md-table-head">
                    <div class="md-table-head-container md-ripple md-disabled">
                      <div class="md-table-head-label">{{ item }}</div>
                    </div>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class>
            <p class="card-category">Showing {{ from + 1 }} to {{ to }} of {{ total }} entries</p>
          </div>
          <pagination class="pagination-no-border pagination-success" v-model="pagination.currentPage"
            :per-page="pagination.perPage" :total="total"></pagination>
        </md-card-actions>
      </md-card>
      <modal v-if="classicModal" @close="classicModalHide">
        <template slot="header">
          <h4 class="modal-title">{{ datosModal.titulo }}</h4>
          <md-button class="md-simple md-just-icon md-round modal-default-button" @click="classicModalHide">
            <md-icon>clear</md-icon>
          </md-button>
        </template>

        <template slot="body">
          <md-field>
            <label>Póliza Id</label>
            <md-input v-model="polizaId"></md-input>
            <span class="md-helper-text">Póliza Id</span>
          </md-field>
        </template>
        <template slot="footer">
          <md-button class="md-danger md-simple" @click="classicModalHide">Cerrar</md-button>
          <md-button class="md-primary" @click.native="postNow(datosModal.evento)">{{ datosModal.boton }}</md-button>
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
import { Pagination, Modal } from "@/components";
import Fuse from "fuse.js";
import mensaje from "@/Servicios/ManjeadorErrores/Mensajes";//
import servSolucion from "@/Servicios/Soluciones/soluciones";
import store from "@/Store";

export default {
  components: {
    Pagination,
    Modal,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
    usuario() {
      return store.state.usuario;
    },
  },
  data() {
    return {
      currentSort: "procesoSolucionesId",
      currentSortOrder: "desc",
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      footerTable: [
        "Id Solución",
        "Póliza Id",
        "Solicitud Id",
        "Fecha",
        "Arrendador",
        "Arrendatario",
        "Fiador",
        "Usuario",
        "Estatus",
        "Acciones",
      ],
      searchQuery: "",
      propsToSearch: [
        "solicitudId",
        "solucionesId",
        "polizaId",
        "fecha",
        "arrendador",
        "arrendatario",
        "fiador",
        "usuario",
        "estatus",
      ],

      fechainicio: "",
      fechafin: "",
      tableData: [],
      searchedData: [],      
      lista: [],
      tableRepresentacion: "",
      fuseSearch: null,
      classicModal: false,
      polizaId: 0,
      solucionesId: 0,
      procesoSolucionesId: 0,
      datosModal: {
        titulo: "Crear una solucion",
        boton: "Confirmar",
        evento: 0,
      },
      Mensajes: {
        tipo: null,
        mensaje: null,
        accion: false,
      },
      solucionId: 0,
    };
  },
  methods: {
    Redirecciono(item) {
      store.state.Modo = 5;
      store.state.General.SolucionesId = item.solucionesId;
      store.state.General.SolicitudId = item.solicitudId;
      store.state.General.PolizaId = item.polizaId;
      this.$router.push("operacion");
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    getProcesoSoluciones() {
      servSolucion.getProcesoSoluciones()
        .then((data) => {
          console.log("datosEstatus", data.data)
          this.lista = data.data;
        });
    },
    getDatos() {
      if (this.usuario.usuariosId > 0) {
        servSolucion
          .Filtros(this.usuario.usuariosId, this.fechainicio, this.fechafin, this.procesoSolucionesId)
          .then((a) => {
            this.tableData = a.data;
            // Fuse search initialization.
            this.fuseSearch = new Fuse(this.tableData, {
              keys: [
                "solucionesId",
                "polizaId",
                "fecha",
                "arrendador",
                "arrendatario",
                "usuario",
                "estatus",
                "fiador",
              ],
              threshold: 0.3,
            });
          })
          .catch((e) => {
            mensaje.Error("soluciones-index-get", e);
          });
      }
    },
    classicModalHide() {
      //  this.Clear();
      this.classicModal = false;
    },
    ModalPersonalizado(exp, row) {
      switch (exp) {
        case 1:  //INSERTAR
          this.classicModal = true;
          this.datosModal.titulo = "Ingresa No. Póliza";
          this.datosModal.boton = "Buscar ";
          this.datosModal.evento = 1;
          this.datosModal.crud = true;
          break;
        case 2:
          this.$router.push({ name: pantalla });
          break;

        case 3:
          this.classicModal = true;
          this.datosModal.boton = "Buscar";
          this.datosModal.evento = 3;
      }
    },
    postNow(id) {
      switch (id) {
        case 1:
          if (this.polizaId > 0) {
            //revisamos que no exista ya la solucion
            servSolucion.isExists(this.polizaId).then(r => {
              console.log(r.data)
              if (r.data === true) {
                let m = {
                  mensaje: "",
                  tipo: "",
                };
                m.mensaje = "Ya existe una solución con el mismo número de póliza"; //BTÓN BUSCAR NUEVO
                m.tipo = "danger";
                this.notifyVue(m);
              } else {
                store.state.Modo = 5;
                store.state.General.PolizaId = this.polizaId;
                store.state.General.SolucionId = 0;
                this.$router.push({
                  name: "createUpdateSolucion",
                });
              }

            }).catch(e => { console.log(e) });
          } else {
            let m = {
              mensaje: "",
              tipo: "",
            };
            m.mensaje = "Para continuar favor de ingresar un PÓLIZA"; //BTÓN BUSCAR NUEVO
            m.tipo = "danger";
            this.notifyVue(m);
          }
          break;
      }
    },
    Redireccionamiento(pantalla, item) {
      store.state.Modo = 1;
      store.state.General.SolucionId = item.solucionesId;
      store.state.General.SolicitudId = item.solicitudId;
      store.state.General.PolizaId = item.polizaId;
      store.state.General.FisicaMoralId = item.fisicaMoralId;
      this.$router.push({ name: pantalla });
    },
    notifyVue(m) {
      this.$notify({
        message: m.mensaje,
        timeout: 30000,
        icon: "add_alert",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: m.tipo,
      });
    },
    SetFechas() {
      var date = new Date(),
        y = date.getFullYear(),
        m = date.getMonth();
      var firstDay = new Date(y, 0, 1);
      var lastDay = new Date(y, 11 + 1, 0);
      this.fechainicio = firstDay.toISOString().split('T')[0];;
      this.fechafin = lastDay.toISOString().split('T')[0];;
    },
  },
  created() {
    this.SetFechas();
    this.getDatos();
    this.getProcesoSoluciones();
  },
  mounted() { },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result.map((r) => r.item);
    },
  },
};
</script>

<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.full-control>.md-list {
  width: 320px;
  max-width: 100%;
  height: 400px;
  display: inline-block;
  overflow: auto;
  border: 1px solid rgba(#000, 0.12);
  vertical-align: top;
}
</style>
